import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  link: string;
};

export const FacebookEmbedComponent = ({ link }: Props) => {
  const containerRef = useRef(null);
  const isFirstRender = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  const create = useCallback(async () => {
    if (containerRef.current) {
      await window.FB.XFBML.parse(containerRef.current);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      setIsLoading(true);

      if (window.FB === undefined) {
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/es_LA/sdk.js';
        script.async = true;
        document.body?.appendChild(script);
        script.onload = () => {
          window.fbAsyncInit = function () {
            window.FB.init({
              xfbml: true,
              version: 'v15.0',
            });
            create();
          };
        };
      } else {
        create();
      }

      isFirstRender.current = false;
    }
  }, [link, create]);

  return (
    <>
      {isLoading ? 'Facebook is loading...' : null}
      <div ref={containerRef}>
        <div className="fb-post" data-href={link} />
      </div>
    </>
  );
};
