import type {
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  NodeKey,
  SerializedElementNode,
} from 'lexical';

import {
  $applyNodeReplacement,
  $createParagraphNode,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  ParagraphNode,
  RangeSelection,
} from 'lexical';
import { addClassNamesToElement } from '@lexical/utils';

export type HeadingTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type SerializedHeadingNode = {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & SerializedElementNode;

/** @noInheritDoc */
export class HeadingNode extends ElementNode {
  /** @internal */
  __tag: HeadingTagType;

  static getType(): string {
    return 'heading';
  }

  static clone(node: HeadingNode): HeadingNode {
    return new HeadingNode(node.__tag, node.__key);
  }

  constructor(tag: HeadingTagType, key?: NodeKey) {
    super(key);
    this.__tag = tag;
  }

  getTag(): HeadingTagType {
    return this.__tag;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const tag = this.__tag;
    const element = document.createElement(tag);
    const theme = config.theme;
    const classNames = theme.heading;

    if (classNames !== undefined) {
      const className = classNames[tag];
      addClassNamesToElement(element, className);
    }
    return element;
  }

  updateDOM(): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return null;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const { element } = super.exportDOM(editor);

    if (element && this.isEmpty()) {
      element.append(document.createElement('br'));
    }

    if (element) {
      element.style.textAlign = this.getFormatType();

      const direction = this.getDirection();
      if (direction) {
        element.dir = direction;
      }
    }

    return {
      element,
    };
  }

  static importJSON(serializedNode: SerializedHeadingNode): HeadingNode {
    const node = $createHeadingNode(serializedNode.tag);

    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);

    return node;
  }

  exportJSON(): SerializedHeadingNode {
    return {
      ...super.exportJSON(),
      tag: this.getTag(),
      type: 'heading',
      version: 1,
    };
  }

  insertNewAfter(selection?: RangeSelection, restoreSelection = true): ParagraphNode | HeadingNode {
    const anchorOffset = selection ? selection.anchor.offset : 0;
    const direction = this.getDirection();
    const newElement =
      anchorOffset > 0 && anchorOffset < this.getTextContentSize()
        ? $createHeadingNode(this.getTag())
        : $createParagraphNode();

    newElement.setDirection(direction);

    this.insertAfter(newElement, restoreSelection);

    return newElement;
  }

  collapseAtStart(): true {
    const newElement = !this.isEmpty() ? $createHeadingNode(this.getTag()) : $createParagraphNode();
    const children = this.getChildren();

    children.forEach((child) => newElement.append(child));

    this.replace(newElement);

    return true;
  }

  extractWithChild(): boolean {
    return true;
  }
}

export function $createHeadingNode(headingTag: HeadingTagType): HeadingNode {
  return $applyNodeReplacement(new HeadingNode(headingTag));
}

export function $isHeadingNode(node: LexicalNode | null | undefined): node is HeadingNode {
  return node instanceof HeadingNode;
}
