import type { CaseReducer } from '@reduxjs/toolkit';
import type { State, Notification } from './types';

import { PayloadAction } from '@reduxjs/toolkit';

export const add: CaseReducer<State, PayloadAction<Notification>> = (state, { payload }) => {
  state.notifications.push(payload);
};

export const remove: CaseReducer<State, PayloadAction<Notification['id']>> = (
  state,
  { payload },
) => {
  state.notifications = state.notifications.filter((notification) => notification.id !== payload);
};
