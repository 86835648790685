// TODO: большенство проверок со старого проєкта, позже уточнить их актуальность

const integers = '\\d';
const cyrillic = 'а-яА-ЯёЁіІїЇєЄӘҒҚҢӨҰҮҺәғқңөұүһ';
const latin = 'a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæŒœ';
const characters = cyrillic + latin;
const commonSpec = '\'"“”';
const specStart = '«„‘¿¡';
const specEnd = '»”’!?.…)';
const commonStartEndSymbols = `${integers}${characters}${commonSpec}`;
const url_without_protocol =
  /(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/; // eslint-disable-line

export const https_url = new RegExp(`^https://${url_without_protocol.source}$`, 'i');
export const url = new RegExp(`^https?://${url_without_protocol.source}$`, 'i');
export const symbols_at_start = new RegExp(`^[${commonStartEndSymbols}${specStart}]`, 'i');
export const symbols_at_end = new RegExp(`[${commonStartEndSymbols}${specEnd}]$`, 'i');
export const tag_title_symbols_at_end = new RegExp(`[${commonStartEndSymbols}${specEnd}+]$`, 'i');
export const danger_symbols = /[[\]<>\\]/;
export const repeating_symbols = new RegExp(`([^${integers}${characters}.])\\1+`, 'i');
export const slug = /^(?!.*(-_|_-))[a-z0-9_-]*[a-z0-9]$/i;
export const image_type = /^image\/(jpe?g|png|gif)$/;
export const catchy_title = new RegExp(`^(?!-\\s)[${characters}0-9-\\s#]*[${characters}0-9]$`);
export const tag_title = new RegExp(`^(?!-\\s)[${characters}+0-9-\\s#]*[${characters}+0-9]$`);
export const article_words = new RegExp(`[${characters}\\d]+`, 'g');
export const password = new RegExp(
  `^(?=.*[\\d])(?=.*[!@#$%^&*¿¡])(?=.*[a-z])(?=.*[A-Z])[${latin}0-9!@#$%^&*¿¡]+$`,
);
export const position = new RegExp(
  `^(?!(-|\\s))(?!.*(\\-\\-|\\s\\s|\\-\\s\\-))[${characters}'\\-\\s]*[${characters}]$`,
);
export const regExpForSocialPlatform = (domain: string) =>
  new RegExp(`^https?:\/\/([a-zA-Z0-9-]+\.)*${domain}\.[a-zA-Z]{2,}\/.*$`);
