import type { ReactNode } from 'react';
import type { DeepPartial, SubmitHandler } from 'react-hook-form';
import type { UseFormWatch, ValidationMode } from 'react-hook-form/dist/types/form';
import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type ChildrenRenderProps<T extends FieldValues> = {
  watch: UseFormWatch<T>;
  loading: boolean;
};

type FormProps<T extends ZodFirstPartySchemaTypes> = {
  children: (props: ChildrenRenderProps<z.infer<FormProps<T>['schema']>>) => ReactNode;
  onSubmit: SubmitHandler<z.infer<FormProps<T>['schema']>>;
  className?: string;
  defaultValues?: DeepPartial<z.infer<FormProps<T>['schema']>>;
  reset?: DeepPartial<z.infer<FormProps<T>['schema']>>;
  schema: T;
  mode?: keyof ValidationMode;
  autoComplete?: boolean;
};

export const RHFForm = <T extends ZodFirstPartySchemaTypes>({
  children,
  onSubmit,
  className,
  defaultValues,
  reset,
  schema,
  mode = 'onTouched',
  autoComplete = false,
}: FormProps<T>) => {
  const methods = useForm<T>({
    defaultValues,
    resolver: zodResolver(schema),
    mode,
  });

  useEffect(() => {
    if (reset) {
      methods.reset(reset);
    }
  }, [reset]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={className}
        noValidate
        autoComplete={autoComplete ? '' : 'off'}
      >
        {children({
          watch: methods.watch,
          loading: methods.formState.isSubmitting,
        })}
      </form>
    </FormProvider>
  );
};
