import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ArticleType } from '../api/types';

import type { State } from './types';

export const selectArticles: CaseReducer<State, PayloadAction<Array<UniqueId>>> = (
  state,
  { payload },
) => {
  state.selectedArticles = payload;
};

export const edit: CaseReducer<State, PayloadAction<ArticleType | null>> = (state, action) => {
  state.editing = action.payload;
};
