import type { FC } from 'react';
import type { StopWordType } from '@/entities/stop-word/types';

import { IconButton, Tooltip } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import NiceModal from '@ebay/nice-modal-react';

import { useAppDispatch } from '@/shared/lib/rtk';
import { STOP_WORD_UPDATE } from '@/shared/config/permissions';

import { HasAccess } from '@/entities/session';
import { stopWordModel } from '@/entities/stop-word';

import { MODAL_ID } from '../config';

type Props = {
  entity: StopWordType;
};

export const EditStopWord: FC<Props> = ({ entity }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(stopWordModel.actions.edit(entity));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[STOP_WORD_UPDATE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title={'Settings'}>
          <Settings fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
