import type { FC } from 'react';

import NiceModal from '@ebay/nice-modal-react';

import { Button } from '@/shared/ui';
import { useAppDispatch } from '@/shared/lib/rtk';
import { STOP_WORD_UPDATE } from '@/shared/config/permissions';

import { HasAccess } from '@/entities/session';
import { stopWordModel } from '@/entities/stop-word';

import { MODAL_ID } from '../config';

type Props = {
  className?: string;
};

export const CreateStopWord: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(stopWordModel.actions.edit(null));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[STOP_WORD_UPDATE]}>
      <Button label="Create" onClick={handleClick} className={className} />
    </HasAccess>
  );
};
