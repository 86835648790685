import {
  ImageList as MUIImageList,
  ImageListItem,
  ImageListItemBar,
  Pagination,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { ImageType } from '@/entities/gallery/types';
import { galleryModel, getCropResizeByWidth } from '@/entities/gallery';

import { Input } from '@/shared/ui';

import { UploadFormButton } from './upload-form-button';

import styles from './image-list.css';

type ImageListProps = {
  onSelect: (image: ImageType) => void;
  showCropped?: boolean;
};

export const ImageList = ({ onSelect, showCropped = false }: ImageListProps) => {
  const [searchingPhrase, setSearchingPhrase] = useState('');
  const [pagination, setPagination] = useState({ page: 1, per_page: 25 });
  const { images, lastPage } = galleryModel.useGetAll(
    Boolean(searchingPhrase) ? undefined : pagination,
  );
  const { searchImages, ...foundData } = galleryModel.useSearch();
  const currentLastPage = searchingPhrase ? foundData.lastPage : lastPage;
  const currentImages = searchingPhrase ? foundData.images : images;

  useEffect(() => {
    if (searchingPhrase) {
      searchImages({
        search_field: 'name',
        search: searchingPhrase,
        params: { params: { page: pagination.page } },
      });
    }
  }, [searchingPhrase, searchImages, pagination]);

  const handleImageSelect = (image: ImageType) => {
    onSelect(image);
  };

  return (
    <>
      <div className={styles.searchBar}>
        {currentLastPage && (
          <Pagination
            className={styles.pagination}
            count={currentLastPage}
            page={pagination.page}
            onChange={(_, page) => {
              setPagination((prevState) => ({ ...prevState, page }));
            }}
          />
        )}

        <Input
          size="small"
          className={styles.searchField}
          placeholder="Search"
          onChange={({ target: { value } }) => {
            setSearchingPhrase(value);
            setPagination((prevState) => ({ ...prevState, page: 1 }));
          }}
        />

        <div className={styles.button}>
          <UploadFormButton label="Add new image" />
        </div>
      </div>

      <MUIImageList className={styles.list} cols={0} rowHeight={showCropped ? 180 : 250} gap={10}>
        {currentImages?.length === 0 && searchingPhrase && (
          <p>
            Nothing found for your request: <b>{searchingPhrase}</b>
          </p>
        )}
        {currentImages?.map((item) => (
          <ImageListItem
            key={item.id}
            onClick={() => handleImageSelect(item)}
            className={styles.item}
          >
            <ImageListItemBar title={item.label} position="bottom" />
            <img
              src={
                showCropped
                  ? getCropResizeByWidth(item, 1200)?.url ?? item.previewUrl
                  : item.previewUrl
              }
              alt={item.label}
            />
          </ImageListItem>
        )) ?? []}
      </MUIImageList>
    </>
  );
};
