import type { FieldsValues } from './schema';

import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { callToActionModel } from '@/entities/call-to-action';
import { useShowNotification } from '@/shared/notification';
import { useAppDispatch } from '@/shared/lib/rtk';

import { MODAL_ID } from '../config';

export const useCreate = () => {
  const create = callToActionModel.useCreate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues) =>
      create(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Call to action was created`);
        })
        .catch(console.error),
    [create, showSuccessNotification],
  );
};

export const useUpdate = () => {
  const dispatch = useAppDispatch();
  const update = callToActionModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues & EntityWithId) =>
      update(data)
        .unwrap()
        .then(() => {
          dispatch(callToActionModel.actions.edit(null));
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Call to action was updated`);
        })
        .catch(console.error),
    [update, showSuccessNotification, dispatch],
  );
};

export const useDelete = () => {
  const deleteCallToAction = callToActionModel.useDelete();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId) => {
      deleteCallToAction(id)
        .unwrap()
        .then(() => {
          showSuccessNotification(`Call to action was deleted`);
        })
        .catch(console.error);
    },
    [deleteCallToAction, showSuccessNotification],
  );
};
