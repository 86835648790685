import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import type { AppDispatch } from '@/app/store';

import { sessionModel } from '@/entities/session';
import { notifyError } from '@/shared/api/lib';
import { removeToken } from '@/shared/api/access-token';
import { notificationModel } from '@/shared/notification';

// TODO: Find a right place for middlewares
export const authErrorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI<AppDispatch>) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        removeToken();
        dispatch(sessionModel.actions.logout());
        dispatch(sessionModel.actions.removeUser());
      }

      if (action.payload.status === 'FETCH_ERROR') {
        console.log(sessionModel);
      }

      if (action.payload.status >= 400 && action.payload.status < 500) {
        dispatch(notificationModel.showErrorNotification(notifyError(action.payload)));
      }
    }

    return next(action);
  };
