import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';

import { isLinkValid } from '@/shared/editor/lib';

import { TRY_INSERT_LINK_COMMAND } from '../commands';
import { LinkEditor } from '../ui/link-editor';
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '../nodes/link-node';

export const LinkPopoverPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [firstSelection, setFirstSelection] = useState<boolean>(true);
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [linkNode, setLinkNode] = useState<LinkNode | null>(null);
  const [linkAnchor, setLinkAnchor] = useState<HTMLElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopover = () => {
    setLinkAnchor(null);
    setLinkUrl('');
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editor.update(() => {
          if (!isPopoverOpen) {
            return;
          }

          if (!linkNode || isLinkValid(linkUrl)) {
            closePopover();
            return;
          }

          const isPresentInEditor = editorState._nodeMap.get(linkNode.getKey());

          // Check if link node still exist in editorState
          if (!isPresentInEditor) {
            closePopover();
            return;
          }

          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            // Update last anchor position
            const node = selection.anchor.getNode();
            const maybeLinkNode = $getNearestNodeOfType(node, LinkNode);
            const newEditorSelection = editorState._selection;
            const cloneSelection = newEditorSelection?.clone();

            // Click outside popover
            if (!maybeLinkNode && cloneSelection) {
              if (!isLinkValid(linkUrl)) {
                linkNode.select();
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                $setSelection(cloneSelection);
              }
              closePopover();
            }
          }
        });
      }),
      editor.registerCommand(
        TRY_INSERT_LINK_COMMAND,
        () => {
          editor.update(
            () => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                const { anchor, focus } = selection;
                const focusNode = focus.getNode();
                const anchorNode = anchor.getNode();
                const allSelectionNodes = selection.getNodes();

                const canInsertLink = allSelectionNodes.every(
                  (node) => $isParagraphNode(node) || $isLinkNode(node) || $isTextNode(node),
                );
                const selectionNodesHasSameParent =
                  focusNode.getParent() === anchorNode.getParent();

                if (canInsertLink && selectionNodesHasSameParent && !selection.isCollapsed()) {
                  setLinkUrl('https://');
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
                }
              }
            },
            { tag: 'history-merge' },
          );
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          editor.update(
            () => {
              if (firstSelection) {
                setFirstSelection(false);
                return;
              }

              if (isLinkValid(linkUrl)) {
                closePopover();
                return;
              }

              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                const node = selection.anchor.getNode();
                const maybeLinkNode = $getNearestNodeOfType(node, LinkNode);
                if ($isLinkNode(maybeLinkNode)) {
                  setLinkAnchor(editor.getElementByKey(maybeLinkNode.getKey()));
                  setLinkUrl(maybeLinkNode.getURL());
                  setIsPopoverOpen(true);
                  setLinkNode(maybeLinkNode);
                }
              }
            },
            { tag: 'history-merge' },
          );
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [editor, linkAnchor, linkUrl, firstSelection, linkNode, isPopoverOpen]);

  return (
    <LinkEditor
      anchor={linkAnchor}
      url={linkUrl}
      open={isPopoverOpen}
      setOpen={setIsPopoverOpen}
      setLinkUrl={setLinkUrl}
    />
  );
};
