import { createContext, ReactNode, useContext, useState } from 'react';
import {
  ArticleType,
  isArticle,
  isUniquenessResult,
  UniquenessResult,
  UniquenessUrlType,
} from '@/entities/article/api/types';

type ContextProps = {
  urls: UniquenessUrlType[];
  percentage: number;
  clearUniquenessState: () => void;
  setUniquenessState: (uniquenessResult: UniquenessResult | ArticleType) => void;
};

export const UniquenessContext = createContext<ContextProps | null>(null);

export const useUniquenessContext = () => {
  const context = useContext(UniquenessContext);

  if (!context) {
    throw new Error(
      `[UniquenessContext]: You forgot to wrap your component with UniquenessContext`,
    );
  }

  return context;
};

export const UniquenessProvider = ({ children }: { children: ReactNode }) => {
  const [percentage, setPercentage] = useState<number | null>(null);
  const [urls, setUrls] = useState<UniquenessUrlType[]>([]);

  const setUniquenessState = (data: UniquenessResult | ArticleType) => {
    if (isArticle(data)) {
      setPercentage(data.uniqueness || null);
      setUrls(data.uniquenessBody?.urls || []);
    }
    if (isUniquenessResult(data)) {
      setPercentage(data.uniquePercentage);
      setUrls(data.urls);
    }
  };

  const clearUniquenessState = () => {
    setPercentage(null);
    setUrls([]);
  };

  const uniquenessContextValue = {
    urls,
    percentage,
    clearUniquenessState,
    setUniquenessState,
  };

  return (
    <UniquenessContext.Provider value={uniquenessContextValue}>
      {children}
    </UniquenessContext.Provider>
  );
};
