export const setToken = (token: string) => {
  window.localStorage.setItem('__aToken', token);
};

export const getToken = () => {
  return window.localStorage.getItem('__aToken');
};

export const removeToken = () => {
  return window.localStorage.removeItem('__aToken');
};

export const hasToken = () => {
  return !!getToken();
};
