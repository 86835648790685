// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-grid__dataGrid___rACZT {
  font-size: 0.75rem;
}

.data-grid__dataGrid___rACZT .MuiDataGrid-cellContent {
  white-space: normal;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/data-grid/data-grid.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".dataGrid {\n  font-size: 0.75rem;\n}\n\n.dataGrid :global .MuiDataGrid-cellContent {\n  white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataGrid": `data-grid__dataGrid___rACZT`
};
export default ___CSS_LOADER_EXPORT___;
