import type { LoginBody } from '../api';

import { useCallback } from 'react';

import { useLoginMutation } from '../api';

export const useLogin = () => {
  const [login] = useLoginMutation();

  const handleLogin = useCallback(
    async (credentials: LoginBody) => {
      await login(credentials);
    },
    [login],
  );

  return { login: handleLogin };
};
