import type { SearchQueryParams } from '@/shared/api/types';
import type {
  ArticleType,
  ArticleConstants,
  SearchConsoleKeyword,
  UniquenessLimit,
  UniquenessResult,
} from './types';

import { api } from '@/shared/api';

import { transformResponse } from './transform-response';

const RESOURCE_PATH = 'articles';

const articlesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query<
      ResponseWithPagination<ArticleType>,
      PaginationQueryParams | undefined
    >({
      query: (args) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'GET',
        params: args ? { page: args.page, per_page: args.per_page, ...args.params } : undefined,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Articles', id } as const)),
              { type: 'Articles', id: 'LIST' },
            ]
          : [{ type: 'Articles', id: 'LIST' }],
      transformResponse: transformResponse,
    }),

    getArticle: builder.query<ArticleType, QueryParams>({
      query: ({ id, params }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Articles', id }],
      keepUnusedDataFor: 20,
    }),

    getAnalytics: builder.query<SearchConsoleKeyword[], QueryParams>({
      query: ({ id, params }) => ({
        url: `/${RESOURCE_PATH}/${id}/search-console/analytics`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: {
        data: Omit<SearchConsoleKeyword, 'id'>[];
      }): SearchConsoleKeyword[] => {
        return response.data.map((searchKey) => ({ ...searchKey, id: searchKey.key }));
      },
    }),

    getArticlesConstants: builder.query<ArticleConstants, void>({
      query: () => ({
        url: `/${RESOURCE_PATH}/constants`,
        method: 'GET',
      }),
    }),

    searchArticles: builder.query<ResponseWithPagination<ArticleType>, SearchQueryParams>({
      query: ({ search, search_field, params }) => ({
        url: `/${RESOURCE_PATH}/search?search=${search}&search_field=${search_field}`,
        method: 'GET',
        params: params
          ? { page: params.page, per_page: params.per_page, ...params.params }
          : undefined,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Articles', id } as const)),
              { type: 'Articles', id: 'LIST' },
            ]
          : [],
      transformResponse: transformResponse,
    }),

    getTasks: builder.query<ArticleType[], QueryParams | void>({
      query: (args) => ({
        url: `/${RESOURCE_PATH}/dashboard`,
        method: 'GET',
        params: args?.params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Articles', id } as const)),
              { type: 'Articles', id: 'LIST' },
            ]
          : [{ type: 'Articles', id: 'LIST' }],
    }),

    checkUniqueness: builder.mutation<UniquenessResult, { id: UniqueId }>({
      query: ({ id }) => ({
        url: `/${RESOURCE_PATH}/${id}/uniqueness`,
        method: 'POST',
      }),
      invalidatesTags: (res, err, args) => [{ type: 'Articles', id: args.id }],
    }),

    getUniquenessLimit: builder.query<UniquenessLimit, QueryParams['id']>({
      query: (id) => ({
        url: `/${RESOURCE_PATH}/${id}/uniqueness`,
        method: 'GET',
      }),
    }),

    addArticle: builder.mutation({
      query: (body) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),

    updateArticle: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        arg.status !== undefined
          ? [
              { type: 'Articles', id: arg.id },
              { type: 'Articles', id: 'LIST' },
            ]
          : [{ type: 'Articles', id: arg.id }],
    }),

    updateTableOfContents: builder.mutation<void, { id: QueryParams['id']; visible: boolean }>({
      query: ({ id, visible }) => ({
        url: `/${RESOURCE_PATH}/${id}/table_of_contents`,
        method: 'PATCH',
        body: { visible },
      }),
    }),

    deleteArticle: builder.mutation({
      query: (id) => {
        return {
          url: `/${RESOURCE_PATH}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Articles', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetTasksQuery,
  useCheckUniquenessMutation,
  useGetUniquenessLimitQuery,
  useLazyGetAnalyticsQuery,
  useGetArticleQuery,
  useLazySearchArticlesQuery,
  useLazyGetArticleQuery,
  useGetArticlesConstantsQuery,
  useAddArticleMutation,
  useDeleteArticleMutation,
  useUpdateArticleMutation,
  useUpdateTableOfContentsMutation,
} = articlesApi;
