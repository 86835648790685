import type { ReactNode, FC } from 'react';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

import { useAppSelector } from '@/shared/lib/rtk';
import { sessionModel, useGetProfile } from '@/entities/session';

type Props = {
  children: ReactNode;
};

export const UserProfile: FC<Props> = ({ children }) => {
  const { getProfile } = useGetProfile();
  const isLogin = useSelector(sessionModel.selectIsLogin);
  const user = useAppSelector(sessionModel.selectUser);

  useEffect(() => {
    if (isLogin && user === null) {
      getProfile();
    }
  }, [user, getProfile, isLogin]);

  return user === null ? (
    <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>{children}</>
  );
};
