import { useGetQueryParams } from '@/shared/lib/query-params-filter';

import {
  useGetRolesQuery,
  useGetRoleQuery,
  useAddRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
} from '../api';

const acceptedFilters = [''];

export const useGetAll = () => {
  const params = useGetQueryParams(acceptedFilters);

  const { data, isFetching } = useGetRolesQuery(
    { params },
    {
      selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
    },
  );

  return { roles: data ?? [], isFetching };
};

export const useGetById = (id: UniqueId) => {
  const { data, isFetching } = useGetRoleQuery(id);

  return { role: data, isFetching };
};

export const useCreate = () => {
  const [create] = useAddRoleMutation();

  return create;
};

export const useDelete = () => {
  const [deleteRole] = useDeleteRoleMutation();

  return deleteRole;
};

export const useUpdate = () => {
  const [update] = useUpdateRoleMutation();

  return update;
};
