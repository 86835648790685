import type { ImageResponseType, ImageType } from './types';

export const transformResponse = (
  response: ResponseWithPagination<ImageResponseType>,
): ResponseWithPagination<ImageType> => {
  const transformedImages = response.data.map(
    ({ id, name, width, height, originalUrl, previewUrl, crop, source }) => ({
      id,
      width,
      height,
      crop,
      source,
      label: name,
      originalUrl,
      previewUrl,
    }),
  );

  return { ...response, data: transformedImages };
};
