import type { FC } from 'react';
import type { TagType } from '@/entities/tag/types';

import { Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { confirmModal } from '@/shared/ui/modal';
import { TAG_DELETE } from '@/shared/config/permissions';

import { HasAccess } from '@/entities/session';

import { useDelete } from '../model';

type Props = {
  entity: TagType;
};

export const DeleteTag: FC<Props> = ({ entity }) => {
  const deleteTag = useDelete();

  const handleClick = async () => {
    confirmModal
      .show({
        title: 'This will delete selected tag.',
      })
      .then(handleDelete)
      .catch(handleClose);
  };

  const handleDelete = async () => {
    await deleteTag(entity.id);
    await confirmModal.hide();
  };

  const handleClose = async () => {
    await confirmModal.hide();
  };

  return (
    <HasAccess permissions={[TAG_DELETE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title="Delete">
          <DeleteIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
