import type { State } from './types';

import { createSlice } from '@reduxjs/toolkit';

import * as reducers from './reducers';
import { REDUCER_PATH } from './constants';

const initialState: State = {
  notifications: [],
};

const slice = createSlice({
  name: REDUCER_PATH,
  initialState,
  reducers,
});

export const actions = slice.actions;

export const reducer = { [REDUCER_PATH]: slice.reducer };
