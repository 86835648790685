import type { FC } from 'react';

import NiceModal from '@ebay/nice-modal-react';

import { Button } from '@/shared/ui';
import { useAppDispatch } from '@/shared/lib/rtk';
import { callToActionModel } from '@/entities/call-to-action';
import { categoryModel } from '@/entities/category';

import { MODAL_ID } from '../config';

type Props = {
  className?: string;
};

export const CreateCallToActionButton: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  categoryModel.useGetAllOptions();
  callToActionModel.useGetConstants();

  const handleClick = () => {
    dispatch(callToActionModel.actions.edit(null));
    NiceModal.show(MODAL_ID);
  };

  return <Button label="Create" onClick={handleClick} className={className} />;
};
