import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { uploadFormFieldsValues } from '../model';

import { Button, Drawer } from '@/shared/ui';
import { RHFForm, RHFSelect, RHFUploadButton } from '@/shared/lib/react-hook-form';
import { stopWordModel } from '@/entities/stop-word';
import { transformUploadFormData } from '@/entities/stop-word/api/transform-response';

import { uploadFormSchema, useUpload } from '../model';
import { UPLOAD_MODAL_ID } from '../config';

const StopWordsUploadForm: FC = () => {
  const { constants } = stopWordModel.useGetConstants();
  const upload = useUpload();

  const handleSubmit: SubmitHandler<uploadFormFieldsValues> = async (data) => {
    await upload(transformUploadFormData(data));
  };

  return (
    <RHFForm onSubmit={handleSubmit} schema={uploadFormSchema}>
      {({ loading }) => (
        <>
          <RHFUploadButton name="file" accept="text/plain" label="Upload file *.txt" />
          <RHFSelect
            name="uploadType"
            label="Stop words upload type"
            options={constants?.uploadType ?? []}
          />
          <Button loading={loading} type="submit" label="Save" color="primary" />
        </>
      )}
    </RHFForm>
  );
};

export const StopWordsUploadModal = () => (
  <Drawer id={UPLOAD_MODAL_ID}>
    <StopWordsUploadForm />
  </Drawer>
);
