import type { ImageType } from '@/entities/gallery/types';
import type { ReactNode } from 'react';

import React, { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Step, StepButton, Stepper } from '@mui/material';

import { Button, Modal } from '@/shared/ui';
import { ImagePayload } from '@/shared/editor/nodes';
import { RHFForm, RHFInput } from '@/shared/lib/react-hook-form';

import { SELECT_IMAGE_MODAL } from '../config';
import { insertImageSchema } from '../model';
import { ImageList } from './image-list';

import style from './insert-image.css';

const steps = ['Select an image', 'Save an image'];

export const InsertImage = React.memo(
  ({
    onInsert,
    onClose,
    onOpen,
    name,
    selectedImage,
    children,
  }: {
    onInsert: (image: ImagePayload) => void;
    onClose?: () => void;
    onOpen?: () => void;
    name: string;
    selectedImage?: ImagePayload;
    children: ({ open }: { open: () => void }) => ReactNode;
  }) => {
    const [activeStep, setActiveStep] = React.useState(selectedImage ? 1 : 0);
    const [image, setImage] = React.useState<ImagePayload | null>(null);

    useEffect(() => {
      if (!selectedImage) {
        setImage(null);
        setActiveStep(0);

        return;
      }

      setImage(selectedImage);
    }, [selectedImage]);

    const handleClick = () => {
      NiceModal.show(`${SELECT_IMAGE_MODAL}-${name}`);
    };

    const handleSelect = (image: ImageType) => {
      setActiveStep((step) => step + 1);
      setImage({ alt: image.alt ?? '', id: image.id, label: image.label, src: image.originalUrl });
    };

    const handleClose = () => {
      onClose && onClose();

      if (!selectedImage) {
        setImage(null);
        setActiveStep(0);

        return;
      }

      setImage(selectedImage);
    };

    return (
      <>
        {children({ open: handleClick })}

        <Modal id={`${SELECT_IMAGE_MODAL}-${name}`} onClose={handleClose} onOpen={onOpen}>
          <div style={{ minWidth: '1000px', padding: '1rem' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={() => setActiveStep(index)} disabled={index === 1 && !image}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && <ImageList onSelect={handleSelect} />}
            {activeStep === 1 && image !== null && (
              <RHFForm
                className={style.form}
                mode="onTouched"
                defaultValues={{ label: image.label ?? '', alt: image.alt }}
                schema={insertImageSchema}
                onSubmit={({ alt, label }) => {
                  onInsert({ ...image, alt, label });
                  NiceModal.hide(`${SELECT_IMAGE_MODAL}-${name}`);
                }}
              >
                {() => (
                  <>
                    <img className={style.image} src={image.src} />
                    <div className={style.inputs}>
                      <RHFInput name="alt" label="Alternative text" />
                      <RHFInput name="label" label="Caption" />
                      <Button
                        label="Insert"
                        type="submit"
                        color="primary"
                        style={{ marginRight: '10px' }}
                      />
                      <Button
                        label="Cancel"
                        color="primary"
                        onClick={() => NiceModal.hide(`${SELECT_IMAGE_MODAL}-${name}`)}
                        variant="outlined"
                      />
                    </div>
                  </>
                )}
              </RHFForm>
            )}
          </div>
        </Modal>
      </>
    );
  },
);

InsertImage.displayName = 'InsertImage';
