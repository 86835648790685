import type { Area, Point } from 'react-easy-crop';

import { useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';

import { Button } from '../button';
import { getCroppedImg } from './canvas';

import styles from './image-cropper.css';

export const ImageCropper = ({
  onCrop,
  imageSrc,
  base64 = false,
  cropShape = 'rect',
  aspect = 16 / 9,
}: {
  onCrop: (imageUrl: string, crop: Area) => void;
  imageSrc: string;
  base64?: boolean;
  cropShape?: 'rect' | 'round';
  aspect?: number;
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [area, setArea] = useState<Area | null>(null);

  const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setArea(croppedAreaPixels);
  };

  const handleSave = async () => {
    if (area) {
      const croppedUrl = await getCroppedImg(imageSrc, area, base64);
      croppedUrl && onCrop(croppedUrl, area);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.canvas}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          cropShape={cropShape}
          showGrid={false}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={handleCropComplete}
        />
      </div>

      <div className={styles.controls}>
        <Button variant="outlined" onClick={handleSave} label="Save" />
        <Slider
          className={styles.slider}
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={(event, newValue) => {
            if (typeof newValue === 'number') {
              setZoom(newValue);
            }
          }}
        />
      </div>
    </div>
  );
};
