import type { FC } from 'react';
import type { CallToActionType } from '@/entities/call-to-action/types';

import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { CTA_DELETE } from '@/shared/config/permissions';
import { confirmModal } from '@/shared/ui/modal';
import { HasAccess } from '@/entities/session';

import { useDelete } from '../model';

type Props = {
  entity: CallToActionType;
};

export const DeleteCallToAction: FC<Props> = ({ entity }) => {
  const deleteCallToAction = useDelete();

  const handleClick = async () => {
    confirmModal
      .show({
        title: 'This will delete call to action.',
      })
      .then(handleDelete)
      .catch(handleClose);
  };

  const handleDelete = async () => {
    await deleteCallToAction(entity.id);
    await confirmModal.hide();
  };

  const handleClose = async () => {
    await confirmModal.hide();
  };

  return (
    <HasAccess permissions={[CTA_DELETE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title={'Delete call to action'}>
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
