// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html, body, #root {
  height: 100%;
  width: 100%;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  font: 400 100%/1.4em Roboto, Helvetica, Ubuntu, Arial, sans-serif;
}

button {
  cursor: pointer;
}

input,
button,
a {
  outline: none;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 50px white inset;
}

ul,
ol {
  list-style: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/assets/css/reset.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EAGE,YAAY;EACZ,WAAW;EACX,6BAA6B;EAC7B,mCAAmC;EACnC,iEAAiE;AACnE;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,aAAa;EACb,qBAAqB;AACvB;;AAEA;;;;EAIE,kCAAkC;AACpC;;AAEA;;EAEE,gBAAgB;AAClB","sourcesContent":["* {\n  border: 0;\n  margin: 0;\n  padding: 0;\n}\n\nhtml {\n  box-sizing: border-box;\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: inherit;\n}\n\n:global html,\n:global body,\n:global #root {\n  height: 100%;\n  width: 100%;\n  -moz-osx-font-smoothing: auto;\n  -webkit-font-smoothing: antialiased;\n  font: 400 100%/1.4em Roboto, Helvetica, Ubuntu, Arial, sans-serif;\n}\n\nbutton {\n  cursor: pointer;\n}\n\ninput,\nbutton,\na {\n  outline: none;\n  text-decoration: none;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n  box-shadow: 0 0 0 50px white inset;\n}\n\nul,\nol {\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
