import { FC } from 'react';

import NiceModal from '@ebay/nice-modal-react';

import { Button } from '@/shared/ui';
import { useAppDispatch } from '@/shared/lib/rtk';
import { ARTICLE_UPDATE } from '@/shared/config/permissions';
import { articleModel } from '@/entities/article';
import { categoryModel } from '@/entities/category';
import { HasAccess } from '@/entities/session';

import { MODAL_ID } from '../config';

type Props = {
  className?: string;
};

export const CreateArticle: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  categoryModel.useGetAllOptions();
  articleModel.useGetConstants();

  const handleClick = () => {
    dispatch(articleModel.actions.edit(null));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[ARTICLE_UPDATE]}>
      <Button label="Create" onClick={handleClick} className={className} />
    </HasAccess>
  );
};
