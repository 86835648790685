import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { sessionModel, useLogin } from '@/entities/session';
import { LoginForm } from '@/features/auth';

import styles from './login.css';
import { useAppSelector } from '@/shared/lib/rtk';

export const LoginPage = () => {
  const { login } = useLogin();
  const isLogin = useAppSelector(sessionModel.selectIsLogin);

  if (isLogin) {
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={styles.formContainer}>
        <Avatar className={styles.formIcon}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <LoginForm onSubmit={login} />
      </Box>
    </Container>
  );
};
