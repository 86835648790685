import type { FieldsValues } from './schema';

import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { galleryModel } from '@/entities/gallery';
import { useShowNotification } from '@/shared/notification';
import { useAppDispatch } from '@/shared/lib/rtk';

import { MODAL_ID } from '../config';

export const useCreate = () => {
  const create = galleryModel.useCreate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues) =>
      create(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Image was uploaded`);
        })
        .catch(console.error),
    [create, showSuccessNotification],
  );
};

export const useUpdate = () => {
  const dispatch = useAppDispatch();
  const update = galleryModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues & EntityWithId) =>
      update(data)
        .unwrap()
        .then(() => {
          dispatch(galleryModel.actions.edit(null));
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Image was updated`);
        })
        .catch(console.error),
    [update, showSuccessNotification, dispatch],
  );
};

export const useDelete = () => {
  const deleteImage = galleryModel.useDelete();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId) => {
      deleteImage(id)
        .unwrap()
        .then(() => {
          showSuccessNotification(`Image was deleted`);
        })
        .catch(console.error);
    },
    [deleteImage, showSuccessNotification],
  );
};
