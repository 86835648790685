import {
  useGetImagesQuery,
  useGetImageQuery,
  useGetImagesConstantsQuery,
  useAddImageMutation,
  useDeleteImageMutation,
  useUpdateImageMutation,
  useLazySearchImagesQuery,
  useCropImageMutation,
} from '../api';
import { PaginationQueryParams } from '@/shared/lib/query-params-filter/types';

const defaultParams = {
  order: '-id',
};

export const useGetAll = (queryParams?: PaginationQueryParams) => {
  const { images, isFetching, lastPage, page } = useGetImagesQuery(
    {
      params: { ...defaultParams, ...queryParams },
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        images: data?.data ?? [],
        page: data?.currentPage,
        lastPage: data?.lastPage,
        isFetching,
      }),
    },
  );

  return { images, isFetching, lastPage, page };
};

export const useGetFromList = (id: UniqueId) => {
  const { image, isFetching } = useGetImagesQuery(
    {
      params: defaultParams,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        image: data?.data.find((image) => image.id === id),
        isFetching,
      }),
    },
  );

  return { image, isFetching };
};

export const useSearch = () => {
  const [searchImages, { images, page, lastPage, isFetching }] = useLazySearchImagesQuery({
    selectFromResult: ({ data, isFetching }) => ({
      images: data?.data,
      page: data?.currentPage,
      lastPage: data?.lastPage,
      isFetching,
    }),
  });

  return { searchImages, images, page, lastPage, isFetching };
};

export const useGetById = (id: UniqueId) => {
  const { data, isFetching } = useGetImageQuery(id);

  return { image: data, isFetching };
};

export const useGetConstants = () => {
  const { data, isFetching } = useGetImagesConstantsQuery(undefined, {
    selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
  });

  return { constants: data, isFetching };
};

export const useCreate = () => {
  const [create] = useAddImageMutation();

  return create;
};

export const useCrop = () => {
  const [crop] = useCropImageMutation();

  return crop;
};

export const useDelete = () => {
  const [deleteImage] = useDeleteImageMutation();

  return deleteImage;
};

export const useUpdate = () => {
  const [update] = useUpdateImageMutation();

  return update;
};
