import type { FC, ChangeEvent } from 'react';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import type { RefCallBack } from 'react-hook-form/dist/types';
import classNames from 'classnames';

import { FormControlLabel, FormHelperText, Switch as MUISwitch } from '@mui/material';

import styles from './switch.css';

type SwitchProps = {
  label?: string;
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  error?: FieldError;
  inputRef: RefCallBack;
  className?: string;
};

export const Switch: FC<SwitchProps> = ({ label, value, onChange, error, inputRef, className }) => (
  <>
    <FormControlLabel
      className={classNames(className, styles.root)}
      control={<MUISwitch value={value} onChange={onChange} checked={value} inputRef={inputRef} />}
      label={label}
    />
    {error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
  </>
);
