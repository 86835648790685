import { useController, useWatch } from 'react-hook-form';

import { isImageType } from '@/entities/gallery';
import { SelectImage } from '@/features/gallery/ui/select-image';

export const RHFSelectThumbnail = ({ watch, name }: { watch?: string; name: string }) => {
  const {
    field: { onChange, value },
  } = useController({ name });
  const field = useWatch(watch ? { name: watch, exact: true } : { name: '', disabled: true });

  return (
    <SelectImage
      selectedImage={watch && isImageType(field) ? value ?? field : value}
      name={name}
      onSelect={(image) => {
        onChange(image);
      }}
    />
  );
};
