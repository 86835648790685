import { z } from 'zod';

import {
  danger_symbols,
  repeating_symbols,
  symbols_at_end,
  symbols_at_start,
} from './validation-regexp';

type Titles = 'Headline' | 'Catchy title' | 'RSS title' | 'Facebook title' | 'Name';

export const title = (titleName: Titles) =>
  z
    .string({ required_error: `${titleName} is required` })
    .refine((value) => symbols_at_start.test(value), {
      message: `Must start with a letter, digits or quotes.`,
    })
    .refine((value) => symbols_at_end.test(value), {
      message: `Must end with a letter, digits, period, question or exclamation mark, closing quote or bracket.`,
    })
    .refine((value) => !repeating_symbols.test(value), {
      message: `Do not use repeating symbols.`,
    })
    .refine((value) => !danger_symbols.test(value), {
      message: `Do not use danger symbols: square, angle brackets and backslash – [ ] < > \\.`,
    });
