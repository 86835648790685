import { z } from 'zod';

import { title, slug, seoDescription, lengthRefine } from '@/shared/validation';
import { catchy_title } from '@/shared/validation/validation-regexp';

export const schema = z.object({
  mainHeadline: lengthRefine(title('Headline'), 110, 55).nullish().or(z.literal('')),
  catchyTitle: lengthRefine(title('Catchy title'), 250).refine(
    (value) => catchy_title.test(value),
    {
      message: `Allowed: Latin, Cyrillic, space, hyphens.`,
    },
  ),
  description: lengthRefine(seoDescription, 300),
  slug: lengthRefine(slug, 50),
  webEnabled: z.boolean().optional(),
});

export type FieldsValues = z.infer<typeof schema>;
