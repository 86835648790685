import type { FC } from 'react';
import type { ArticleType } from '@/entities/article/types';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { HasAccess } from '@/entities/session';

import { routes } from '@/shared/routes';
import { ARTICLE_UPDATE } from '@/shared/config/permissions';

type Props = {
  entity: ArticleType;
};

export const OpenEditor: FC<Props> = React.memo(({ entity }) => {
  const navigate = useNavigate();

  return (
    <HasAccess permissions={[ARTICLE_UPDATE]}>
      <IconButton onClick={() => navigate(`${routes.ARTICLES}/${entity.id}`)}>
        <Tooltip title={'Editor'}>
          <EditIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
});

OpenEditor.displayName = 'OpenEditor';
