import type { FC, ReactNode } from 'react';
import type { ButtonProps as DefaultButtonProps } from '@mui/material';

import { CircularProgress, Button as MUIButton } from '@mui/material';

type ButtonProps = {
  loading?: boolean;
  label?: string;
  icon?: ReactNode;
} & DefaultButtonProps;

export const Button: FC<ButtonProps> = ({
  disabled = false,
  loading = false,
  type,
  label,
  color,
  className,
  variant = 'contained',
  size = 'medium',
  onClick,
  style,
  icon,
}) => (
  <MUIButton
    style={style}
    type={type}
    variant={variant}
    className={className}
    color={color}
    disabled={disabled || loading}
    onClick={onClick}
    size={size}
  >
    {loading ? (
      <CircularProgress size={24} />
    ) : (
      <>
        {icon}
        {label}
      </>
    )}
  </MUIButton>
);
