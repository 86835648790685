import type { Area } from 'react-easy-crop/types';
import type { AuthorFieldsValues } from '@/features/user/model';
import type { AuthorFormData, SocialLinksType, SocialLinksKeys } from '@/entities/user/api/types';

export const prepareAuthorData = (
  formData: AuthorFieldsValues & { file?: FileList | null },
  crop: Area | null,
) => {
  const {
    description,
    bio,
    awards,
    education,
    experience,
    file,
    facebook,
    instagram,
    twitter,
    linkedin,
    muckrack,
  } = formData;

  const formSocialLinks: PartialRecord<SocialLinksKeys, string | undefined | null> = {
    facebook,
    instagram,
    twitter,
    linkedin,
    muckrack,
  };

  const clearedSocialLinks = Object.fromEntries(
    Object.entries(formSocialLinks).filter(([key, value]) => value),
  );

  const formattedData: Omit<AuthorFormData, 'socialLinks'> & {
    socialLinks: SocialLinksType | '';
  } = {
    description: description,
    bio: bio,
    awards: awards,
    education: education,
    experience: experience,
    socialLinks: Object.keys(clearedSocialLinks).length > 0 ? clearedSocialLinks : '',
  };

  if (file instanceof FileList && crop) {
    formattedData.crop = crop;
    formattedData.avatar = file;
  }

  return formattedData;
};
