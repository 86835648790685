import type { EditorState, LexicalEditor } from 'lexical';
import type { FieldsValues } from '../model';

import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { callToActionModel } from '@/entities/call-to-action';
import { categoryModel } from '@/entities/category';
import { Button, Drawer } from '@/shared/ui';
import { RHFInput, RHFSelect, RHFSwitch, RHFSyncAutocomplete } from '@/shared/lib/react-hook-form';
import { useAppSelector } from '@/shared/lib/rtk';
import { RichEditor } from '@/shared/editor';
import { Toolbar } from '@/shared/editor/plugins';

import { useCreate, useUpdate, schema } from '../model';
import { MODAL_ID } from '../config';

import styles from './form.module.css';

const prepareFormPayload = (
  formValues: Omit<FieldsValues, 'body'>,
  editorState: EditorState,
): FieldsValues => ({
  ...formValues,
  body: [editorState],
});

const CallToActionForm = () => {
  const { constants } = callToActionModel.useGetConstants();
  const { categoriesOptions } = categoryModel.useGetAllOptions();
  const [editor, setEditor] = useState<LexicalEditor>();
  const callToAction = useAppSelector(callToActionModel.selectEditing);
  const create = useCreate();
  const update = useUpdate();

  const methods = useForm({ resolver: zodResolver(schema), mode: 'onTouched' });

  const handleSubmit = async () => {
    const editorState = editor?.getEditorState().toJSON();

    await methods.handleSubmit(async (values) => {
      callToAction
        ? await update({ id: callToAction.id, ...prepareFormPayload(values, editorState) })
        : await create(prepareFormPayload(values, editorState));
    })();
  };

  useEffect(() => {
    if (callToAction) {
      methods.reset(callToAction);
    }
  }, [callToAction, methods]);

  const handleEditorStateChanged = (_, editor: LexicalEditor) => {
    setEditor(editor);
  };

  return (
    <FormProvider {...methods}>
      <RHFInput name="name" type="text" label="Name" />
      <RHFSelect name="position" label="Position" options={constants?.position ?? []} />
      <RHFSyncAutocomplete
        options={categoriesOptions ?? ''}
        name="categoryId"
        placeholder="Select category"
        label="Category"
      />
      <RHFSwitch name="isEnabled" label="Is enabled" className={styles.switch} />
      <RichEditor
        initialEditorState={callToAction ? callToAction.body[0] : null}
        onChange={handleEditorStateChanged}
        className={styles.editor}
      >
        <Toolbar bodyVersion />
      </RichEditor>
      <Button
        loading={methods.formState.isSubmitting}
        type="submit"
        label="Save"
        color="primary"
        onClick={() => handleSubmit()}
      />
    </FormProvider>
  );
};

export const CallToActionModal = () => (
  <Drawer id={MODAL_ID}>
    <CallToActionForm />
  </Drawer>
);
