import type { FC } from 'react';
import type { TagType } from '@/entities/tag/types';

import { Tooltip } from '@mui/material';
import NiceModal from '@ebay/nice-modal-react';
import IconButton from '@mui/material/IconButton';
import { Settings } from '@mui/icons-material';

import { useAppDispatch } from '@/shared/lib/rtk';
import { TAG_UPDATE } from '@/shared/config/permissions';

import { tagModel } from '@/entities/tag';
import { HasAccess } from '@/entities/session';

import { MODAL_ID } from '../config';

type Props = {
  entity: TagType;
};

export const EditTag: FC<Props> = ({ entity }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(tagModel.actions.edit(entity));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[TAG_UPDATE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title="Settings">
          <Settings fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
