import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { FieldsValues } from '../model';

import { Button, Drawer } from '@/shared/ui';
import { RHFForm, RHFInput, RHFSwitch } from '@/shared/lib/react-hook-form';
import { useAppSelector } from '@/shared/lib/rtk';
import { stopWordModel } from '@/entities/stop-word';

import { useCreate, useUpdate, schema } from '../model';
import { MODAL_ID } from '../config';

import styles from './form.css';

const StopWordForm: FC = () => {
  const stopWord = useAppSelector(stopWordModel.selectEditing);
  const create = useCreate();
  const update = useUpdate();

  const handleSubmit: SubmitHandler<FieldsValues> = async (data) => {
    stopWord ? await update({ id: stopWord.id, ...data }) : await create(data);
  };

  return (
    <RHFForm
      onSubmit={handleSubmit}
      schema={schema}
      defaultValues={stopWord ?? {}}
      className={styles.form}
    >
      {({ loading }) => (
        <>
          <RHFInput name="name" type="text" label="Name" />
          <RHFSwitch name="isArticle" label="For Article" className={styles.switch} />
          <RHFSwitch name="isTag" label="For Tag" className={styles.switch} />
          <Button loading={loading} type="submit" label="Save" color="primary" />
        </>
      )}
    </RHFForm>
  );
};

export const StopWordModal = () => (
  <Drawer id={MODAL_ID}>
    <StopWordForm />
  </Drawer>
);
