import type { FC } from 'react';
import type { ButtonProps } from '@mui/material';

import { Button } from '@/shared/ui';

import { CANCEL, CUSTOM, SUBMIT } from '../config/buttons';

import styles from './buttons.css';

interface ButtonsProps extends ButtonProps {
  loading?: boolean;
  label: string;
  rhfButtonType: string;
}
// TODO: Refactoring. Для чего нам эти if'ы
export const RHFButtons: FC<ButtonsProps> = ({
  disabled = false,
  loading = false,
  label,
  rhfButtonType,
  onClick,
  className,
}) => {
  if (rhfButtonType === SUBMIT) {
    return (
      <Button
        disabled={disabled}
        loading={loading}
        type="submit"
        label={label}
        color="primary"
        className={styles.submitBtn}
        onClick={onClick}
      />
    );
  }

  if (rhfButtonType === CANCEL) {
    return (
      <Button
        disabled={disabled}
        loading={loading}
        type="button"
        label={label}
        color="error"
        className={styles.cancelBtn}
        onClick={onClick}
      />
    );
  }

  if (rhfButtonType === CUSTOM) {
    return (
      <Button
        disabled={disabled}
        loading={loading}
        type="button"
        label={label}
        className={className}
        onClick={onClick}
      />
    );
  }

  return null;
};
