import { $getRoot, DOMConversionOutput, LexicalEditor, LexicalNode } from 'lexical';

import { httpsUrl } from '@/shared/validation';

import {
  INSERT_DAILY_MOTION_COMMAND,
  INSERT_FACEBOOK_COMMAND,
  INSERT_INSTAGRAM_COMMAND,
  INSERT_TIKTOK_COMMAND,
  INSERT_TWITTER_COMMAND,
  INSERT_YOUTUBE_COMMAND,
} from './commands';

const youtubeRegex = /(youtube\.com\/(watch.+?v=|embed\/)|youtu.be\/)(?<id>\w+)/;
const twitterRegex = /twitter.+?status\/(?<id>\w+)/;
const facebookRegex = /facebook/;
const instagramRegex = /instagram.+?(p|reel)\/(?<id>\w+)/;
const tiktokRegex = /tiktok.+?video\/(?<id>\w+)/;
const dailyMotionRegex = /dailymotion.+?video\/(?<id>\w+)/;

export type EmbedNodeCreator = (id: string, link: string) => LexicalNode;

export const EMBED_ATTRIBUTES = {
  link: 'data-lexical-embed-link',
  id: 'data-lexical-embed-id',
};

export function convertElementToNode(
  domNode: HTMLDivElement,
  nodeCreator: EmbedNodeCreator,
): DOMConversionOutput | null {
  const id = domNode.getAttribute('data-lexical-embed-id');
  const link = domNode.getAttribute('data-lexical-embed-link');

  if (id && link) {
    return { node: nodeCreator(id, link) };
  }

  return null;
}

const EMBED_LINK_VALIDATOR = {
  twitter: {
    validator: twitterRegex,
    command: INSERT_TWITTER_COMMAND,
    name: 'Twitter',
    domains: ['twitter.com', 't.co/'],
  },
  youtube: {
    validator: youtubeRegex,
    command: INSERT_YOUTUBE_COMMAND,
    name: 'Youtube',
    domains: ['youtube.com', 'youtu.be/'],
  },
  facebook: {
    validator: facebookRegex,
    command: INSERT_FACEBOOK_COMMAND,
    name: 'Facebook',
    domains: ['facebook.com'],
  },
  instagram: {
    validator: instagramRegex,
    command: INSERT_INSTAGRAM_COMMAND,
    name: 'Instagram',
    domains: ['instagram.com', 'instagr.am/'],
  },
  tiktok: {
    validator: tiktokRegex,
    command: INSERT_TIKTOK_COMMAND,
    name: 'Tiktok',
    domains: ['tiktok.com'],
  },
  dailyMotion: {
    validator: dailyMotionRegex,
    command: INSERT_DAILY_MOTION_COMMAND,
    name: 'Daily motion',
    domains: ['dailymotion.com'],
  },
};

const getPlatformFromLink = (link: string) => {
  const allowedDomains = Object.values(EMBED_LINK_VALIDATOR).reduce(
    (prev, { domains }) => [...prev, ...domains],
    [] as Array<string>,
  );
  const allowedValidator = new RegExp(`(${allowedDomains.join('|')})`, 'gi');
  const result = allowedValidator.exec(link);

  if (!result?.[0]) {
    return;
  }

  const domain = result?.[0];

  return Object.values(EMBED_LINK_VALIDATOR).find((item) => item.domains.includes(domain) && item);
};

export const insertEmbedPayload = (
  link: string,
  editor: LexicalEditor,
): { id: string; link: string; facebookType?: string } | undefined => {
  const platform = getPlatformFromLink(link);

  if (!platform) {
    return;
  }
  const { validator, command } = platform;

  if (command === INSERT_FACEBOOK_COMMAND) {
    const { searchParams, pathname } = new URL(link);
    const params = Object.fromEntries(searchParams);
    const id =
      params?.v ?? params?.fbid ?? params?.id ?? pathname.split('/').filter(Boolean).at(-1);
    const facebookType = link.match(/videos|watch|fb\.watch/i) ? 'fb-video' : 'fb-post';

    if (!id) {
      return;
    }

    editor.dispatchCommand(INSERT_FACEBOOK_COMMAND, { id, link, facebookType });
  }

  const result = validator.exec(link);

  if (result !== null && result.groups !== undefined) {
    const id = result.groups.id;

    editor.dispatchCommand(command, { id, link });
  }
};

export const clearHighlight = (textNodes: LexicalNode[]) => {
  textNodes.forEach((node) => {
    if (node.hasFormat('highlight')) {
      node.toggleFormat('highlight');
    }
  });
};

export const clearStateAndProceed = (
  action: () => Promise<unknown>,
  editor: LexicalEditor | undefined,
) => {
  if (typeof editor === 'undefined') {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    editor.update(
      () => {
        const textNodes = $getRoot().getAllTextNodes();

        const textNodesHasHighlight = textNodes.some((node) => node.hasFormat('highlight'));

        // If text nodes has 'highlight' format, clearHighlight method will
        // trigger editorState update and action will be resolve in onUpdate option
        // of editor with updated editorState

        textNodesHasHighlight
          ? clearHighlight($getRoot().getAllTextNodes())
          : action()
              .then((response) => resolve(response))
              .catch((err) => {
                reject(err);
              });
      },
      {
        onUpdate: () => {
          action()
            .then((response) => resolve(response))
            .catch((err) => {
              reject(err);
            });
        },
      },
    );
  });
};

export const isLinkValid = (link: string) => httpsUrl.safeParse(link).success;
