import type { AuthorFormData } from '@/entities/user/api/types';
import type { FieldsValues } from './schema';

import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { userModel } from '@/entities/user';
import { useShowNotification } from '@/shared/notification';
import { useAppDispatch } from '@/shared/lib/rtk';

import { MODAL_ID } from '../config';

export const useCreate = () => {
  const create = userModel.useCreate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues) =>
      create(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`User was created`);
        })
        .catch(console.error),
    [create, showSuccessNotification],
  );
};

export const useUpdate = () => {
  const dispatch = useAppDispatch();
  const update = userModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues & { id: UniqueId }) =>
      update(data)
        .unwrap()
        .then(() => {
          dispatch(userModel.actions.edit(null));
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`User was updated`);
        })
        .catch(console.error),
    [update, showSuccessNotification, dispatch],
  );
};

export const useUpdateAuthor = () => {
  const updateAuthor = userModel.useUpdateAuthor();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId, data: AuthorFormData) =>
      updateAuthor({ id: id, data: data })
        .unwrap()
        .then(() => {
          showSuccessNotification(`Author info was updated`);
        })
        .catch(console.error),
    [updateAuthor, showSuccessNotification],
  );
};

export const useDelete = () => {
  const deleteUser = userModel.useDelete();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId) => {
      deleteUser(id)
        .unwrap()
        .then(() => {
          showSuccessNotification(`User was deleted`);
        })
        .catch(console.error);
    },
    [deleteUser, showSuccessNotification],
  );
};
