import type { FC } from 'react';

import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import { LogoutButton } from '@/features/auth';
import { sessionModel } from '@/entities/session/';

import styles from './header.css';

type Props = {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

export const Header: FC<Props> = ({ toggleSidebar }) => {
  const user = useSelector(sessionModel.selectUser);

  return (
    <Box className={styles.container}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            className={styles.iconBtn}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className={styles.title}>
            Sportsbrief
          </Typography>
          <b>{user?.username}</b>
          <LogoutButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
};
