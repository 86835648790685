import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  id: string;
};

export const TweetComponent = ({ id }: Props) => {
  const containerRef = useRef(null);
  const isFirstRender = useRef(true);

  const [isTweetLoading, setIsTweetLoading] = useState(false);

  const createTweet = useCallback(async () => {
    if (containerRef.current) {
      await window.twttr.widgets.createTweet(id, containerRef.current);
      setIsTweetLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (isFirstRender.current) {
      setIsTweetLoading(true);

      if (window.twttr === undefined) {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        document.body?.appendChild(script);
        script.onload = createTweet;
      } else {
        createTweet();
      }

      isFirstRender.current = false;
    }
  }, [id, createTweet]);

  return (
    <>
      {isTweetLoading ? 'Tweet is loading...' : null}
      <div ref={containerRef} />
    </>
  );
};
