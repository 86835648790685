import { z } from 'zod';

export const schema = z.object({
  name: z.string({ required_error: 'Name is required' }),
  isArticle: z.boolean().optional(),
  isTag: z.boolean().optional(),
});

export type FieldsValues = z.infer<typeof schema>;

export const uploadFormSchema = z.object({
  file: z.instanceof(FileList, { message: 'File is required' }),
  uploadType: z.string({ required_error: 'Upload type is required' }),
});

export type uploadFormFieldsValues = z.infer<typeof uploadFormSchema>;
