// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-form__switch___t3G7T {
  display: block;
  padding: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/features/user/ui/user-form.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".switch {\n  display: block;\n  padding: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `user-form__switch___t3G7T`
};
export default ___CSS_LOADER_EXPORT___;
