import type { Option } from '@/shared/ui';

import { RHFAsyncAutocomplete } from '@/shared/lib/react-hook-form';

import { useSuggestions } from '../model';

export const UsersAsyncAutocomplete = ({
  users,
  fieldName,
  multiple = false,
}: {
  users: Array<Option> | Option | null;
  fieldName: string;
  multiple?: boolean;
}) => {
  const { searchUsers, suggestion, isFetching } = useSuggestions();

  const handleSearch = async (searchPhrase: string) => {
    await searchUsers({ search_field: 'username', search: searchPhrase });
  };

  return (
    <RHFAsyncAutocomplete
      loading={isFetching}
      placeholder={multiple ? 'Select users' : 'Select user'}
      value={users}
      options={suggestion ?? []}
      onInputChange={handleSearch}
      name={fieldName}
      multiple={multiple}
    />
  );
};
