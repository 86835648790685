import type { Area } from 'react-easy-crop/types';
import type { Dispatch, SetStateAction } from 'react';

import { useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Modal } from '@/shared/ui/modal';
import { ImageCropper } from '@/shared/ui/image-croppper';

export const AVATAR_IMAGE_MODAL = 'avatar-image-modal';

type SelectImageProps = {
  uploadImage: string | null;
  defaultImage: string | null | undefined;
  setCrop: Dispatch<SetStateAction<Area | null>>;
};

export const SelectAvatar = ({ uploadImage, defaultImage, setCrop }: SelectImageProps) => {
  const [croppedImage, setCroppedImage] = useState<string | null>();
  const [uploadImageState, setUploadImageState] = useState<string | null>();

  useEffect(() => {
    if (uploadImage) {
      setCroppedImage(null);
      setUploadImageState(uploadImage);
      NiceModal.show(`${AVATAR_IMAGE_MODAL}`);
      setCrop(null);
    }
  }, [uploadImage]);

  const handleCrop = (imageUrl: string, crop: Area) => {
    setUploadImageState(null);
    setCroppedImage(imageUrl);
    NiceModal.hide(`${AVATAR_IMAGE_MODAL}`);
    setCrop(crop);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${
          croppedImage ??
          uploadImage ??
          defaultImage ??
          'https://images.sportsbrief.com/images/original/default.jpg'
        })`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '500px',
        height: '500px',
        aspectRatio: '1 / 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {uploadImageState && (
        <Modal id={`${AVATAR_IMAGE_MODAL}`} disableBackdropClick>
          <ImageCropper
            onCrop={handleCrop}
            imageSrc={uploadImageState}
            cropShape={'round'}
            aspect={1}
            base64
          />
        </Modal>
      )}
    </div>
  );
};
