import { z } from 'zod';

import { regExpForSocialPlatform, url } from './validation-regexp';

export const socialPlatform = (platform: string) =>
  z
    .string()
    .url()
    .refine(
      (value) => {
        const isUrl = url.test(value);
        const platformRegexp = regExpForSocialPlatform(platform);
        return isUrl && platformRegexp.test(value);
      },
      {
        message: `Enter a valid ${platform} account link.`,
      },
    )
    .nullish()
    .or(z.literal(''));
