import type { NotificationType } from '@/shared/notification/model/types';

import { v4 as uuidv4 } from 'uuid';
import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';

import { NOTIFICATION_TIMEOUT } from './constants';
import { actions } from './slice';

const notificationFactory = (
  message: string,
  type: NotificationType,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const randomId = uuidv4();
  const notification = { message, type, id: randomId };

  dispatch(actions.add(notification));

  setTimeout(() => {
    dispatch(actions.remove(notification.id));
  }, NOTIFICATION_TIMEOUT);
};

export const showSuccessNotification = createAsyncThunk(
  'notification/status',
  (message: string, { dispatch }) => notificationFactory(message, 'success', dispatch),
);

export const showErrorNotification = createAsyncThunk(
  'notification/status',
  (message: string, { dispatch }) => notificationFactory(message, 'error', dispatch),
);
