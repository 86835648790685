import type { FC } from 'react';
import type { SelectOption } from '@/shared/ui/select';

import { useController } from 'react-hook-form';

import { Select } from '@/shared/ui';

type SelectProps = {
  name: string;
  label: string;
  options: SelectOption[];
  disabled?: boolean;
};

export const RHFSelect: FC<SelectProps> = ({ name, label, options, disabled = false }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name });
  return (
    <Select
      options={options}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      onBlur={onBlur}
      inputRef={ref}
      disabled={disabled}
    />
  );
};
