import type { FC, ReactNode } from 'react';

import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { routes } from '@/shared/routes';
import { useAppSelector } from '@/shared/lib/rtk';
import { sessionModel } from '@/entities/session';

type Props = {
  children?: ReactNode;
};

export const PersistAuth: FC<Props> = () => {
  const { pathname } = useLocation();
  const isLogin = useAppSelector(sessionModel.selectIsLogin);
  const user = useAppSelector(sessionModel.selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin && (pathname !== routes.LOGIN || pathname !== '/')) {
      navigate(routes.LOGIN, { replace: true });
    }

    if (isLogin && (pathname === routes.LOGIN || pathname === '/')) {
      navigate(routes.TASKS, { replace: true });
    }
  }, [isLogin, navigate, user, pathname]);

  return <Outlet />;
};
