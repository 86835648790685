import type { ReactNode } from 'react';
import type { DrawerProps } from '@mui/material';

import { Drawer as MuiDrawer } from '@mui/material';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import styles from './drawer.css';

type Props = {
  onClose?: () => void;
  anchor?: DrawerProps['anchor'];
  children: ReactNode;
};

export const Drawer = NiceModal.create<Props>(({ onClose, anchor = 'right', children }) => {
  const { visible, hide } = useModal();

  const handleClose = async () => {
    onClose && onClose();
    await hide();
  };
  return (
    <MuiDrawer open={visible} onClose={handleClose} anchor={anchor} className={styles.root}>
      {children}
    </MuiDrawer>
  );
});
