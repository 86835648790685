import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  link: string;
};

export const InstagramComponent = ({ link }: Props) => {
  const containerRef = useRef(null);
  const isFirstRender = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  const createEmbed = useCallback(async () => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      setIsLoading(true);

      if (window.instgrm === undefined) {
        const script = document.createElement('script');
        script.src = 'https://www.instagram.com/embed.js';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
          createEmbed();
        };
      } else {
        createEmbed();
      }

      isFirstRender.current = false;
    }
  }, [link, createEmbed]);

  return (
    <>
      {isLoading ? 'Embed is loading...' : null}
      <div ref={containerRef}>
        <blockquote className="instagram-media" data-instgrm-captioned data-instgrm-version="7">
          <p>
            <a href={link} rel="nofollow noopener noreferrer" target="_blank" />
          </p>
        </blockquote>
      </div>
    </>
  );
};
