import { useEffect } from 'react';
import { TextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export const TextCleanupPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, (textNode) => {
      if (textNode.getTextContent().includes('\xa0')) {
        textNode.setTextContent(textNode.getTextContent().replaceAll('\xa0', ' '));
      }
    });
  }, [editor]);

  return null;
};
