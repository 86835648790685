import type { FC } from 'react';
import type { ArticleType } from '@/entities/article/types';

import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';

import styles from './task-card.css';

type Props = {
  cardData: ArticleType;
  actionDelete: JSX.Element;
};

export const TaskCard: FC<Props> = ({ cardData, actionDelete }) => {
  return (
    <Card className={styles.card}>
      <CardHeader
        className={styles.header}
        title={` ID : ${cardData.id}`}
        action={actionDelete}
        disableTypography={true}
      />
      <CardContent className={styles.content}>
        <Typography className={styles.contentTypography}>{cardData.mainHeadline}</Typography>
      </CardContent>
      <CardActions className={styles.footer}>
        <div className={styles.publishedTime}>
          {new Intl.DateTimeFormat('en-GB', {
            dateStyle: 'short',
            timeStyle: 'short',
            hour12: false,
          }).format(new Date(cardData.updatedAt))}
        </div>
      </CardActions>
    </Card>
  );
};
