import { createSelector } from '@reduxjs/toolkit';

import type { State } from './types';

import { createBaseSelector } from '@/shared/lib/rtk';
import { REDUCER_PATH } from './constants';

const baseSelector = createBaseSelector<State>(REDUCER_PATH);

export const selectIsLogin = createSelector(baseSelector, (session) => session.isLogin);

export const selectUser = createSelector(baseSelector, (session) => session.user);
