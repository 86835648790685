import { z } from 'zod';

export const FormSchema = z.object({
  email: z
    .string({
      required_error: 'Email is required',
    })
    .email({ message: 'Type correct email' }),
  password: z.string({
    required_error: 'Password is required',
  }),
});
