// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons__submitBtn___eulcO {
    margin: 24px 0 16px;
}

.buttons__cancelBtn___GstJe {
    margin: 24px 0 16px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/lib/react-hook-form/ui/buttons.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".submitBtn {\n    margin: 24px 0 16px;\n}\n\n.cancelBtn {\n    margin: 24px 0 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitBtn": `buttons__submitBtn___eulcO`,
	"cancelBtn": `buttons__cancelBtn___GstJe`
};
export default ___CSS_LOADER_EXPORT___;
