import { z } from 'zod';

import { danger_symbols, repeating_symbols, symbols_at_start } from './validation-regexp';

export const seoDescription = z
  .string({ required_error: 'The description field is required' })
  .refine((value) => symbols_at_start.test(value), {
    message: `Must start with a letter, digits or quotes.`,
  })
  .refine((value) => !repeating_symbols.test(value), {
    message: `Do not use repeating symbols.`,
  })
  .refine((value) => !danger_symbols.test(value), {
    message: `Do not use danger symbols: square, angle brackets and backslash – [ ] < > \\.`,
  });
