import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type { CategoryType } from '@/entities/category/types';

import { categoryModel } from '@/entities/category';
import { DataGrid } from '@/shared/ui';

import { DeleteCategory } from './delete-category';
import { EditCategory } from './edit-category';

const columns: NarrowGridColumns<CategoryType> = [
  {
    field: 'catchyTitle',
    headerName: 'Catchy title',
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'slug',
    headerName: 'Slug',
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'webEnabled',
    headerName: 'Show on main page',
    width: 200,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => (row.webEnabled ? 'Yes' : 'No'),
  },
];

export const CategoryList = () => {
  const { categories, isFetching } = categoryModel.useGetAll();

  return (
    <DataGrid
      hidePagination
      columns={columns}
      loading={isFetching}
      rows={categories}
      actions={(entity) => (
        <>
          <DeleteCategory entity={entity} /> <EditCategory entity={entity} />
        </>
      )}
    />
  );
};
