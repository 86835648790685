import type { ReactNode } from 'react';
import type { NodeKey } from 'lexical';

import { useCallback, useEffect, useRef } from 'react';
import {
  $getNodeByKey,
  $getSelection,
  $isDecoratorNode,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { Button } from '@/shared/ui';

import styles from './index.css';

type Props = Readonly<{
  children: ReactNode;
  onEdit?: () => void;
  nodeKey: NodeKey;
}>;

export const DecoratorBlock = ({ children, onEdit, nodeKey }: Props) => {
  const [editor] = useLexicalComposerContext();

  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  const ref = useRef(null);
  const className = isSelected
    ? `${editor._config.theme.embedBlock?.base} ${editor._config.theme.embedBlock?.focus}` ?? ''
    : editor._config.theme.embedBlock?.base ?? '';

  const handleDelete = useCallback(
    (event: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);

        if ($isDecoratorNode(node)) {
          node.remove();
        }

        setSelected(false);
      }

      return false;
    },
    [isSelected, nodeKey, setSelected],
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        (event) => {
          if (event.target === ref.current) {
            event.preventDefault();
            if (!event.shiftKey) {
              clearSelection();
            }

            setSelected(!isSelected);
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(KEY_DELETE_COMMAND, handleDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, handleDelete, COMMAND_PRIORITY_LOW),
    );
  }, [clearSelection, editor, isSelected, nodeKey, handleDelete, setSelected]);

  const handleDeleteButtonClick = () => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if ($isDecoratorNode(node)) {
        node.remove();
      }
    });
  };

  return (
    <div className={className} ref={ref}>
      <div className={styles.actionButtons}>
        <Button icon={<DeleteIcon />} onClick={handleDeleteButtonClick} color="error" />
        {onEdit && <Button icon={<EditIcon />} onClick={onEdit} />}
      </div>
      {children}
    </div>
  );
};
