// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button__root___xdUHs {
  flex-shrink: 0;
  height: 100%;
}

.filter-button__buttons___SOlnz {
  display: flex;
  gap: 10px;
}

.filter-button__button___uiXr0 {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/filter-button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".root {\n  flex-shrink: 0;\n  height: 100%;\n}\n\n.buttons {\n  display: flex;\n  gap: 10px;\n}\n\n.button {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `filter-button__root___xdUHs`,
	"buttons": `filter-button__buttons___SOlnz`,
	"button": `filter-button__button___uiXr0`
};
export default ___CSS_LOADER_EXPORT___;
