// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-loader__isLoading___KhOtx {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/page-loader/page-loader.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,gBAAgB;;EAEhB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,0CAA0C;AAC5C","sourcesContent":["\n.isLoading {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 9999999;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  background-color: rgba(255, 255, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isLoading": `page-loader__isLoading___KhOtx`
};
export default ___CSS_LOADER_EXPORT___;
