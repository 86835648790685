import { Alert, Snackbar } from '@mui/material';

import { useAppSelector } from '@/shared/lib/rtk';

import { selectNotifications } from '../model';

import styles from './notification.css';

export const Notifications = () => {
  const notifications = useAppSelector(selectNotifications);

  return (
    <Snackbar
      open={notifications.length !== 0}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={styles.snackbar}
    >
      <div>
        {notifications.map((notification) => (
          <Alert
            variant="filled"
            severity={notification.type}
            className={styles.alert}
            key={notification.id}
          >
            {notification.message}
          </Alert>
        ))}
      </div>
    </Snackbar>
  );
};
