import { Button } from '@mui/material';

import { useLogout } from '@/entities/session';

export const LogoutButton = () => {
  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <Button color="inherit" onClick={handleLogout}>
      Logout
    </Button>
  );
};
