import type { ReactNode } from 'react';
import type { ModalProps } from '@mui/material';

import { useEffect } from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Button } from '../button';

type Props = {
  onClose?: () => void;
  onOpen?: () => void;
  children: ReactNode;
  disableBackdropClick?: boolean;
};

export const Modal = NiceModal.create<Props>(
  ({ onClose, onOpen, children, disableBackdropClick = false }) => {
    const { visible, hide, resolveHide, remove } = useModal();

    const handleClose: ModalProps['onClose'] = async (event, reason) => {
      if (disableBackdropClick && reason && reason == 'backdropClick') return;
      await hide();
    };

    useEffect(() => {
      if (visible) {
        onOpen && onOpen();
      }
    }, [visible, onOpen]);

    return (
      <MuiDialog
        open={visible}
        onClose={handleClose}
        maxWidth={'lg'}
        TransitionProps={{
          onExited: () => {
            onClose && onClose();

            resolveHide();
            remove();
          },
        }}
      >
        {children}
      </MuiDialog>
    );
  },
);

type ConfirmModalProps = {
  title: string;
};

const CONFIRM_MODAL_ID = 'confirm_modal';

export const ConfirmModal = NiceModal.create<ConfirmModalProps>(({ title }) => {
  const { visible, hide, resolve, reject } = useModal();

  const handleConfirm = () => {
    resolve();
  };

  const handleReject = () => {
    reject();
  };

  const handleClose = async () => {
    await hide();
  };

  return (
    <MuiDialog open={visible} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <p>{title}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} variant={'text'} color={'error'} label={'No'} />
        <Button onClick={handleConfirm} variant={'text'} label="Yes" />
      </DialogActions>
    </MuiDialog>
  );
});

export const confirmModal = {
  show: (props: ConfirmModalProps) => NiceModal.show(CONFIRM_MODAL_ID, props),
  hide: () => NiceModal.hide(CONFIRM_MODAL_ID),
  id: CONFIRM_MODAL_ID,
};
