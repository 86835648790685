import type { FC } from 'react';

import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { PageLoadingProvider } from '@/shared/ui';
import { Notifications } from '@/shared/notification';

import { routing } from '../routing';
import { store } from '../store';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3A73D7',
      light: '#277CFD',
    },
    secondary: {
      main: '#C2F92C',
    },
    warning: {
      main: '#F88A25',
    },
    error: {
      main: '#EA3944',
    },
    success: {
      main: '#6BA351',
    },
    info: {
      main: '#A8A8A8',
    },
  },
});

export const Providers: FC = () => (
  <StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <PageLoadingProvider>
            <RouterProvider router={routing} />
          </PageLoadingProvider>
          <Notifications />
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </StrictMode>
);
