import { z } from 'zod';

import { danger_symbols, repeating_symbols, position as position_re } from './validation-regexp';

export const position = z
  .string()
  .refine((value) => position_re.test(value), {
    message: `Allowed: Latin, space, hyphens. Must start and end with a letter.`,
  })
  .refine((value) => !repeating_symbols.test(value), {
    message: `Do not use repeating symbols.`,
  })
  .refine((value) => !danger_symbols.test(value), {
    message: `Do not use danger symbols: square, angle brackets and backslash – [ ] < > \\.`,
  });
