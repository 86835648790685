import type { Dispatch, SetStateAction } from 'react';

import { z } from 'zod';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Paper, Popper } from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { httpsUrl } from '@/shared/validation';

import { RHFForm, RHFInput } from '../../lib/react-hook-form';
import { TOGGLE_LINK_COMMAND } from '../nodes/link-node';

import styles from './link-editor.css';

const EmbedFormSchema = z.object({
  url: httpsUrl,
});

export const LinkEditor = ({
  anchor,
  url,
  open,
  setOpen,
  setLinkUrl,
}: {
  anchor: HTMLElement | null;
  url: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setLinkUrl: Dispatch<SetStateAction<string>>;
}) => {
  const [editor] = useLexicalComposerContext();

  const handleSubmit = async ({ url }: { url: string }) => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
    setLinkUrl(url);
    setOpen(false);
  };

  const handleDelete = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    setOpen(false);
  };

  return (
    <Popper open={open} anchorEl={anchor} className={styles.popper} placement="bottom-start">
      <Paper className={styles.paper}>
        <RHFForm
          onSubmit={handleSubmit}
          defaultValues={{ url }}
          reset={url ? { url } : {}}
          schema={EmbedFormSchema}
        >
          {() => (
            <div className={styles.container}>
              <RHFInput name="url" size="small" className={styles.input} multiline />
              <IconButton
                type="submit"
                size="small"
                color="success"
                className={styles.submitButton}
              >
                <CheckIcon />
              </IconButton>
              <IconButton size="small" className={styles.deleteButton} onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </RHFForm>
      </Paper>
    </Popper>
  );
};
