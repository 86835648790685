import type { ReactElement } from 'react';

import classnames from 'classnames';
import { $getRoot, EditorConfig, LexicalEditor, LineBreakNode, ParagraphNode } from 'lexical';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';

import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { InitialEditorStateType, LexicalComposer } from '@lexical/react/LexicalComposer';

import {
  EmbedPlugin,
  ImagePlugin,
  ToolbarProps,
  UniquenessPlugin,
  LinkPopoverPlugin,
  LinkPlugin,
  TextCleanupPlugin,
  TreeViewPlugin,
} from '../plugins';

import {
  YoutubeNode,
  ImageNode,
  FacebookNode,
  TweetNode,
  InstagramNode,
  DailyMotionNode,
  TiktokNode,
  LinkNode,
  HeadingNode,
} from '../nodes';

import styles from './index.css';

export const editorConfig: EditorConfig = {
  // The editor theme
  theme: {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: styles.paragraph,
    quote: styles.quote,
    heading: {
      h1: 'editor-heading-h1',
      h2: styles.h2,
      h3: 'editor-heading-h3',
      h4: 'editor-heading-h4',
      h5: 'editor-heading-h5',
    },
    list: {
      nested: {
        listitem: 'editor-nested-listitem',
      },
      ol: styles.ol,
      ul: styles.ul,
      listitem: 'editor-listitem',
    },
    image: styles.img,
    link: 'editor-link',
    text: {
      bold: styles.bold,
      italic: styles.italic,
      overflowed: 'editor-text-overflowed',
      hashtag: 'editor-text-hashtag',
      underline: styles.underline,
      strikethrough: 'editor-text-strikethrough',
      underlineStrikethrough: 'editor-text-underlineStrikethrough',
      code: 'editor-text-code',
    },
    code: 'editor-code',
    embedBlock: {
      base: styles.embedBlock,
      focus: styles.embedBlockFocus,
    },
    blockCursor: styles.blockCursor,
    codeHighlight: {
      atrule: 'editor-tokenAttr',
      attr: 'editor-tokenAttr',
      boolean: 'editor-tokenProperty',
      builtin: 'editor-tokenSelector',
      cdata: 'editor-tokenComment',
      char: 'editor-tokenSelector',
      class: 'editor-tokenFunction',
      'class-name': 'editor-tokenFunction',
      comment: 'editor-tokenComment',
      constant: 'editor-tokenProperty',
      deleted: 'editor-tokenProperty',
      doctype: 'editor-tokenComment',
      entity: 'editor-tokenOperator',
      function: 'editor-tokenFunction',
      important: 'editor-tokenVariable',
      inserted: 'editor-tokenSelector',
      keyword: 'editor-tokenAttr',
      namespace: 'editor-tokenVariable',
      number: 'editor-tokenProperty',
      operator: 'editor-tokenOperator',
      prolog: 'editor-tokenComment',
      property: 'editor-tokenProperty',
      punctuation: 'editor-tokenPunctuation',
      regex: 'editor-tokenVariable',
      selector: 'editor-tokenSelector',
      string: 'editor-tokenSelector',
      symbol: 'editor-tokenProperty',
      tag: 'editor-tokenProperty',
      url: 'editor-tokenOperator',
      variable: 'editor-tokenVariable',
    },
  },
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    {
      replace: LineBreakNode,
      with: () => ParagraphNode,
    },
    LinkNode,
    ImageNode,
    YoutubeNode,
    TweetNode,
    FacebookNode,
    InstagramNode,
    TiktokNode,
    DailyMotionNode,
  ],
};

export const RichEditor = ({
  initialEditorState,
  onChange,
  className,
  children,
  plagiatState,
  debugMode = false,
}: {
  initialEditorState: InitialEditorStateType;
  onChange: (textContent: string, editor: LexicalEditor) => void;
  className?: string;
  children?: ReactElement<ToolbarProps>;
  plagiatState?: string[];
  debugMode?: boolean;
}) => {
  return (
    <LexicalComposer
      initialConfig={
        initialEditorState && typeof initialEditorState !== 'string'
          ? { ...editorConfig, editorState: JSON.stringify(initialEditorState) }
          : editorConfig
      }
    >
      <div className={classnames(styles.editorContainer, className)}>
        <div className={styles.toolbar}>{children}</div>
        <RichTextPlugin
          contentEditable={<ContentEditable className={styles.editorInput} />}
          placeholder={<></>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin defaultSelection="rootStart" />
        <TablePlugin />
        <ImagePlugin />
        <OnChangePlugin
          onChange={(editorState, editor) => {
            editorState.read(() => {
              onChange($getRoot().getTextContent(), editor);
            });
          }}
        />
        <EmbedPlugin />
        <LinkPopoverPlugin />
        <ListPlugin />
        <TextCleanupPlugin />
        <LinkPlugin />
        <UniquenessPlugin plagiatState={plagiatState} />
        {debugMode && <TreeViewPlugin />}
      </div>
    </LexicalComposer>
  );
};
