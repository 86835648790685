// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select__root___xgvCl {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/select.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".root {\n  margin-top: 1rem;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `select__root___xgvCl`
};
export default ___CSS_LOADER_EXPORT___;
