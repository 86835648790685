import type { CategoryType } from '../api/types';
import type { Option } from '@/shared/ui';

import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

import { useGetQueryParams } from '@/shared/lib/query-params-filter';
import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useLazySearchCategoriesQuery,
} from '../api';

const acceptedFilters = [''];

export const useGetAll = () => {
  const params = useGetQueryParams(acceptedFilters);

  const { data, isFetching } = useGetCategoriesQuery(
    { params },
    {
      selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
    },
  );

  return { categories: data ?? [], isFetching };
};

export const useSuggestions = () => {
  const [searchCategories, { isFetching, suggestions }] = useLazySearchCategoriesQuery({
    selectFromResult: ({ data, isFetching }) => ({
      suggestions: data
        ? data.data.map(({ id, catchyTitle }) => ({ value: id, label: catchyTitle }))
        : [],
      isFetching,
    }),
  });

  return { searchCategories, isFetching, suggestions };
};

export const useGetAllOptions = () => {
  const selectOptions = useMemo(() => {
    const emptyArray: Array<Option> = [];

    return createSelector(
      (categories: Array<CategoryType> | undefined) => categories,
      (categories) =>
        categories?.map(({ id, catchyTitle }) => ({
          value: id,
          name: catchyTitle,
        })) ?? emptyArray,
    );
  }, []);

  return useGetCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      categoriesOptions: selectOptions(data),
    }),
  });
};

export const useGetFromList = (id: UniqueId) => {
  const params = useGetQueryParams(acceptedFilters);

  const { category, isFetching } = useGetCategoriesQuery(
    { params },
    {
      selectFromResult: ({ data = [], isFetching }) => ({
        category: data.find((category) => category.id === id),
        isFetching,
      }),
    },
  );

  return { category, isFetching };
};

export const useCreate = () => {
  const [create] = useAddCategoryMutation();

  return create;
};

export const useUpdate = () => {
  const [update] = useUpdateCategoryMutation();

  return update;
};

export const useDelete = () => {
  const [deleteCategory] = useDeleteCategoryMutation();

  return deleteCategory;
};
