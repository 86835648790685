import type { FC, MouseEvent, ReactNode } from 'react';

import { useState } from 'react';
import { Box, Popover } from '@mui/material';

import { Button } from './button';

import styles from './filter-button.css';

type Props = {
  label: string;
  children: ReactNode;
  values: Array<string | number>;
  resetFilter: () => void;
};

export const FilterButton: FC<Props> = ({ label, children, values, resetFilter }) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorElement);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div className={styles.root}>
      <Button
        className={styles.button}
        variant={values.length ? 'contained' : 'outlined'}
        onClick={handleClick}
        label={`${label}: ${values.length}`}
      />

      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, width: '400px' }}>
          {children}
          <div className={styles.buttons}>
            <Button size="small" onClick={handleClose} label={`Apply`} />
            <Button
              variant="outlined"
              size="small"
              onClick={resetFilter}
              label={`Reset ${label}`}
            />
          </div>
        </Box>
      </Popover>
    </div>
  );
};
