import type { ArticleType } from '@/entities/article/api/types';
import type { Dispatch, SetStateAction } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classnames from 'classnames';

import { useUniquenessContext } from '@/entities/article';

import { SearchConsole } from './search-console';
import { UniquenessChecker } from './uniqueness-checker';
import { MIN_PERCENTAGE } from '../config';

import styles from './stats.css';

export const ArticleStats = ({
  article,
  plagiatState,
  setPlagiatState,
  saveArticleAndCheckUniqueness,
  textContent,
}: {
  article: ArticleType;
  plagiatState: string[];
  setPlagiatState: Dispatch<SetStateAction<string[]>>;
  saveArticleAndCheckUniqueness: () => Promise<unknown>;
  textContent: string;
}) => {
  const { percentage } = useUniquenessContext();

  const roundedInt = article.uniqueness ? Math.round(article.uniqueness) : 0;

  return (
    <>
      <Accordion className={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Uniqueness check &nbsp;
          <span
            className={classnames({
              [styles.success]: roundedInt >= MIN_PERCENTAGE,
              [styles.warning]: roundedInt < MIN_PERCENTAGE,
            })}
          >
            {percentage ? percentage + '%' : ''}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <UniquenessChecker
            article={article}
            plagiatState={plagiatState}
            setPlagiatState={setPlagiatState}
            saveArticleAndCheckUniqueness={saveArticleAndCheckUniqueness}
            textContent={textContent}
          />
        </AccordionDetails>
      </Accordion>

      {!article.isEvergreen && (
        <Accordion className={styles.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Search console</AccordionSummary>
          <AccordionDetails>
            <SearchConsole article={article} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};
