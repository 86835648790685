import { Children, cloneElement, useState, isValidElement, ReactElement } from 'react';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import { TabPanelProps } from './tab-panel';

export const Tabs = ({ children }: { children: Array<ReactElement<TabPanelProps>> }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <MuiTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return null;
          }

          const { tabLabel = null } = child.props;

          return child && tabLabel ? <Tab key={tabLabel} label={tabLabel ?? ''} /> : null;
        })}
      </MuiTabs>

      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return null;
        }

        return cloneElement(child, { value, tabIndex: index });
      })}
    </div>
  );
};
