import type { UseSearch } from '@/shared/api/types';
import type { StopWordType } from '../api/types';

import { useCallback } from 'react';

import {
  useGetStopWordsQuery,
  useGetStopWordQuery,
  useGetStopWordsConstantsQuery,
  useAddStopWordMutation,
  useUploadStopWordsMutation,
  useDeleteStopWordMutation,
  useUpdateStopWordMutation,
  useLazySearchStopWordsQuery,
} from '../api';
import { usePagination } from '@/shared/lib/query-params-filter';

export const useGetAll = (queryParams?: PaginationQueryParams) => {
  const { stopWords, isFetching, total } = useGetStopWordsQuery(
    {
      ...queryParams,
      params: {
        ...queryParams?.params,
      },
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        stopWords: data?.data,
        total: data?.total,
        isFetching,
      }),
    },
  );

  return { stopWords, isFetching, total };
};

export const useSearch: UseSearch<StopWordType> = () => {
  const [trigger, { stopWords, total, isFetching }] = useLazySearchStopWordsQuery({
    selectFromResult: ({ data, isFetching }) => ({
      stopWords: data?.data,
      total: data?.total ?? 0,
      isFetching,
    }),
  });

  const handleSearch = useCallback(
    (search: string, params?: PaginationQueryParams) =>
      trigger({
        search_field: 'name',
        search,
        params: {
          ...params,
        },
      }),
    [trigger],
  );

  return [handleSearch, stopWords, total, isFetching];
};

export const useGetFromList = (id: UniqueId) => {
  const { page, perPage } = usePagination();
  const { stopWord, isFetching } = useGetStopWordsQuery(
    {
      page,
      per_page: perPage,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        stopWord: data?.data.find((stopWord) => stopWord.id === id),
        isFetching,
      }),
    },
  );

  return { stopWord, isFetching };
};

export const useGetConstants = () => {
  const { data, isFetching } = useGetStopWordsConstantsQuery(undefined, {
    selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
  });

  return { constants: data, isFetching };
};

export const useGetById = (id: UniqueId) => {
  const { data, isFetching } = useGetStopWordQuery(id);

  return { stopWord: data, isFetching };
};

export const useCreate = () => {
  const [create] = useAddStopWordMutation();

  return create;
};

export const useUpload = () => {
  const [upload] = useUploadStopWordsMutation();

  return upload;
};

export const useDelete = () => {
  const [deleteStopWord] = useDeleteStopWordMutation();

  return deleteStopWord;
};

export const useUpdate = () => {
  const [update] = useUpdateStopWordMutation();

  return update;
};
