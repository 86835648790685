// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-card__card___aMemE {
  margin: 5px;
  font-size: 12px;
}

.task-card__header___UQZfZ {
  padding-bottom: 0;
}

.task-card__header___UQZfZ .MuiCardHeader-action {
  margin-right: 0;
}

.task-card__footer___KIuIx {
  padding: 0 16px 16px;
}

.task-card__publishedTime___EDqCh {
  flex: 1 1 auto;
}

.task-card__content___tQV25 {
  padding: 0 16px;
}

.task-card__actions___WtIy4 {
  color: rgba(0, 0, 0, 0.5);
}

.task-card__contentTypography___lftPv {
  font-size: 18px;
  padding: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/entities/article/ui/task-card.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".card {\n  margin: 5px;\n  font-size: 12px;\n}\n\n.header {\n  padding-bottom: 0;\n}\n\n.header :global .MuiCardHeader-action {\n  margin-right: 0;\n}\n\n.footer {\n  padding: 0 16px 16px;\n}\n\n.publishedTime {\n  flex: 1 1 auto;\n}\n\n.content {\n  padding: 0 16px;\n}\n\n.actions {\n  color: rgba(0, 0, 0, 0.5);\n}\n\n.contentTypography {\n  font-size: 18px;\n  padding: 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `task-card__card___aMemE`,
	"header": `task-card__header___UQZfZ`,
	"footer": `task-card__footer___KIuIx`,
	"publishedTime": `task-card__publishedTime___EDqCh`,
	"content": `task-card__content___tQV25`,
	"actions": `task-card__actions___WtIy4`,
	"contentTypography": `task-card__contentTypography___lftPv`
};
export default ___CSS_LOADER_EXPORT___;
