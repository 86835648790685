import type { FC, ReactNode } from 'react';

import NiceModal from '@ebay/nice-modal-react';

import { ArticleModal } from '@/features/article';
import { CategoryModal } from '@/features/category';
import { GalleryModal } from '@/features/gallery';
import { CallToActionModal } from '@/features/call-to-action';
import { TagModal } from '@/features/tag';
import { StopWordModal, StopWordsUploadModal } from '@/features/stop-word';
import { UserModal } from '@/features/user';
import { ConfirmModal, confirmModal } from '@/shared/ui/modal';

type Props = {
  children: ReactNode;
};

NiceModal.register(confirmModal.id, ConfirmModal);
export const Modals: FC<Props> = ({ children }) => (
  <NiceModal.Provider>
    {children}
    <CategoryModal />
    <ArticleModal />
    <GalleryModal />
    <CallToActionModal />
    <TagModal />
    <StopWordModal />
    <StopWordsUploadModal />
    <UserModal />
  </NiceModal.Provider>
);
