import type { FieldsValues } from './schema';
import type { UploadFormData } from '@/entities/stop-word/types';

import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { stopWordModel } from '@/entities/stop-word';
import { useShowNotification } from '@/shared/notification';
import { useAppDispatch } from '@/shared/lib/rtk';

import { MODAL_ID, UPLOAD_MODAL_ID } from '../config';

export const useCreate = () => {
  const create = stopWordModel.useCreate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues) =>
      create(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Stop word "${data.name}" was created`);
        })
        .catch(console.error),
    [create, showSuccessNotification],
  );
};

export const useUpload = () => {
  const upload = stopWordModel.useUpload();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: UploadFormData) =>
      upload(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(UPLOAD_MODAL_ID);
          showSuccessNotification(`File was uploaded`);
        })
        .catch(console.error),
    [upload, showSuccessNotification],
  );
};

export const useUpdate = () => {
  const dispatch = useAppDispatch();
  const update = stopWordModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues & EntityWithId) =>
      update(data)
        .unwrap()
        .then(() => {
          dispatch(stopWordModel.actions.edit(null));
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Stop word was updated`);
        })
        .catch(console.error),
    [update, showSuccessNotification, dispatch],
  );
};

export const useDelete = () => {
  const deleteStopWord = stopWordModel.useDelete();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId) => {
      deleteStopWord(id)
        .unwrap()
        .then(() => {
          showSuccessNotification(`Stop word was deleted`);
        })
        .catch(console.error);
    },
    [deleteStopWord, showSuccessNotification],
  );
};
