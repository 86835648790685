import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { FieldsValues } from '../model';

import { roleModel } from '@/entities/role';
import { userModel } from '@/entities/user';
import {
  RHFForm,
  RHFInput,
  RHFPasswordInput,
  RHFSelect,
  RHFSwitch,
} from '@/shared/lib/react-hook-form';
import { useAppSelector } from '@/shared/lib/rtk';
import { Button, Drawer } from '@/shared/ui';

import { useCreate, useUpdate, updateSchema, createSchema } from '../model';
import { MODAL_ID } from '../config';

import styles from './user-form.css';

export const UserForm: FC = () => {
  const create = useCreate();
  const update = useUpdate();
  const { roles } = roleModel.useGetAll();
  const user = useAppSelector(userModel.selectEditing);

  const mappedRoles = roles.map(({ id, name }) => ({
    value: id,
    name,
  }));

  const handleSubmit: SubmitHandler<FieldsValues> = async (data) => {
    user ? await update({ id: user.id, ...data }) : await create(data);
  };

  return (
    <RHFForm
      onSubmit={handleSubmit}
      schema={user ? updateSchema : createSchema}
      defaultValues={user ?? {}}
    >
      {({ loading }) => (
        <>
          <RHFInput name="username" type="text" label="Name" />
          <RHFSelect name="roleId" label="Role" options={mappedRoles ?? []} />
          <RHFInput name="email" type="text" label="Email" />
          <RHFPasswordInput name="password" label="Password" />
          <RHFPasswordInput name="confirmPassword" label="Repeat password" />
          <RHFInput name="position" type="text" label="Position" />
          <RHFSwitch name="webEnabled" label="Show on authors page" className={styles.switch} />
          <Button loading={loading} type="submit" label="Save" color="primary" />
        </>
      )}
    </RHFForm>
  );
};

export const UserModal = () => (
  <Drawer id={MODAL_ID}>
    <UserForm />
  </Drawer>
);
