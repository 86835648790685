import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type { ArticleType, ArticleConstants } from '@/entities/article/types';

import { useMemo } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { articleModel } from '@/entities/article';
import { DataGrid } from '@/shared/ui';

import { DeleteArticle } from './delete-article';
import { OpenEditor } from './open-editor';
import { EditArticle } from './edit-article';

const getColumns = (constants: ArticleConstants | undefined): NarrowGridColumns<ArticleType> => [
  {
    field: 'mainHeadline',
    headerName: 'Main headline',
    width: 400,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) =>
      constants?.status.find(({ value }) => value === row.status)?.name ?? row.status,
  },
  {
    field: 'users',
    headerName: 'Authors',
    width: 250,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.users?.map(({ username }) => username).join(', '),
  },
  {
    field: 'uniqueness',
    headerName: 'Uniqueness',
    width: 150,
    sortable: true,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.uniqueness ?? '-',
  },
  {
    field: 'formatType',
    headerName: 'Format',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) =>
      constants?.formatType.find(({ value }) => value === row.formatType)?.name ?? row.formatType,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.category?.catchyTitle ?? row.categoryId,
  },
  {
    field: 'publishedAt',
    headerName: 'Date',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) =>
      row.publishedAt
        ? new Intl.DateTimeFormat('en-GB', {
            dateStyle: 'short',
            timeStyle: 'medium',
            hour12: false,
          }).format(new Date(row.publishedAt))
        : '–',
  },
];

type Props = ItemsListProps<ArticleType>;

export const ArticleList = ({ items, total, isFetching }: Props) => {
  const { constants } = articleModel.useGetConstants();
  const columns = useMemo(() => getColumns(constants), [constants]);

  return (
    <DataGrid
      rowsTotalCount={total}
      columns={columns}
      loading={isFetching}
      rows={items ?? []}
      actions={(article) => (
        <>
          <DeleteArticle entity={article}>
            {({ handleDelete }) => (
              <IconButton onClick={() => handleDelete()}>
                <Tooltip title={'Delete'}>
                  <DeleteIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            )}
          </DeleteArticle>
          <OpenEditor entity={article} />
          <EditArticle entity={article} />
        </>
      )}
    />
  );
};
