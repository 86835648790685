import type { FC } from 'react';

import { Drawer, List } from '@mui/material';

import classnames from 'classnames';

import { useAppSelector } from '@/shared/lib/rtk';
import { sessionModel } from '@/entities/session';

import { SidebarItem } from './sidebar-item';
import { sidebarItems } from './config';

import styles from './sidebar.css';

type Props = {
  isSidebarOpen: boolean;
};

export const Sidebar: FC<Props> = ({ isSidebarOpen = false }) => {
  const user = useAppSelector(sessionModel.selectUser);

  if (!user) {
    return null;
  }

  const allowedRoutes = sidebarItems.filter((route) => user.permissions.includes(route.permission));

  return (
    <Drawer
      variant="permanent"
      className={classnames(styles.container, { [styles.open]: isSidebarOpen })}
      open={isSidebarOpen}
    >
      <List disablePadding>
        {allowedRoutes.map(({ route, icon, label }) => (
          <SidebarItem key={route} icon={icon} label={label} route={route} />
        ))}
      </List>
    </Drawer>
  );
};
