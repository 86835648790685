/* eslint-disable  @typescript-eslint/no-explicit-any */

import type { AsyncThunkAction, Dispatch } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';

import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import type { AppDispatch } from '@/app/store';

type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export type RootState = Record<string, unknown>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const createBaseSelector = <T>(rootKey: string) => {
  return (state: RootState) => {
    if (rootKey in state) {
      return state[rootKey] as T;
    } else {
      throw new Error(`Reducer ${rootKey} is not registered`);
    }
  };
};

export const useAction = <Returned, ThunkArg>(
  actionCreator: (arg: ThunkArg) => AsyncThunkAction<Returned, ThunkArg, AsyncThunkConfig>,
) => {
  const dispatch = useAppDispatch();
  return useCallback((arg: ThunkArg) => dispatch(actionCreator(arg)), [actionCreator, dispatch]);
};
