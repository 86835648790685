import type { EditorState } from 'lexical';

import { z } from 'zod';

import { lengthRefine, title } from '@/shared/validation';

export const schema = z.object({
  name: lengthRefine(title('Name'), 100),
  position: z.number(),
  categoryId: z.number().nullable().optional(),
  isEnabled: z.boolean().optional(),
  body: z.custom<EditorState[]>(),
});

export type FieldsValues = z.infer<typeof schema>;
