import type { LexicalCommand } from 'lexical';
import { z } from 'zod';

import { useEffect } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  LexicalNode,
  PASTE_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister, $insertNodeToNearestRoot } from '@lexical/utils';

import {
  $createDailyMotionNode,
  $createFacebookNode,
  $createInstagramNode,
  $createTweetNode,
  $createYouTubeNode,
  DailyMotionNode,
  FacebookNode,
  InstagramNode,
  TweetNode,
  YoutubeNode,
} from '../nodes';
import {
  INSERT_FACEBOOK_COMMAND,
  INSERT_TWITTER_COMMAND,
  INSERT_YOUTUBE_COMMAND,
  INSERT_INSTAGRAM_COMMAND,
  INSERT_TIKTOK_COMMAND,
  INSERT_DAILY_MOTION_COMMAND,
} from '../commands';
import { EmbedModal } from '../ui/embed-modal';
import { $createTiktokNode, TiktokNode } from '../nodes/tiktok-node';
import { insertEmbedPayload } from '../lib';

const useEmbedNodeBuilder = <Payload extends object>(
  node: Class<LexicalNode>,
  command: LexicalCommand<Payload>,
  nodeCreator: (payload: Payload) => LexicalNode,
): void => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([node])) {
      throw new Error(`Embed: ${node.name} not registered on editor`);
    }

    return mergeRegister(
      editor.registerCommand<Payload>(
        command,
        (payload) => {
          const selection = $getSelection();

          if (!$isRangeSelection(selection)) {
            return true;
          }

          const node = nodeCreator(payload);

          $insertNodeToNearestRoot(node);

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),

      editor.registerCommand(
        PASTE_COMMAND,
        (event) => {
          const selection = $getSelection();

          if (
            !$isRangeSelection(selection) ||
            !selection.isCollapsed() ||
            !(event instanceof ClipboardEvent) ||
            event.clipboardData == null
          ) {
            return false;
          }
          const clipboardText = event.clipboardData.getData('text');

          if (z.string().trim().url().safeParse(clipboardText).success) {
            insertEmbedPayload(clipboardText, editor);
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [editor, command, node, nodeCreator]);
};

export const EmbedPlugin = () => {
  useEmbedNodeBuilder(TweetNode, INSERT_TWITTER_COMMAND, $createTweetNode);
  useEmbedNodeBuilder(YoutubeNode, INSERT_YOUTUBE_COMMAND, $createYouTubeNode);
  useEmbedNodeBuilder(FacebookNode, INSERT_FACEBOOK_COMMAND, $createFacebookNode);
  useEmbedNodeBuilder(InstagramNode, INSERT_INSTAGRAM_COMMAND, $createInstagramNode);
  useEmbedNodeBuilder(TiktokNode, INSERT_TIKTOK_COMMAND, $createTiktokNode);
  useEmbedNodeBuilder(DailyMotionNode, INSERT_DAILY_MOTION_COMMAND, $createDailyMotionNode);

  return <EmbedModal />;
};
