import type { uploadFormFieldsValues } from '@/features/stop-word/model';
import type { StopWordType, UploadFormData } from './types';

export const transformResponse = (
  response: ResponseWithPagination<StopWordType>,
): ResponseWithPagination<StopWordType> => {
  return response;
};

export const transformUploadFormData = (data: uploadFormFieldsValues): UploadFormData => ({
  file: data.file,
  upload_type: data.uploadType,
});
