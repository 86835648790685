import { z } from 'zod';

import { readFileAsImage } from '../lib/file';
import { image_type } from './validation-regexp';

const MAX_IMAGE_SIZE = 10;
const MIN_IMAGE_WIDTH = 600;

const sizeInMB = (file: File) => Number((file.size / (1024 * 1024)).toFixed(2));

export const image = z
  .instanceof(FileList, { message: 'Image is required' })
  .refine(
    (value) => sizeInMB(value[0]) < MAX_IMAGE_SIZE,
    (val) => ({
      message: `Max image size ${MAX_IMAGE_SIZE} Mb. Now its: ${sizeInMB(val[0])} Mb.`,
    }),
  )
  .refine((value) => image_type.test(value[0].type), {
    message: `You can upload only jpeg, jpg, png, gif image files`,
  })
  .refine(
    async (value) => {
      const imageFile = await readFileAsImage(value[0]);
      return imageFile.width > MIN_IMAGE_WIDTH;
    },
    {
      message: `Image must be wider than ${MIN_IMAGE_WIDTH}px.`,
    },
  );
