import { z } from 'zod';
import NiceModal from '@ebay/nice-modal-react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_TABLE_COMMAND } from '@lexical/table';

import { RHFForm, RHFInput } from '../../lib/react-hook-form';
import { Button, Modal } from '../../ui';

import styles from './index.css';

export const TableFormSchema = z.object({
  rows: z.coerce.string({
    required_error: 'Rows is required',
  }),
  cols: z.coerce.string({
    required_error: 'Cols is required',
  }),
});

export const InsertTableModal = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <Modal id="table-modal">
      <RHFForm
        onSubmit={async ({ rows, cols }) => {
          editor.dispatchCommand(INSERT_TABLE_COMMAND, {
            columns: cols,
            rows: rows,
            includeHeaders: false,
          });
          NiceModal.hide('table-modal');
        }}
        schema={TableFormSchema}
        defaultValues={{ rows: '5', cols: '5' }}
      >
        {() => (
          <div className={styles.tableModal}>
            <RHFInput
              name="rows"
              type="number"
              label="Enter number of table rows"
              style={{ margin: 0, width: '300px' }}
            />
            <RHFInput
              name="cols"
              type="number"
              label="Enter number of table cols"
              style={{ margin: 0, width: '300px' }}
            />
            <Button type="submit" label="Save" color="primary" />
          </div>
        )}
      </RHFForm>
    </Modal>
  );
};
