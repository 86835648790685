import type { FC } from 'react';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import styles from './sidebar.css';

export type SidebarItemProps = {
  icon: JSX.Element;
  label: string;
  route: string;
};

export const SidebarItem: FC<SidebarItemProps> = ({ icon, label, route }) => {
  return (
    <ListItem disablePadding>
      <NavLink to={route} className={styles.link}>
        {({ isActive }) => (
          <ListItemButton
            sx={(theme) => ({
              background: isActive ? theme.palette.primary.main : '',
              '&:hover': {
                background: isActive ? theme.palette.primary.main : '',
              },
            })}
          >
            <ListItemIcon
              sx={(theme) => ({
                color: isActive ? theme.palette.common.white : '',
              })}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              sx={(theme) => ({
                color: isActive ? theme.palette.common.white : '',
              })}
              primary={label}
            />
          </ListItemButton>
        )}
      </NavLink>
    </ListItem>
  );
};
