import type { State } from './types';

import { createSelector } from '@reduxjs/toolkit';

import { createBaseSelector } from '@/shared/lib/rtk';

import { REDUCER_PATH } from './constants';

const baseSelector = createBaseSelector<State>(REDUCER_PATH);

export const selectEditing = createSelector(baseSelector, (image) => image.editing);
