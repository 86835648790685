export const routes = {
  // DASHBOARD: '/dashboard',
  TASKS: '/tasks',
  LOGIN: '/login',
  ARTICLES: '/articles',
  ARTICLE: '/articles/:id',
  ROLES: '/roles',
  CALL_TO_CATIONS: '/cta',
  CONTEXTUAL_LINKS: '/contextual-links',
  USERS: '/users',
  AUTHOR: '/users/:id',
  CATEGORIES: '/categories',
  TAGS: '/tags',
  STOP_WORDS: '/stop-words',
  IMAGES: '/images',
  NOT_MATCH: '*',
};
