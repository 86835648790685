import type { CallToActionIncludes } from '@/entities/call-to-action/api/types';

import { useGetQueryParams } from '@/shared/lib/query-params-filter';

import {
  useGetCallToActionsQuery,
  useGetCallToActionQuery,
  useGetCallToActionConstantsQuery,
  useAddCallToActionMutation,
  useDeleteCallToActionMutation,
  useUpdateCallToActionMutation,
} from '../api';

const acceptedFilters = ['category_id', 'position'];

export const useGetAll = (includes: CallToActionIncludes[]) => {
  const params = useGetQueryParams(acceptedFilters);

  const { callToActions, isFetching } = useGetCallToActionsQuery(
    { params, includes },
    {
      selectFromResult: ({ data = [], isFetching }) => ({
        callToActions: data,
        isFetching,
      }),
    },
  );

  return { callToActions, isFetching };
};

export const useGetById = (id: UniqueId) => {
  const { data, isFetching } = useGetCallToActionQuery(id);

  return { callToAction: data, isFetching };
};

export const useGetConstants = () => {
  const { data, isFetching } = useGetCallToActionConstantsQuery(undefined, {
    selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
  });

  return { constants: data, isFetching };
};

export const useCreate = () => {
  const [create] = useAddCallToActionMutation();

  return create;
};

export const useDelete = () => {
  const [deleteCallToAction] = useDeleteCallToActionMutation();

  return deleteCallToAction;
};

export const useUpdate = () => {
  const [update] = useUpdateCallToActionMutation();

  return update;
};
