import type { User } from '@/entities/session/types';

import { api } from '@/shared/api';
import { removeToken, setToken } from '@/shared/api/access-token';

import { actions } from '../model';
import { CACHE_KEYS } from '@/shared/api/base';

export type LoginBody = { email: string; password: string };

export type LoginResponse = { token: string };

const sessionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginBody>({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data }) => {
            setToken(data.token);
            dispatch(actions.login());
            dispatch(api.util.invalidateTags(Object.values(CACHE_KEYS)));
          })
          .catch(console.error);
      },
    }),

    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled.catch(console.error).finally(() => {
          removeToken();
          dispatch(actions.logout());
          dispatch(actions.removeUser());
        });
      },
    }),

    getProfile: builder.query<User, unknown>({
      query: () => ({
        url: '/auth/profile',
        method: 'GET',
      }),
      onQueryStarted: (_, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data }) => {
            dispatch(actions.setUser(data));
          })
          .catch(console.error);
      },
    }),
  }),
});

export const { useLazyGetProfileQuery, useLogoutMutation, useLoginMutation, useGetProfileQuery } =
  sessionApi;
