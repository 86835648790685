import type { LexicalCommand } from 'lexical';
import type {
  InsertImagePayload,
  InsertTweetPayload,
  InsertYouTubePayload,
  InsertFacebookPayload,
  InsertDailyMotionPayload,
} from './nodes';

import { createCommand } from 'lexical';

export const INSERT_TWITTER_COMMAND: LexicalCommand<InsertTweetPayload> =
  createCommand('INSERT_TWITTER_COMMAND');

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand('INSERT_IMAGE_COMMAND');

export const INSERT_YOUTUBE_COMMAND: LexicalCommand<InsertYouTubePayload> =
  createCommand('INSERT_YOUTUBE_COMMAND');

export const INSERT_FACEBOOK_COMMAND: LexicalCommand<InsertFacebookPayload> =
  createCommand('INSERT_FACEBOOK_COMMAND');

export const INSERT_INSTAGRAM_COMMAND: LexicalCommand<InsertImagePayload> = createCommand(
  'INSERT_INSTAGRAM_COMMAND',
);

export const INSERT_TIKTOK_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand('INSERT_TIKTOK_COMMAND');

export const INSERT_DAILY_MOTION_COMMAND: LexicalCommand<InsertDailyMotionPayload> = createCommand(
  'INSERT_DAILY_MOTION_COMMAND',
);

export const TRY_INSERT_LINK_COMMAND: LexicalCommand<void> =
  createCommand('TRY_INSERT_LINK_COMMAND');
