import type { FC } from 'react';
import type { UserType } from '@/entities/user/api/types';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { HasAccess } from '@/entities/session';
import { routes } from '@/shared/routes';
import { USER_UPDATE } from '@/shared/config/permissions';

type Props = {
  entity: UserType;
};

export const OpenAuthorPage: FC<Props> = React.memo(({ entity }) => {
  const navigate = useNavigate();

  return (
    <HasAccess permissions={[USER_UPDATE]}>
      <IconButton onClick={() => navigate(`${routes.USERS}/${entity?.id}`)}>
        <Tooltip title={'Author page'}>
          <EditIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
});

OpenAuthorPage.displayName = 'OpenAuthorPage';
