import type { FieldsValues } from './schema';

import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { tagModel } from '@/entities/tag';
import { useShowNotification } from '@/shared/notification';
import { useAppDispatch } from '@/shared/lib/rtk';

import { MODAL_ID } from '../config';

export const useCreate = () => {
  const create = tagModel.useCreate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues) =>
      create(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Tag was created`);
        })
        .catch(console.error),
    [create, showSuccessNotification],
  );
};

export const useUpdate = () => {
  const dispatch = useAppDispatch();
  const update = tagModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues & EntityWithId) =>
      update(data)
        .unwrap()
        .then(() => {
          dispatch(tagModel.actions.edit(null));
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Tag was updated`);
        })
        .catch(console.error),
    [update, showSuccessNotification, dispatch],
  );
};

export const useDelete = () => {
  const deleteTag = tagModel.useDelete();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId) => {
      deleteTag(id)
        .unwrap()
        .then(() => {
          showSuccessNotification(`Tag was deleted`);
        })
        .catch(console.error);
    },
    [deleteTag, showSuccessNotification],
  );
};
