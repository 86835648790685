import { z } from 'zod';

import { lengthRefine, title } from '@/shared/validation';

export const schema = z.object({
  mainHeadline: lengthRefine(title('Headline'), 100, 50),
  priority: z.number().optional(),
  templateType: z.number({ required_error: 'Template type is required' }),
  formatType: z.number({ required_error: 'Format type is required' }),
  categoryId: z.number({ required_error: 'Category is required' }),
  status: z.number().optional(),
  assigneeId: z.number().nullable().optional(),
});

export type FieldsValues = z.infer<typeof schema>;
