import type { ChangeEventHandler, FC, CSSProperties, MouseEvent } from 'react';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import type { Noop, RefCallBack } from 'react-hook-form/dist/types';
import type { InputBaseProps } from '@mui/material';

import { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export type InputSize = 'medium' | 'small';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  size?: InputSize;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: FieldError;
  multiline?: boolean;
  inputRef?: RefCallBack;
  onBlur?: Noop;
  className?: string;
  style?: CSSProperties;
  inputProps?: InputBaseProps['inputProps'];
};

export const PasswordInput: FC<Props> = ({
  name,
  label,
  placeholder,
  value,
  disabled,
  multiline,
  size,
  onChange,
  error,
  inputRef,
  onBlur,
  className,
  style,
  inputProps,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      name={name}
      disabled={disabled}
      onChange={onChange}
      label={label}
      value={value}
      variant="outlined"
      margin="normal"
      multiline={multiline}
      size={size}
      placeholder={placeholder}
      fullWidth
      error={!!error}
      helperText={error?.message}
      inputRef={inputRef}
      onBlur={onBlur}
      style={style}
      className={className}
      inputProps={inputProps}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
