import type { FC } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
  src: string;
};

export const DownloadImageButton: FC<Props> = ({ src }) => {
  return (
    <Tooltip title="Download">
      <IconButton href={src}>
        <DownloadIcon sx={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};
