import { configureStore } from '@reduxjs/toolkit';

import { tagModel } from '@/entities/tag';
import { userModel } from '@/entities/user';
import { articleModel } from '@/entities/article';
import { galleryModel } from '@/entities/gallery';
import { sessionModel } from '@/entities/session';
import { categoryModel } from '@/entities/category';
import { stopWordModel } from '@/entities/stop-word';
import { callToActionModel } from '@/entities/call-to-action';
import { roleModel } from '@/entities/role';

import { api } from '@/shared/api/base';
import { notificationModel } from '@/shared/notification';
import { authErrorMiddleware } from '@/shared/api/middleware';

export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    ...sessionModel.reducer,
    ...articleModel.reducer,
    ...categoryModel.reducer,
    ...galleryModel.reducer,
    ...notificationModel.reducer,
    ...callToActionModel.reducer,
    ...stopWordModel.reducer,
    ...tagModel.reducer,
    ...userModel.reducer,
    ...roleModel.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, authErrorMiddleware),
});
