import type { NodeKey, SerializedLexicalNode, Spread } from 'lexical';

import { $applyNodeReplacement, DecoratorNode, LexicalNode } from 'lexical';

import { DecoratorBlock } from '../ui/decorator-block';
import { FacebookEmbedComponent } from '../ui/facebook';

export type FacebookPayload = {
  id: string;
  link: string;
  facebookType: string;
  key?: NodeKey;
};

export type InsertFacebookPayload = Readonly<FacebookPayload>;

export type SerializedFacebookNode = Spread<
  {
    id: string;
    link: string;
    facebookType: string;
    type: typeof NODE_TYPE;
    version: 1;
  },
  SerializedLexicalNode
>;

const NODE_TYPE = 'facebook';

export class FacebookNode extends DecoratorNode<JSX.Element> {
  __id: string;
  __link: string;
  __facebookType: string;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: FacebookNode): FacebookNode {
    return new FacebookNode(node.__id, node.__link, node.__facebookType, node.__key);
  }

  exportJSON(): SerializedFacebookNode {
    return {
      id: this.getId(),
      link: this.getLink(),
      facebookType: this.getFacebookType(),
      type: NODE_TYPE,
      version: 1,
    };
  }

  static importJSON(serializedNode: SerializedFacebookNode): FacebookNode {
    const { id, link, facebookType } = serializedNode;

    return $createFacebookNode({ id, link, facebookType });
  }

  constructor(id: string, link: string, facebookType: string, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__link = link;
    this.__facebookType = facebookType;
  }

  getId(): string {
    const self = this.getLatest();
    return self.__id;
  }

  getLink(): string {
    const self = this.getLatest();
    return self.__link;
  }

  getFacebookType(): string {
    const self = this.getLatest();
    return self.__facebookType;
  }

  isInline(): false {
    return false;
  }

  updateDOM(): false {
    return false;
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  decorate(): JSX.Element {
    return (
      <DecoratorBlock nodeKey={this.getKey()}>
        <FacebookEmbedComponent link={this.__link} />
      </DecoratorBlock>
    );
  }
}

export function $createFacebookNode({ id, link, facebookType }: FacebookPayload): FacebookNode {
  return $applyNodeReplacement(new FacebookNode(id, link, facebookType));
}

export function $isFacebookNode(node: LexicalNode | null | undefined): node is FacebookNode {
  return node instanceof FacebookNode;
}
