import type { ImageResponseType, ImageConstantsKeys, ImageType } from './types';

import { api } from '@/shared/api';

import { transformResponse } from './transform-response';
import { CropImagePayload } from './types';

const RESOURCE_PATH = 'images';

const imagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImages: builder.query<ResponseWithPagination<ImageType>, QueryParams | void>({
      query: (args) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'GET',
        params: args?.params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Images', id } as const)),
              { type: 'Images', id: 'LIST' },
            ]
          : [{ type: 'Images', id: 'LIST' }],
      transformResponse: transformResponse,
    }),

    getImage: builder.query<ImageResponseType, UniqueId>({
      query: (id) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Images', id }],
    }),

    searchImages: builder.query<
      ResponseWithPagination<ImageType>,
      { search_field: string; search: string; params: QueryParams }
    >({
      query: ({ search, search_field, params }) => ({
        url: `/${RESOURCE_PATH}/search?search=${search}&search_field=${search_field}`,
        method: 'GET',
        params: params,
      }),
      transformResponse: transformResponse,
    }),

    getImagesConstants: builder.query<ConstantsType<ImageConstantsKeys>, void>({
      query: () => ({
        url: `/${RESOURCE_PATH}/constants`,
        method: 'GET',
      }),
    }),

    addImage: builder.mutation({
      query: (data) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
    }),

    cropImage: builder.mutation<void, CropImagePayload>({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}/crop`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
    }),

    updateImage: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
    }),

    deleteImage: builder.mutation({
      query: (id) => {
        return {
          url: `/${RESOURCE_PATH}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Images', id }],
    }),
  }),
});

export const {
  useGetImagesQuery,
  useGetImageQuery,
  useGetImagesConstantsQuery,
  useLazySearchImagesQuery,
  useAddImageMutation,
  useCropImageMutation,
  useDeleteImageMutation,
  useUpdateImageMutation,
} = imagesApi;
