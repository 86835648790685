type Props = {
  id: string;
};

export const DailyMotionComponent = ({ id }: Props) => (
  <iframe
    width="100%"
    height="380px"
    frameBorder="0"
    scrolling="no"
    src={`https://www.dailymotion.com/embed/video/${id}`}
  />
);
