import NiceModal from '@ebay/nice-modal-react';

import { HasAccess } from '@/entities/session';
import { userModel } from '@/entities/user';
import { USER_UPDATE } from '@/shared/config/permissions';
import { useAppDispatch } from '@/shared/lib/rtk';
import { Button } from '@/shared/ui';

import { MODAL_ID } from '../config';

export const CreateUserButton = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(userModel.actions.edit(null));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[USER_UPDATE]}>
      <Button label="Create" onClick={handleClick} />
    </HasAccess>
  );
};
