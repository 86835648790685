import { z } from 'zod';

import { image, lengthRefine, title } from '@/shared/validation';

export const createSchema = z.object({
  file: image,
  name: lengthRefine(title('Name'), 100),
  source: z.number({ required_error: 'Source is required' }),
});

export const updateSchema = createSchema.omit({ file: true });

export type FieldsValues = z.infer<typeof createSchema> | z.infer<typeof updateSchema>;

export const insertImageSchema = z.object({
  alt: z.string({ required_error: 'Image alt is required' }).min(6).max(255),
  label: z.string().min(5).max(200),
});
