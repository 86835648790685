// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-list__root___VTpgh {
  --columns: 3;

  display: grid;
  gap: 10px;

  grid-template-columns: repeat(var(--columns, 3), 1fr);
}

.image-list__item___PtYo4 {
  position: relative;

  overflow: hidden;
}

.image-list__image___Ku926 {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.image-list__caption___PgAmG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: 10px;

  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  background-color: rgba(0, 0, 0, 0.5);
}

.image-list__buttons___hXbaZ {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/image-list/image-list.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ,aAAa;EACb,SAAS;;EAET,qDAAqD;AACvD;;AAEA;EACE,kBAAkB;;EAElB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;;EAEN,WAAW;EACX,YAAY;;EAEZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;;EAER,aAAa;;EAEb,WAAW;EACX,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;;EAEnB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,SAAS;AACX","sourcesContent":[".root {\n  --columns: 3;\n\n  display: grid;\n  gap: 10px;\n\n  grid-template-columns: repeat(var(--columns, 3), 1fr);\n}\n\n.item {\n  position: relative;\n\n  overflow: hidden;\n}\n\n.image {\n  position: absolute;\n  left: 0;\n  top: 0;\n\n  width: 100%;\n  height: 100%;\n\n  object-fit: cover;\n}\n\n.caption {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n\n  padding: 10px;\n\n  color: #fff;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n.buttons {\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `image-list__root___VTpgh`,
	"item": `image-list__item___PtYo4`,
	"image": `image-list__image___Ku926`,
	"caption": `image-list__caption___PgAmG`,
	"buttons": `image-list__buttons___hXbaZ`
};
export default ___CSS_LOADER_EXPORT___;
