import type { FC, CSSProperties } from 'react';
import type { InputSize } from '@/shared/ui';

import { useController } from 'react-hook-form';

import { Input } from '@/shared/ui';

type InputProps = {
  name: string;
  type?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  multiline?: boolean;
  size?: InputSize;
  style?: CSSProperties;
  className?: string;
};

export const RHFInput: FC<InputProps> = ({
  name,
  type = 'text',
  label,
  disabled = false,
  placeholder,
  size,
  multiline,
  style,
  className,
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name });

  return (
    <Input
      label={label}
      type={type}
      value={value || ''}
      disabled={disabled}
      placeholder={placeholder}
      size={size}
      name={name}
      onChange={onChange}
      error={error}
      inputRef={ref}
      multiline={multiline}
      onBlur={onBlur}
      style={style}
      className={className}
    />
  );
};
