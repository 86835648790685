// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__form___CJGCD > * + * {
  margin-top: 1rem;
}

.form__switch___o3LXS {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/features/stop-word/ui/form.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".form > * + * {\n  margin-top: 1rem;\n}\n\n.switch {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `form__form___CJGCD`,
	"switch": `form__switch___o3LXS`
};
export default ___CSS_LOADER_EXPORT___;
