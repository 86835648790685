import type { ChangeEventHandler, FC, CSSProperties } from 'react';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import type { Noop, RefCallBack } from 'react-hook-form/dist/types';
import type { InputProps, InputBaseProps } from '@mui/material';

import TextField from '@mui/material/TextField';

export type InputSize = 'medium' | 'small';

type Props = {
  name?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  size?: InputSize;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: FieldError;
  multiline?: boolean;
  inputRef?: RefCallBack;
  onBlur?: Noop;
  className?: string;
  style?: CSSProperties;
  InputProps?: InputProps;
  inputProps?: InputBaseProps['inputProps'];
};

export const Input: FC<Props> = ({
  type = 'text',
  name,
  label,
  placeholder,
  value,
  disabled,
  multiline,
  size,
  onChange,
  error,
  inputRef,
  onBlur,
  className,
  style,
  InputProps,
  inputProps,
}) => (
  <TextField
    name={name}
    disabled={disabled}
    onChange={onChange}
    label={label}
    value={value}
    variant="outlined"
    margin="normal"
    multiline={multiline}
    size={size}
    type={type}
    placeholder={placeholder}
    fullWidth
    error={!!error}
    helperText={error?.message}
    inputRef={inputRef}
    onBlur={onBlur}
    style={style}
    className={className}
    InputProps={InputProps}
    inputProps={inputProps}
  />
);
