// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar__container___eR2nS {
  width: 64px;

  overflow: hidden;
  transition: width 0.3s;
  will-change: width;
}

.sidebar__container___eR2nS .MuiPaper-root {
  top: 0;

  width: 64px;
  padding-top: 64px;
  overflow-x: hidden;

  transition: width 0.3s;
  will-change: width;
}

.sidebar__open___dS2Zz {
  width: 250px;
}

.sidebar__open___dS2Zz .MuiPaper-root {
  width: 250px;
}

.sidebar__container___eR2nS .MuiListItemButton-root {
  padding: 8px 20px;
}

.sidebar__link___unNaF {
  display: block;
  width: 100%;
  white-space: nowrap;

  color: #000;
}



`, "",{"version":3,"sources":["webpack://./src/widgets/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;;EAEX,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,MAAM;;EAEN,WAAW;EACX,iBAAiB;EACjB,kBAAkB;;EAElB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,mBAAmB;;EAEnB,WAAW;AACb","sourcesContent":[".container {\n  width: 64px;\n\n  overflow: hidden;\n  transition: width 0.3s;\n  will-change: width;\n}\n\n.container :global .MuiPaper-root {\n  top: 0;\n\n  width: 64px;\n  padding-top: 64px;\n  overflow-x: hidden;\n\n  transition: width 0.3s;\n  will-change: width;\n}\n\n.open {\n  width: 250px;\n}\n\n.open :global .MuiPaper-root {\n  width: 250px;\n}\n\n.container :global .MuiListItemButton-root {\n  padding: 8px 20px;\n}\n\n.link {\n  display: block;\n  width: 100%;\n  white-space: nowrap;\n\n  color: #000;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sidebar__container___eR2nS`,
	"open": `sidebar__open___dS2Zz`,
	"link": `sidebar__link___unNaF`
};
export default ___CSS_LOADER_EXPORT___;
