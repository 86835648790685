import type { TagType } from './types';

import { api } from '@/shared/api';

import { transformResponse } from './transform-response';

const RESOURCE_PATH = 'tags';

const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<ResponseWithPagination<TagType>, PaginationQueryParams>({
      query: ({ page, per_page, params }) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'GET',
        params: { page, per_page, ...params },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Tags', id } as const)),
              { type: 'Tags', id: 'LIST' },
            ]
          : [{ type: 'Tags', id: 'LIST' }],
      transformResponse: transformResponse,
    }),

    getTag: builder.query<TagType, UniqueId>({
      query: (id) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Tags', id }],
    }),

    searchTags: builder.query<
      ResponseWithPagination<TagType>,
      { search_field: string; search: string }
    >({
      query: ({ search, search_field }) => ({
        url: `/${RESOURCE_PATH}/search?search=${search}&search_field=${search_field}`,
        method: 'GET',
      }),
    }),

    addTag: builder.mutation({
      query: (data) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
    }),

    updateTag: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
    }),

    deleteTag: builder.mutation({
      query: (id) => {
        return {
          url: `/${RESOURCE_PATH}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Tags', id }],
    }),
  }),
});

export const {
  useGetTagsQuery,
  useGetTagQuery,
  useLazySearchTagsQuery,
  useAddTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
} = tagsApi;
