import type { Dispatch, SetStateAction } from 'react';
import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type { ArticleType, SearchConsoleKeyword } from '@/entities/article/api/types';

import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { subDays, subMonths, parse, format } from 'date-fns';

import { Button, DataGrid, Select } from '@/shared/ui';
import { articleModel } from '@/entities/article';

import styles from './search-console.css';

const columns: NarrowGridColumns<SearchConsoleKeyword> = [
  {
    field: 'key',
    headerName: 'Key',
    width: 400,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'position',
    headerName: 'Position',
    width: 120,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => Math.round((value + Number.EPSILON) * 100) / 100,
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    width: 120,
    disableColumnMenu: true,
  },
];

const dateFormat = 'yyyy-MM-dd';

// Search Console only stores the most recent data from two days ago.
const SEARCH_CONSOLE_DATE_SHIFT = 2;
const initialDateObject = subDays(new Date(), SEARCH_CONSOLE_DATE_SHIFT);

const dateRanges = [
  { value: format(subDays(initialDateObject, 6), dateFormat), name: 'last 7 days' },
  { value: format(subDays(initialDateObject, 27), dateFormat), name: 'last 28 days' },
  { value: format(subMonths(initialDateObject, 3), dateFormat), name: 'last 3 month days' },
];

const initialDate = format(initialDateObject, dateFormat);

export const SearchConsole = ({ article }: { article: ArticleType }) => {
  const [start, setStart] = useState(initialDate);
  const [end, setEnd] = useState(initialDate);
  const { getAnalytics, data, isLoading } = articleModel.useGetSearchConsoleAnalytics();

  const handleDateSelect = (setter: Dispatch<SetStateAction<string>>) => (value: Date | null) => {
    setter(format(value ?? new Date(), dateFormat));
  };

  const startDateObject = parse(start, dateFormat, new Date());
  const endDateObject = parse(end, dateFormat, new Date());

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={styles.controls}>
          <Button
            style={{ flexShrink: 0 }}
            label="Search for keywords"
            onClick={() => getAnalytics(article.id, start, end)}
          />
          <div style={{ flexShrink: 0 }}>
            <DatePicker
              views={['day']}
              format="dd/MM/yyyy"
              label="Start date"
              value={startDateObject}
              onChange={handleDateSelect(setStart)}
              maxDate={endDateObject}
            />
          </div>
          <div style={{ flexShrink: 0 }}>
            <DatePicker
              views={['day']}
              format="dd/MM/yyyy"
              label="End date"
              value={endDateObject}
              onChange={handleDateSelect(setEnd)}
              minDate={startDateObject}
              maxDate={initialDateObject}
            />
          </div>
          <Select
            className={styles.select}
            label="Date range"
            value={start}
            options={dateRanges}
            onChange={(event) => {
              setStart(event.target.value);
              setEnd(format(initialDateObject, dateFormat));
            }}
          />
        </div>

        <DataGrid
          autoHeight
          hidePagination
          columns={columns}
          rows={data ?? []}
          loading={isLoading}
          rowsTotalCount={data?.length ?? 0}
        />
      </LocalizationProvider>
    </div>
  );
};
