import type { NodeKey, SerializedLexicalNode, Spread } from 'lexical';

import { $applyNodeReplacement, DecoratorNode, LexicalNode } from 'lexical';

import { DecoratorBlock } from '../ui/decorator-block';
import { YouTubeComponent } from '../ui/youtube';

export type YouTubePayload = {
  id: string;
  key?: NodeKey;
};

export type InsertYouTubePayload = Readonly<YouTubePayload>;

const NODE_TYPE = 'youtube';

export type SerializedYouTubeNode = Spread<
  {
    id: string;
    type: typeof NODE_TYPE;
    version: 1;
  },
  SerializedLexicalNode
>;

export class YoutubeNode extends DecoratorNode<JSX.Element> {
  __id: string;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: YoutubeNode): YoutubeNode {
    return new YoutubeNode(node.__id, node.__key);
  }

  exportJSON(): SerializedYouTubeNode {
    return {
      id: this.getId(),
      type: NODE_TYPE,
      version: 1,
    };
  }

  static importJSON(serializedNode: SerializedYouTubeNode): YoutubeNode {
    const { id } = serializedNode;

    return $createYouTubeNode({
      id,
    });
  }

  constructor(id: string, key?: NodeKey) {
    super(key);
    this.__id = id;
  }

  getId(): string {
    const self = this.getLatest();
    return self.__id;
  }

  isInline(): false {
    return false;
  }

  updateDOM(): false {
    return false;
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  decorate(): JSX.Element {
    return (
      <DecoratorBlock nodeKey={this.getKey()}>
        <YouTubeComponent id={this.__id} />
      </DecoratorBlock>
    );
  }
}

export function $createYouTubeNode({ id }: YouTubePayload): YoutubeNode {
  return $applyNodeReplacement(new YoutubeNode(id));
}

export function $isYoutubeNode(node: LexicalNode | null | undefined): node is YoutubeNode {
  return node instanceof YoutubeNode;
}
