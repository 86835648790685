import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { routes } from '@/shared/routes';
import Layout from '@/pages/layout';
import { LoginPage } from '@/pages/login';
import { PersistAuth, PrivateRoute, UserProfile } from '@/entities/session';
import {
  ARTICLE_READ,
  CONTEXTUAL_LINK_READ,
  USER_READ,
  USER_UPDATE,
  CTA_READ,
  IMAGE_READ,
  ROLE_READ,
  STOP_WORD_READ,
  CATEGORY_READ,
  TAG_READ,
} from '@/shared/config/permissions';

import { Modals } from './providers/modals';

const Dashboard = lazy(() =>
  import('@/pages/dashboard').then((module) => ({ default: module.Dashboard })),
);
const Tasks = lazy(() => import('@/pages/tasks').then((module) => ({ default: module.Tasks })));
const Articles = lazy(() =>
  import('@/pages/articles').then((module) => ({ default: module.Articles })),
);
const Article = lazy(() =>
  import('@/pages/article').then((module) => ({ default: module.ArticlePage })),
);
const Categories = lazy(() =>
  import('@/pages/categories').then((module) => ({ default: module.Categories })),
);
const Tags = lazy(() => import('@/pages/tags').then((module) => ({ default: module.Tags })));
const Gallery = lazy(() =>
  import('@/pages/gallery').then((module) => ({ default: module.Gallery })),
);
const CallToAction = lazy(() =>
  import('@/pages/call-to-actions').then((module) => ({ default: module.CallToActions })),
);
const ContextualLinks = lazy(() =>
  import('@/pages/contextual-links').then((module) => ({ default: module.ContextualLinks })),
);
const StopWords = lazy(() =>
  import('@/pages/stop-words').then((module) => ({ default: module.StopWords })),
);
const Users = lazy(() => import('@/pages/users').then((module) => ({ default: module.Users })));
const AuthorPage = lazy(() =>
  import('@/pages/users').then((module) => ({ default: module.AuthorPage })),
);
const Roles = lazy(() => import('@/pages/roles').then((module) => ({ default: module.Roles })));
const ErrorPage = lazy(() =>
  import('@/pages/404').then((module) => ({ default: module.ErrorPage })),
);

export const routing = createBrowserRouter([
  {
    element: <PersistAuth />,
    children: [
      {
        path: routes.LOGIN,
        element: <LoginPage />,
      },
      {
        index: true,
        element: <LoginPage />,
      },
      {
        element: (
          <UserProfile>
            <Modals>
              <Layout />
            </Modals>
          </UserProfile>
        ),
        children: [
          // {
          //   path: routes.DASHBOARD,
          //   element: (
          //     <Suspense fallback={<CircularProgress />}>
          //       <PrivateRoute permission={ARTICLE_READ}>
          //         <Dashboard />
          //       </PrivateRoute>
          //     </Suspense>
          //   ),
          // },
          {
            path: routes.TASKS,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={ARTICLE_READ}>
                  <Tasks />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.ARTICLES,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={ARTICLE_READ}>
                  <Articles />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.ARTICLE,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={ARTICLE_READ}>
                  <Article />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.CATEGORIES,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={CATEGORY_READ}>
                  <Categories />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.TAGS,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={TAG_READ}>
                  <Tags />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.USERS,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={USER_READ}>
                  <Users />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.AUTHOR,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={USER_UPDATE}>
                  <AuthorPage />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.ROLES,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={ROLE_READ}>
                  <Roles />
                </PrivateRoute>
              </Suspense>
            ),
          },
          {
            path: routes.CALL_TO_CATIONS,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={CTA_READ}>
                  <CallToAction />
                </PrivateRoute>
              </Suspense>
            ),
          },

          {
            path: routes.IMAGES,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={IMAGE_READ}>
                  <Gallery />
                </PrivateRoute>
              </Suspense>
            ),
          },

          {
            path: routes.CONTEXTUAL_LINKS,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={CONTEXTUAL_LINK_READ}>
                  <ContextualLinks />
                </PrivateRoute>
              </Suspense>
            ),
          },

          {
            path: routes.STOP_WORDS,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <PrivateRoute permission={STOP_WORD_READ}>
                  <StopWords />
                </PrivateRoute>
              </Suspense>
            ),
          },

          {
            path: routes.NOT_MATCH,
            element: (
              <Suspense fallback={<CircularProgress />}>
                <ErrorPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
]);
