import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { FieldsValues } from '../model';

import { Link } from 'react-router-dom';
import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Button, Drawer } from '@/shared/ui';
import { routes } from '@/shared/routes';
import { RHFForm, RHFInput, RHFSelect } from '@/shared/lib/react-hook-form';
import { useAppSelector } from '@/shared/lib/rtk';
import { articleModel } from '@/entities/article';
import { categoryModel } from '@/entities/category';
import { UsersAsyncAutocomplete } from '@/entities/user';

import { useCreate, useUpdate, schema } from '../model';
import { MODAL_ID } from '../config';

import styles from './form.css';

const articleFormDefaultValues = {
  templateType: articleModel.TemplateTypeEnum.REGULAR,
  priority: articleModel.PriorityEnum.MEDIUM,
};

export const ArticleForm: FC = () => {
  const { constants } = articleModel.useGetConstants();
  const { categoriesOptions } = categoryModel.useGetAllOptions();
  const article = useAppSelector(articleModel.selectEditing);
  const create = useCreate();
  const update = useUpdate();

  const handleSubmit: SubmitHandler<FieldsValues> = async (data) => {
    article ? await update({ id: article.id, ...data }) : await create(data);
  };

  return (
    <RHFForm
      onSubmit={handleSubmit}
      schema={schema}
      defaultValues={article ?? articleFormDefaultValues}
    >
      {({ loading }) => (
        <>
          <RHFInput name="mainHeadline" type="text" label="Headline" />
          <RHFSelect name="priority" label="Priority" options={constants?.priority ?? []} />
          <RHFSelect
            name="templateType"
            label="Template type"
            options={constants?.templateType ?? []}
            disabled // TODO: disabled till implementation of editor for live updates
          />
          <RHFSelect name="formatType" label="Format type" options={constants?.formatType ?? []} />
          <RHFSelect name="categoryId" label="Category" options={categoriesOptions} />
          <UsersAsyncAutocomplete
            users={
              article?.assignee
                ? { name: article?.assignee.username, value: article?.assignee.id }
                : null
            }
            fieldName="assigneeId"
          />
          <div className={styles.controls}>
            <Button loading={loading} type="submit" label="Save" color="primary" />
            {article?.id && (
              <Link to={`${routes.ARTICLES}/${article?.id}`}>
                <Button
                  variant="outlined"
                  label="Open editor"
                  color="primary"
                  onClick={() => NiceModal.hide(MODAL_ID)}
                />
              </Link>
            )}
          </div>
        </>
      )}
    </RHFForm>
  );
};

export const ArticleModal = () => (
  <Drawer id={MODAL_ID}>
    <ArticleForm />
  </Drawer>
);
