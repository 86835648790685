import type { UserType } from '@/entities/user/api/types';
import type { UseSearch } from '@/shared/api/types';

import { useCallback } from 'react';

import {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useLazySearchUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useUpdateAuthorMutation,
  useDeleteUserMutation,
} from '../api';

export const useGetAll = (queryParams?: PaginationQueryParams) => {
  const { users, isFetching, total } = useGetUsersQuery(
    {
      ...queryParams,
      params: {
        ...queryParams?.params,
      },
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        users: data?.data,
        total: data?.total,
        isFetching,
      }),
    },
  );

  return { users, isFetching, total };
};

export const useGetById = (id: UniqueId) => {
  const { data, isFetching } = useGetUserQuery(id);

  return { user: data, isFetching };
};

export const useLazyGetById = () => {
  const [trigger] = useLazyGetUserQuery();

  return [trigger];
};

export const useSuggestions = () => {
  const [searchUsers, { suggestion, isFetching }] = useLazySearchUsersQuery({
    selectFromResult: ({ data, isFetching }) => ({
      suggestion: data?.data.map(({ id, username }) => ({ value: id, name: username })),
      isFetching,
    }),
  });
  return { searchUsers, suggestion, isFetching };
};

export const useSearch: UseSearch<UserType> = () => {
  const [trigger, { users, total, isFetching }] = useLazySearchUsersQuery({
    selectFromResult: ({ data, isFetching }) => ({
      users: data?.data,
      total: data?.total ?? 0,
      isFetching,
    }),
  });

  const handleSearch = useCallback(
    (search: string, params?: PaginationQueryParams) =>
      trigger({
        search_field: 'username',
        search,
        params: {
          ...params,
        },
      }),
    [trigger],
  );

  return [handleSearch, users, total, isFetching];
};

export const useCreate = () => {
  const [create] = useAddUserMutation();

  return create;
};

export const useDelete = () => {
  const [deleteTag] = useDeleteUserMutation();

  return deleteTag;
};

export const useUpdate = () => {
  const [update] = useUpdateUserMutation();

  return update;
};

export const useUpdateAuthor = () => {
  const [updateAuthor] = useUpdateAuthorMutation();

  return updateAuthor;
};
