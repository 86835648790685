import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { FieldsValues } from '../model';

import { Button, Drawer } from '@/shared/ui';
import { RHFForm, RHFInput } from '@/shared/lib/react-hook-form';
import { useAppSelector } from '@/shared/lib/rtk';
import { tagModel } from '@/entities/tag';

import { useCreate, useUpdate, schema } from '../model';
import { MODAL_ID } from '../config';

const TagForm: FC = () => {
  const tag = useAppSelector(tagModel.selectEditing);
  const create = useCreate();
  const update = useUpdate();

  const handleSubmit: SubmitHandler<FieldsValues> = async (data) => {
    tag ? await update({ id: tag.id, ...data }) : await create(data);
  };

  return (
    <RHFForm onSubmit={handleSubmit} schema={schema} defaultValues={tag ?? {}}>
      {({ loading }) => (
        <>
          <RHFInput name="catchyTitle" type="text" label="CatchyTitle" />
          <RHFInput name="mainHeadline" type="text" label="Seo main headline" />
          <RHFInput name="description" type="text" label="Seo description" />
          <Button loading={loading} type="submit" label="Save" color="primary" />
        </>
      )}
    </RHFForm>
  );
};

export const TagModal = () => (
  <Drawer id={MODAL_ID}>
    <TagForm />
  </Drawer>
);
