import FingerprintSharpIcon from '@mui/icons-material/FingerprintSharp';
import LinkIcon from '@mui/icons-material/Link';
import BlockIcon from '@mui/icons-material/Block';
import CollectionsIcon from '@mui/icons-material/Collections';
import ViewListIcon from '@mui/icons-material/ViewList';
import ArticleIcon from '@mui/icons-material/Article';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import { routes } from '@/shared/routes';
import {
  ARTICLE_READ,
  CATEGORY_READ,
  CONTEXTUAL_LINK_READ,
  CTA_READ,
  IMAGE_READ,
  ROLE_READ,
  STOP_WORD_READ,
  USER_READ,
  TAG_READ,
} from '@/shared/config/permissions';

export const sidebarItems = [
  // {
  //   icon: () => <InboxIcon />,
  //   route: routes.DASHBOARD,
  //   permission: ARTICLE_READ,
  //   label: 'Dashboard',
  // },
  {
    icon: <LowPriorityIcon titleAccess="Tasks" />,
    route: routes.TASKS,
    permission: ARTICLE_READ,
    label: 'Tasks',
  },
  {
    icon: <ArticleIcon titleAccess="Articles" />,
    route: routes.ARTICLES,
    permission: ARTICLE_READ,
    label: 'Articles',
  },
  {
    icon: <ViewListIcon titleAccess="Categories" />,
    route: routes.CATEGORIES,
    permission: CATEGORY_READ,
    label: 'Categories',
  },
  {
    icon: <LocalOfferIcon titleAccess="Tags" />,
    route: routes.TAGS,
    permission: TAG_READ,
    label: 'Tags',
  },
  {
    icon: <CollectionsIcon titleAccess="Gallery" />,
    route: routes.IMAGES,
    permission: IMAGE_READ,
    label: 'Gallery',
  },
  // {
  //   icon: <LinkIcon titleAccess="Contextual links" />,
  //   route: routes.CONTEXTUAL_LINKS,
  //   permission: CONTEXTUAL_LINK_READ,
  //   label: 'Contextual links',
  // },
  {
    icon: <BlockIcon titleAccess="Stop words" />,
    route: routes.STOP_WORDS,
    permission: STOP_WORD_READ,
    label: 'Stop words',
  },
  {
    icon: <ArtTrackIcon titleAccess="CTA" />,
    route: routes.CALL_TO_CATIONS,
    permission: CTA_READ,
    label: 'Call to actions',
  },
  // {
  //   icon: <FingerprintSharpIcon titleAccess="Roles" />,
  //   route: routes.ROLES,
  //   permission: ROLE_READ,
  //   label: 'Roles',
  // },
  {
    icon: <PeopleAltIcon titleAccess="Users" />,
    route: routes.USERS,
    permission: USER_READ,
    label: 'Users',
  },
];
