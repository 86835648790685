import { ZodSchema } from 'zod';

const lengthWithoutSpaces = (value: string) => value.replaceAll(/\s/g, '').length;

export const lengthRefine = (schema: ZodSchema, max: number, min?: number, skipSpace?: boolean) =>
  schema.refine(
    (value: string) => {
      if (value && value.length > 0) {
        let valueLength = value.length;

        if (skipSpace) {
          valueLength = lengthWithoutSpaces(value);
        }

        return min ? valueLength >= min && valueLength <= max : valueLength <= max;
      }
    },
    (value: string) => ({
      message: min
        ? `Must be more than ${min} and less than ${max} symbols ${
            skipSpace && 'without spaces'
          }. Now its: ${skipSpace ? lengthWithoutSpaces(value) : value.length}`
        : `Must be less than ${max} symbols ${skipSpace && 'without spaces'}. Now its: ${
            skipSpace ? lengthWithoutSpaces(value) : value.length
          }`,
    }),
  );
