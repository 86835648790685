import type { FC } from 'react';

import NiceModal from '@ebay/nice-modal-react';

import { Button } from '@/shared/ui';
import { useAppDispatch } from '@/shared/lib/rtk';
import { stopWordModel } from '@/entities/stop-word';

import { UPLOAD_MODAL_ID } from '../config';

type Props = {
  className?: string;
};

export const UploadStopWordsButton: FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  stopWordModel.useGetConstants();

  const handleClick = () => {
    dispatch(stopWordModel.actions.edit(null));
    NiceModal.show(UPLOAD_MODAL_ID);
  };

  return <Button label="Upload file" onClick={handleClick} className={className} />;
};
