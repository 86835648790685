import * as changeCase from 'change-case';

import {
  isArray,
  isBlob,
  isDate,
  isFile,
  isFileList,
  isObject,
  isUndefined,
} from '@/shared/lib/type-guards';

export const jsonToFormData = <T = object>(obj: T, formData?: FormData, pre?: string) => {
  const localFormData = formData || new FormData();

  if (isUndefined(obj)) {
    return localFormData;
  }

  if (isArray(obj) && obj.length) {
    obj.forEach((value) => {
      jsonToFormData(value, localFormData, `${pre}[]`);
    });

    return localFormData;
  }

  if (isDate(obj)) {
    localFormData.append(pre, obj.toISOString());
  }

  if (isBlob(obj) || isFile(obj)) {
    localFormData?.append(pre, obj);
  }

  if (isFileList(obj)) {
    localFormData?.append(pre, obj[0]);
    return localFormData;
  }

  if (isObject(obj)) {
    Object.entries(obj).forEach(([prop, value]) => {
      const key = pre ? `${pre}[${prop}]` : changeCase.snakeCase(prop);
      jsonToFormData(value, localFormData, key);
    });

    return localFormData;
  }

  localFormData.append(pre, obj);

  return localFormData;
};
