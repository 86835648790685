import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type {
  CallToActionConstantsKeys,
  CallToActionType,
} from '@/entities/call-to-action/api/types';

import { useMemo } from 'react';

import { callToActionModel } from '@/entities/call-to-action';
import { DataGrid } from '@/shared/ui';

import { DeleteCallToAction } from './delete-button';
import { EditCallToActionButton } from './edit-button';

const getColumns = (
  constants: ConstantsType<CallToActionConstantsKeys> | undefined,
): NarrowGridColumns<CallToActionType> => [
  {
    field: 'name',
    headerName: 'Call to action',
    width: 400,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'position',
    headerName: 'Position',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) =>
      constants?.position.find(({ value }) => value === row.position)?.name ?? row.position,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.category?.catchyTitle ?? '-',
  },
  {
    field: 'isEnabled',
    headerName: 'Is enabled',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => (row.isEnabled ? 'Yes' : 'No'),
  },
];

export const CallToActionList = () => {
  const { callToActions, isFetching } = callToActionModel.useGetAll(['category']);
  const { constants } = callToActionModel.useGetConstants();

  const columns = useMemo(() => getColumns(constants), [constants]);

  return (
    <DataGrid
      columns={columns}
      loading={isFetching}
      rows={callToActions ?? []}
      actions={(entity) => (
        <>
          <DeleteCallToAction entity={entity} /> <EditCallToActionButton entity={entity} />
        </>
      )}
    />
  );
};
