import type { UserType } from '@/entities/user/api/types';

export const getInitialFormValues = ({
  avatar,
  description,
  bio,
  awards,
  education,
  experience,
  socialLinks,
}: UserType) => ({
  file: avatar,
  description: description ?? '',
  bio: bio ?? '',
  awards: awards ?? '',
  education: education ?? '',
  experience: experience ?? '',
  ...socialLinks,
});
