import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { FieldsValues } from '../model';

import { useState } from 'react';
import { Box } from '@mui/material';

import { RHFForm, RHFInput, RHFSelect, RHFUploadButton } from '@/shared/lib/react-hook-form';
import { readFileAsImage } from '@/shared/lib/file';
import { useAppSelector } from '@/shared/lib/rtk';
import { Button, Drawer } from '@/shared/ui';
import { galleryModel } from '@/entities/gallery';

import { MODAL_ID } from '../config';
import { createSchema, updateSchema, useCreate, useUpdate } from '../model';

const GalleryForm: FC = () => {
  const [imageFile, setImageFile] = useState('');
  const { constants } = galleryModel.useGetConstants();
  const create = useCreate();
  const update = useUpdate();
  const image = useAppSelector(galleryModel.selectEditing);

  const handleSubmit: SubmitHandler<FieldsValues> = async (data) => {
    image ? await update({ id: image.id, ...data }) : await create(data);
    setImageFile('');
  };

  const handleFileSelect = async (file: Blob | File) => {
    const imageElement = await readFileAsImage(file);
    setImageFile(imageElement.src);
  };

  return (
    <>
      <Box
        component="img"
        sx={{ mx: 'auto', my: 2, maxHeight: 250, maxWidth: '100%' }}
        src={image ? image.previewUrl : imageFile}
      />
      <RHFForm
        onSubmit={handleSubmit}
        schema={image ? updateSchema : createSchema}
        defaultValues={image ? { ...image, name: image.label } : {}}
      >
        {({ loading }) => (
          <>
            {!image && (
              <RHFUploadButton
                name="file"
                accept="image/*"
                label="Upload image +"
                onFileSelect={handleFileSelect}
              />
            )}
            <RHFInput name="name" type="text" label="Name" />
            <RHFSelect name="source" label="Image source" options={constants?.source ?? []} />
            <Button loading={loading} type="submit" label="Save" color="primary" />
          </>
        )}
      </RHFForm>
    </>
  );
};

export const GalleryModal = () => {
  return (
    <Drawer id={MODAL_ID}>
      <GalleryForm />
    </Drawer>
  );
};
