import type { FC, ReactNode } from 'react';

import { useAppSelector } from '@/shared/lib/rtk';
import { sessionModel } from '@/entities/session';

type Props = {
  permissions?: Array<string>;
  children?: ReactNode;
};

export const HasAccess: FC<Props> = ({ permissions = [], children }) => {
  const user = useAppSelector(sessionModel.selectUser);

  if (user === null) {
    return null;
  }

  const { permissions: userPermissions } = user;

  const hasPermission = permissions.every((element) => userPermissions.includes(element));

  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
};
