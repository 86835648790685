import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type { UserType } from '@/entities/user/api/types';
import type { RoleType } from '@/entities/role/api/types';

import { FC, useMemo } from 'react';

import { EditUserButton, DeleteUserButton, OpenAuthorPage } from '@/features/user';
import { roleModel } from '@/entities/role';
import { DataGrid } from '@/shared/ui';

const getColumns = (roles: RoleType[] | undefined): NarrowGridColumns<UserType> => [
  {
    field: 'username',
    headerName: 'Name',
    width: 250,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'position',
    headerName: 'Position',
    width: 250,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'roleId',
    headerName: 'Role',
    width: 250,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => roles?.find(({ id }) => id === row.roleId)?.name ?? row.roleId,
  },
];

type Props = ItemsListProps<UserType>;

export const UserList: FC<Props> = ({ items, total, isFetching }) => {
  const { roles } = roleModel.useGetAll();

  const columns = useMemo(() => getColumns(roles), [roles]);

  return (
    <DataGrid
      rowsTotalCount={total}
      columns={columns}
      loading={isFetching}
      rows={items ?? []}
      actions={(entity) => (
        <>
          <DeleteUserButton entity={entity} />
          <OpenAuthorPage entity={entity} />
          <EditUserButton entity={entity} />
        </>
      )}
    />
  );
};
