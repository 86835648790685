import { z } from 'zod';

import { authorDescription } from '@/shared/validation/author-description';
import { socialPlatform } from '@/shared/validation/social-platform';
import { title, lengthRefine, password, position, email } from '@/shared/validation';

const baseSchema = z.object({
  username: lengthRefine(title('Name'), 80),
  roleId: z.number({ required_error: 'Role is required' }),
  email: lengthRefine(email, 100),
  position: lengthRefine(position, 255).nullish().or(z.literal('')),
  webEnabled: z.boolean().optional(),
});

export const createSchema = z
  .object({
    password: lengthRefine(password, 16, 6),
    confirmPassword: z.string(),
  })
  .merge(baseSchema)
  .refine((schema) => schema.password === schema.confirmPassword, {
    message: 'Passwords are not same.',
    path: ['confirmPassword'],
  });

export const updateSchema = z
  .object({
    password: lengthRefine(password, 16, 6).optional(),
    confirmPassword: z.string().optional(),
  })
  .merge(baseSchema)
  .refine((schema) => schema.password === schema.confirmPassword, {
    message: 'Passwords are not same.',
    path: ['confirmPassword'],
  });

export const authorSchema = z.object({
  file: z.any().refine((value: string | FileList | null) => !!value, {
    message: `Image is required`,
  }),
  description: lengthRefine(authorDescription, 600, 100),
  bio: lengthRefine(authorDescription, 255, 150).nullish().or(z.literal('')),
  education: lengthRefine(z.string(), 250, 0, true),
  experience: lengthRefine(z.string(), 250, 0, true),
  awards: lengthRefine(z.string(), 250, 0, true).nullish().or(z.literal('')),
  facebook: socialPlatform('facebook'),
  instagram: socialPlatform('instagram'),
  twitter: socialPlatform('twitter'),
  linkedin: socialPlatform('linkedin'),
  muckrack: socialPlatform('muckrack'),
});

export type FieldsValues = z.infer<typeof createSchema> | z.infer<typeof updateSchema>;
export type AuthorFieldsValues = z.infer<typeof authorSchema>;
