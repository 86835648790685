import type { FC } from 'react';
import type { RefCallBack } from 'react-hook-form/dist/types';
import type { FieldError } from 'react-hook-form/dist/types/errors';

import { Button, FormHelperText } from '@mui/material';

type Props = {
  accept: string;
  label: string;
  multiple?: boolean;
  name: string;
  inputRef: RefCallBack;
  handleOnChange: (...event: any[]) => FileList | void;
  error?: FieldError;
};

export const UploadButton: FC<Props> = ({
  accept,
  label,
  multiple,
  name,
  inputRef,
  handleOnChange,
  error,
}) => {
  return (
    <>
      <Button variant="contained" component="label">
        {label}
        <input
          hidden
          accept={accept}
          multiple={multiple}
          type="file"
          ref={inputRef}
          name={name}
          onChange={(e) => handleOnChange(e)}
        />
      </Button>
      {error && (
        <FormHelperText sx={{ mx: '14px' }} error={!!error}>
          {error.message}
        </FormHelperText>
      )}
    </>
  );
};
