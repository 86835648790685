import type { CategoryType } from './types';

import { api } from '@/shared/api';

const RESOURCE_PATH = 'categories';

const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<CategoryType[], QueryParams | void>({
      query: (args) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'GET',
        params: args?.params,
      }),
      providesTags: (result = []) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Categories', id } as const)),
              { type: 'Categories', id: 'LIST' },
            ]
          : [{ type: 'Categories', id: 'LIST' }],
    }),

    searchCategories: builder.query<
      ResponseWithPagination<CategoryType>,
      { search_field: string; search: string }
    >({
      query: ({ search, search_field }) => ({
        url: `/${RESOURCE_PATH}/search?search=${search}&search_field=${search_field}`,
        method: 'GET',
      }),
    }),

    addCategory: builder.mutation({
      query: (body) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),

    updateCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),

    deleteCategory: builder.mutation({
      query: (id) => {
        return {
          url: `/${RESOURCE_PATH}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Categories', id }],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useLazySearchCategoriesQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
