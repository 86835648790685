import type { ChangeEvent, FC } from 'react';

import { useController } from 'react-hook-form';

import { UploadButton } from '@/shared/ui';

type Props = {
  name: string;
  accept: string;
  label: string;
  multiple?: boolean;
  onFileSelect?: (file: Blob | File) => void;
};

export const RHFUploadButton: FC<Props> = ({ name, label, accept, multiple, onFileSelect }) => {
  const {
    field: { onChange, ref },
    fieldState: { error },
  } = useController({ name });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filesList = e.target.files;
    onChange(filesList);

    if (filesList != null && filesList.length > 0 && onFileSelect) onFileSelect(filesList[0]);
  };
  return (
    <UploadButton
      inputRef={ref}
      name={name}
      label={label}
      accept={accept}
      multiple={multiple}
      handleOnChange={handleOnChange}
      error={error}
    />
  );
};
