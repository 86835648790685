// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification__alert___fibMK {
  margin: 10px 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0.24,0);
  width: 250px;
  min-height: 4em;
  align-items: center;
  text-align: center;
}

.notification__snackbar___XUckb {
  top: 74px;
}
`, "",{"version":3,"sources":["webpack://./src/shared/notification/ui/notification.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kEAAkE;EAClE,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".alert {\n  margin: 10px 0;\n  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0.24,0);\n  width: 250px;\n  min-height: 4em;\n  align-items: center;\n  text-align: center;\n}\n\n.snackbar {\n  top: 74px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `notification__alert___fibMK`,
	"snackbar": `notification__snackbar___XUckb`
};
export default ___CSS_LOADER_EXPORT___;
