import { useEffect, useRef, useState } from 'react';

type Props = {
  link: string;
  id: string;
};

export const TiktokComponent = ({ link, id }: Props) => {
  const containerRef = useRef(null);
  const isFirstRender = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isFirstRender.current) {
      setIsLoading(true);

      const script = document.createElement('script');
      script.src = 'https://www.tiktok.com/embed.js';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        setIsLoading(false);
      };

      isFirstRender.current = false;
    }
  }, [link]);

  return (
    <>
      {isLoading ? 'Embed is loading...' : null}
      <div ref={containerRef}>
        <blockquote cite={link} data-video-id={id} className="tiktok-embed">
          <section />
        </blockquote>
      </div>
    </>
  );
};
