// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar__toolbar___C07i4 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;

}

.toolbar__button___bRs2z {
  color: rgba(0, 0, 0, 0.54);
  border-color: rgba(0, 0, 0, 0.12);
  padding: 11px;

}
`, "",{"version":3,"sources":["webpack://./src/shared/editor/plugins/toolbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,WAAW;;AAEb;;AAEA;EACE,0BAA0B;EAC1B,iCAAiC;EACjC,aAAa;;AAEf","sourcesContent":[".toolbar {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  gap: 0.5rem;\n\n}\n\n.button {\n  color: rgba(0, 0, 0, 0.54);\n  border-color: rgba(0, 0, 0, 0.12);\n  padding: 11px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": `toolbar__toolbar___C07i4`,
	"button": `toolbar__button___bRs2z`
};
export default ___CSS_LOADER_EXPORT___;
