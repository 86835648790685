import type { ArticleType, UniquenessUrlType } from '@/entities/article/api/types';
import type { Dispatch, SetStateAction } from 'react';

import { useEffect, useState } from 'react';
import {
  GridRowId,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { Checkbox, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Button, DataGrid } from '@/shared/ui';
import { articleModel, useUniquenessContext } from '@/entities/article';
import { showErrorNotification } from '@/shared/notification/model';

import { useCheckUniquenessAndSubmit } from '../model/hooks';

const columns = [
  {
    field: 'url',
    headerName: 'Url',
    width: 600,
  },
  {
    field: 'plagiat',
    headerName: 'Plagiat',
    width: 140,
  },
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 100,
  },
];
const CheckedIcon = () => (
  <IconButton aria-label="visibile" color="success">
    <VisibilityIcon />
  </IconButton>
);
const UnCheckedIcon = () => (
  <IconButton aria-label="visibile" color="secondary">
    <VisibilityIcon />
  </IconButton>
);
export const UniquenessChecker = ({
  article,
  plagiatState,
  setPlagiatState,
  saveArticleAndCheckUniqueness,
  textContent,
}: {
  article: ArticleType;
  plagiatState: string[];
  setPlagiatState: Dispatch<SetStateAction<string[]>>;
  saveArticleAndCheckUniqueness: () => Promise<unknown>;
  textContent: string;
}) => {
  const { uniquenessCheckLimit } = articleModel.useGetUniquenessLimit(article.id);
  const { getUniqueness, uniquenessResult, uniquenessLoading } = useCheckUniquenessAndSubmit(
    article.id,
    textContent,
  );
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>();
  const { setUniquenessState, urls } = useUniquenessContext();

  useEffect(() => {
    if (uniquenessResult) {
      setUniquenessState(uniquenessResult);
    }
  }, [uniquenessResult]);

  useEffect(() => {
    if (!plagiatState.length) {
      setRowSelectionModel([]);
    }
  }, [plagiatState]);

  const checkLimit = uniquenessResult?.limitRemaining ?? uniquenessCheckLimit ?? 0;

  const getSummaryPlagiatWords = (rows: GridRowSelectionModel) => {
    if (!(uniquenessResult?.urls || article.uniquenessBody?.urls)) return [];

    let plagiatWordsArray: UniquenessUrlType[] = [];

    if (uniquenessResult?.urls) {
      plagiatWordsArray = uniquenessResult?.urls;
    }

    if (article.uniquenessBody?.urls) {
      plagiatWordsArray = article.uniquenessBody?.urls;
    }

    const resultArray = plagiatWordsArray
      .filter((url) => rows.includes(url.url))
      .map((url) => (url.words.length > 0 ? url.words.split(' ') : []));

    return [...new Set(resultArray?.flat())];
  };

  const handleSelectionChange = (rows: GridRowSelectionModel) => {
    setPlagiatState(getSummaryPlagiatWords(rows));
  };

  const handleOnCLick = () => {
    if (checkLimit && checkLimit > 0) {
      getUniqueness(saveArticleAndCheckUniqueness).catch((err) => console.error(err));
    } else {
      showErrorNotification(`Uniqueness limit check exceeded.`);
    }
  };

  return (
    <div>
      <Button
        style={{ flexShrink: 0, marginBottom: '1rem' }}
        label={`Save & Check uniqueness: ${checkLimit}`}
        onClick={() => handleOnCLick()}
      />

      {urls.length > 0 && (
        <DataGrid
          autoHeight
          columns={columns}
          rows={urls}
          hidePagination
          loading={uniquenessLoading}
          slots={{
            baseCheckbox: (props) => (
              <Checkbox
                {...props}
                checkedIcon={<CheckedIcon />}
                icon={<UnCheckedIcon />}
                indeterminateIcon={<UnCheckedIcon />}
              />
            ),
          }}
          checkboxSelection
          onSelectionChange={(rows) => {
            setRowSelectionModel(rows);
            handleSelectionChange(rows);
          }}
          rowSelectionModel={rowSelectionModel}
          getRowId={(item) => item.url}
        />
      )}
    </div>
  );
};
