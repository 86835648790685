import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 25;

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const perPage = searchParams.get('per_page');

  const handleSetPage = useCallback(
    (page: number | string) => {
      searchParams.set('page', page <= 0 ? '1' : page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleSetPerPage = useCallback(
    (perPage: number | string) => {
      searchParams.set('per_page', perPage.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleReset = useCallback(
    (provided?: URLSearchParams) => {
      const currentSearchParams = provided ?? searchParams;
      if (currentSearchParams.has('page')) {
        currentSearchParams.delete('per_page');
        currentSearchParams.delete('page');
        setSearchParams(currentSearchParams);
      }
    },
    [searchParams, setSearchParams],
  );

  return useMemo(
    () => ({
      setPage: handleSetPage,
      setPerPage: handleSetPerPage,
      resetPagination: handleReset,
      page: page ? Number(page) : DEFAULT_PAGE,
      perPage: perPage ? Number(perPage) : DEFAULT_PAGE_SIZE,
    }),
    [handleReset, handleSetPage, handleSetPerPage, page, perPage],
  );
};
