import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePagination } from './use-pagination';

type FilterHandler = (value: string | number | Array<string | number>) => void;

type Filter = {
  filter: FilterHandler;
  resetFilter: () => void;
  values: Array<string>;
};

export const useFilterBy = (filterBy: string): Filter => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { resetPagination } = usePagination();
  const currentValues = useMemo(
    () => searchParams.get(filterBy)?.split(',') ?? [],
    [filterBy, searchParams],
  );

  const handleFilter: FilterHandler = useCallback(
    (value) => {
      let newValues;

      if (value instanceof Array) {
        newValues = value;
      } else {
        newValues = currentValues.includes(value.toString())
          ? currentValues.filter((newValue) => newValue !== value)
          : [...currentValues, value];
      }

      if (newValues.length === 0) {
        searchParams.delete(filterBy);
      } else {
        searchParams.set(filterBy, newValues.join(','));
      }

      resetPagination(searchParams);
      setSearchParams(searchParams);
    },
    [currentValues, filterBy, resetPagination, searchParams, setSearchParams],
  );

  const resetFilter = useCallback(() => {
    searchParams.delete(filterBy);
    setSearchParams(searchParams);
  }, [filterBy, searchParams, setSearchParams]);

  const result = useMemo(
    () => ({
      filter: handleFilter,
      values: currentValues,
      resetFilter,
    }),
    [currentValues, handleFilter, resetFilter],
  );

  return result;
};
