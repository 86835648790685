import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

export type CheckboxGroupItem = {
  value: string | number;
  name: string;
};

type Props = {
  checkboxes: CheckboxGroupItem[];
  onChange: (value: string) => void;
  values: Array<string>;
};

export const CheckboxGroup: FC<Props> = ({ checkboxes, onChange, values }) => (
  <div>
    {checkboxes.map(({ value, name }) => (
      <div key={value}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={({ target }) => onChange(target.value)}
              value={value}
              name={name}
              checked={values.includes(value.toString())}
            />
          }
          label={name}
        />
      </div>
    ))}
  </div>
);
