import type { SearchQuery, SearchTrigger } from '@/shared/api/types';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { usePagination } from './use-pagination';
import { useSearchParams } from 'react-router-dom';

export const useSearch = <T>(onSearch: SearchQuery<T>): [(phrase: string) => void, boolean] => {
  const [hasSearch, setHasSearch] = useState(false);
  const [phrase, setPhrase] = useState('');
  const { page, perPage, resetPagination } = usePagination();
  const [searchParams] = useSearchParams();
  const currentRequest = useRef<SearchTrigger<T>>();
  const debouncedSearch = useDebouncedCallback((search: string, params?: PaginationQueryParams) => {
    currentRequest.current = onSearch(search, params);
    currentRequest.current
      .unwrap()
      .then(() => setHasSearch(true))
      .catch(() => setHasSearch(false));
  }, 300);

  useEffect(() => {
    currentRequest.current && currentRequest.current.abort();
    debouncedSearch.cancel();

    if (phrase.length) {
      debouncedSearch(phrase, { page, per_page: perPage });
    } else {
      setHasSearch(false);
    }
  }, [debouncedSearch, page, perPage, phrase, searchParams]);

  const handleSearch = useCallback(
    (phrase: string) => {
      resetPagination();
      setPhrase(phrase);
    },
    [resetPagination],
  );

  return [handleSearch, hasSearch];
};
