import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { Area } from 'react-easy-crop/types';
import type { UserType } from '@/entities/user/types';
import type { AuthorFieldsValues } from '../model';

import { useState } from 'react';

import { RHFForm, RHFInput, RHFUploadButton } from '@/shared/lib/react-hook-form';
import { capitalizedLetter } from '@/shared/lib/string';
import { readFileAsImage } from '@/shared/lib/file';
import { Button } from '@/shared/ui';

import { useUpdateAuthor, authorSchema } from '../model';
import { getInitialFormValues, prepareAuthorData } from '../lib';
import { SelectAvatar } from './select-avatar';

import styles from './author-form.css';

const SOCIAL_PLATFORMS = ['facebook', 'instagram', 'twitter', 'linkedin', 'muckrack'];

type AuthorFormProps = {
  entity: UserType;
  id: UniqueId;
};

export const AuthorForm: FC<AuthorFormProps> = ({ entity, id }) => {
  const update = useUpdateAuthor();
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Area | null>(null);

  const renderSocialLinks = () =>
    SOCIAL_PLATFORMS.map((platform) => (
      <RHFInput
        key={platform}
        name={platform}
        type="text"
        label={`${capitalizedLetter(platform)} account link`}
        size={'small'}
      />
    ));

  const handleFileSelect = async (file: Blob | File) => {
    const imageElement = await readFileAsImage(file);
    setImage(imageElement.src);
  };

  const handleSubmit: SubmitHandler<AuthorFieldsValues> = async (data) => {
    await update(id, prepareAuthorData(data, crop));
  };

  return (
    <RHFForm
      onSubmit={handleSubmit}
      schema={authorSchema}
      defaultValues={entity ? getInitialFormValues(entity) : {}}
      className={styles.form}
    >
      {({ loading }) => (
        <>
          <div className={styles.imageWrapper}>
            <SelectAvatar uploadImage={image} defaultImage={entity?.avatar} setCrop={setCrop} />
            <RHFUploadButton
              name="file"
              accept="image/*"
              label="Upload image"
              onFileSelect={handleFileSelect}
            />
          </div>
          <div className={styles.inputsWrapper}>
            <h1 className={styles.title}>{entity?.username}</h1>

            <RHFInput name="description" type="text" label="Description" multiline size="small" />
            <RHFInput name="bio" type="text" label="Biography" size="small" />
            <RHFInput name="education" type="text" label="Education" size="small" />
            <RHFInput name="experience" type="text" label="Experience" size="small" />
            <RHFInput name="awards" type="text" label="Awards" size="small" />

            {renderSocialLinks()}

            <Button
              className={styles.button}
              loading={loading}
              type="submit"
              label="Save"
              color="primary"
            />
          </div>
        </>
      )}
    </RHFForm>
  );
};
