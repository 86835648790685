export const isUndefined = (value: unknown) => {
  return value === undefined;
};

export const isObject = (value: unknown): value is object => {
  return value === Object(value);
};

export const isArray = (value: unknown): value is Array<any> => {
  return Array.isArray(value);
};

export const isDate = (value: unknown): value is Date => {
  return value instanceof Date;
};

export const isFileList = (value: unknown): value is FileList => {
  return value instanceof FileList;
};

export const isBlob = (value: unknown): value is Blob => {
  return (
    isObject(value) &&
    typeof value.size === 'number' &&
    typeof value.type === 'string' &&
    typeof value.slice === 'function'
  );
};

export const isFile = (value: unknown) => {
  return (
    isBlob(value) &&
    typeof value.name === 'string' &&
    (isObject(value.lastModifiedDate) || typeof value.lastModified === 'number')
  );
};
