import type { FC, ReactNode } from 'react';
import type { ArticleType } from '@/entities/article/types';

import React from 'react';

import { HasAccess } from '@/entities/session';
import { articleModel } from '@/entities/article';

import { confirmModal } from '@/shared/ui/modal';
import { ARTICLE_DELETE } from '@/shared/config/permissions';

import { useDelete, useUpdateStatus } from '../model';
import { useShowNotification } from '@/shared/notification';

type HandleDelete = (onDelete?: () => void) => Promise<void>;

type Props = {
  entity: ArticleType;
  children: ({
    handleDelete,
    handleRestore,
  }: {
    handleDelete: HandleDelete;
    handleRestore: () => void;
  }) => ReactNode;
};

export const DeleteArticle: FC<Props> = React.memo(({ entity, children }) => {
  const update = useUpdateStatus();
  const deleteArticle = useDelete();
  const { showSuccessNotification } = useShowNotification();

  const handleClick: HandleDelete = async (onDelete?: () => void) => {
    confirmModal
      .show({
        title:
          entity.status === articleModel.ArticleStatusEnum.IN_TRASH
            ? 'This will remove article forever.'
            : 'This will move selected article to trash.',
      })
      .then(() => handleDelete(onDelete))
      .catch(handleClose);
  };

  const handleDelete = async (onDelete?: () => void) => {
    entity.status === articleModel.ArticleStatusEnum.IN_TRASH
      ? await deleteArticle(entity.id)
      : await update({ id: entity.id, status: articleModel.ArticleStatusEnum.IN_TRASH });
    onDelete && onDelete();
    await confirmModal.hide();
  };

  const handleClose = async () => {
    await confirmModal.hide();
  };

  const handleRestore = () => {
    confirmModal
      .show({
        title: 'This will restore article to draft.',
      })
      .then(async () => {
        await update({ id: entity.id, status: articleModel.ArticleStatusEnum.DRAFT });
        showSuccessNotification('Article was restored');
        await confirmModal.hide();
      })
      .catch(handleClose);
  };

  return (
    <HasAccess permissions={[ARTICLE_DELETE]}>
      {children({ handleDelete: handleClick, handleRestore })}
    </HasAccess>
  );
});

DeleteArticle.displayName = 'DeleteArticle';
