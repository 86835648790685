import { useCallback } from 'react';

import { useAppDispatch } from '@/shared/lib/rtk';

import { showSuccessNotification, showErrorNotification } from '../model';

export const useShowNotification = () => {
  const dispatch = useAppDispatch();

  const showErrorNotificationCb = useCallback(
    (message: string) => {
      dispatch(showErrorNotification(message));
    },
    [dispatch],
  );

  const showSuccessNotificationCb = useCallback(
    (message: string) => {
      dispatch(showSuccessNotification(message));
    },
    [dispatch],
  );

  return {
    showSuccessNotification: showSuccessNotificationCb,
    showErrorNotification: showErrorNotificationCb,
  };
};
