import type { FC } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  src: string;
};

export const CopyImageUrlButton: FC<Props> = ({ src }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(src);
  };
  return (
    <Tooltip title={src}>
      <IconButton onClick={handleClick}>
        <ContentCopyIcon sx={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};
