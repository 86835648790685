import type { FC, ReactNode } from 'react';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { FieldError } from 'react-hook-form/dist/types/errors';
import type { Noop, RefCallBack } from 'react-hook-form/dist/types';

import classnames from 'classnames';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@mui/material';

import styles from './select.css';

export type SelectOption = {
  value: string | number;
  name: string;
};

type SelectProps = {
  options: Array<SelectOption>;
  label?: string;
  value?: string | number | Array<string | number>;
  multiple?: boolean;
  onChange: (event: SelectChangeEvent, child: ReactNode) => unknown;
  error?: FieldError;
  inputRef?: RefCallBack;
  onBlur?: Noop;
  disabled?: boolean;
  className?: string;
};

export const Select: FC<SelectProps> = ({
  options,
  label,
  value,
  multiple = false,
  onChange,
  onBlur,
  error,
  inputRef,
  disabled,
  className,
}) => {
  const defaultValue = multiple ? [] : '';

  return (
    <FormControl fullWidth className={classnames(styles.root, className)}>
      <InputLabel error={!!error}>{label}</InputLabel>
      <MUISelect
        error={!!error}
        value={value ?? defaultValue}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={inputRef}
        disabled={disabled}
        multiple={multiple}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
            },
          },
        }}
      >
        {options.map(({ value, name }) => (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        ))}
      </MUISelect>
      {error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
    </FormControl>
  );
};
