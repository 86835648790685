export const REDUCER_PATH = 'entities/article';

export const AdvertStatusEnum = {
  REGULAR: 0,
  WITHOUT_ADS: 2,
} as const;

export const FormatTypeEnum = {
  REGULAR: 0,
  FEATURES: 1,
  ANALYSIS: 2,
  HISTORY: 3,
  INTERVIEW: 4,
  LISTICLES: 5,
  OPINION: 6,
  REACTIONS: 7,
  OTHER: 8,
  MATCH_PREVIEW: 9,
  MATCH_REPORT: 10,
} as const;

export const PriorityEnum = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
} as const;

export const SourceTypeEnum = {
  COMMON: 1,
  EXPORTED: 2,
  AFP: 3,
} as const;

export const ArticleStatusEnum = {
  DRAFT: 0,
  IN_WORK: 1,
  READY: 2,
  PUBLISHED: 3,
  IN_TRASH: 4,
  SCHEDULED: 5,
} as const;

export const ArticleStatusToNameEnum = {
  [ArticleStatusEnum.DRAFT]: 'Draft',
  [ArticleStatusEnum.IN_WORK]: 'In work',
  [ArticleStatusEnum.READY]: 'Ready',
  [ArticleStatusEnum.PUBLISHED]: 'Published',
  [ArticleStatusEnum.IN_TRASH]: 'In trash',
  [ArticleStatusEnum.SCHEDULED]: 'Scheduled',
} as const;

export const TemplateTypeEnum = {
  REGULAR: 0,
  EXTERNAL: 5,
  LIVE_UPDATES: 7,
} as const;

export const TopStatusEnum = {
  REGULAR: 0,
  MAIN: 1,
  TOP: 2,
  FEATURED: 3,
} as const;

export const TASK_COLUMNS = [
  { title: 'Draft', identifier: ArticleStatusEnum.DRAFT },
  { title: 'In Work', identifier: ArticleStatusEnum.IN_WORK },
  { title: 'Ready', identifier: ArticleStatusEnum.READY },
  { title: 'Published', identifier: ArticleStatusEnum.PUBLISHED },
  { title: 'Scheduled', identifier: ArticleStatusEnum.SCHEDULED },
];
