import type { FieldsValues } from '@/features/user/model';
import type { UserType, AuthorFormData } from './types';

import { api } from '@/shared/api';
import { CACHE_KEYS } from '@/shared/api/base';

import { SearchQueryParams } from '@/shared/api/types';

import { transformResponse } from './transform-response';

const RESOURCE_PATH = 'users';
const CACHE_KEY = CACHE_KEYS.user;

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<ResponseWithPagination<UserType>, PaginationQueryParams>({
      query: ({ page, per_page, params }) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'GET',
        params: { page, per_page, ...params },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: CACHE_KEY, id } as const)),
              { type: CACHE_KEY, id: 'LIST' },
            ]
          : [{ type: CACHE_KEY, id: 'LIST' }],
      transformResponse: transformResponse,
    }),

    getUser: builder.query<UserType, UniqueId>({
      query: (id) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: CACHE_KEY, id }],
    }),

    searchUsers: builder.query<ResponseWithPagination<UserType>, SearchQueryParams>({
      query: ({ search, search_field, params }) => ({
        url: `/${RESOURCE_PATH}/search?search=${search}&search_field=${search_field}`,
        method: 'GET',
        params: params
          ? { page: params.page, per_page: params.per_page, ...params.params }
          : undefined,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: CACHE_KEY, id } as const)),
              { type: CACHE_KEY, id: 'LIST' },
            ]
          : [],
      transformResponse: transformResponse,
    }),

    addUser: builder.mutation({
      query: (data) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: CACHE_KEY, id: 'LIST' }],
    }),

    updateUser: builder.mutation<void, FieldsValues & { id: UniqueId }>({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_KEY, id }],
    }),

    updateAuthor: builder.mutation<void, { id: UniqueId; data: AuthorFormData }>({
      query: ({ id, data }) => ({
        url: `/${RESOURCE_PATH}/${id}/author`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CACHE_KEY, id }],
    }),

    deleteUser: builder.mutation({
      query: (id) => {
        return {
          url: `/${RESOURCE_PATH}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: CACHE_KEY, id }],
    }),
  }),
});

export const {
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateAuthorMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useLazySearchUsersQuery,
} = usersApi;
