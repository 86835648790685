import type { ArticleType } from '@/entities/article/api/types';
import type { ArticleStatus } from '../api/types';

import { ArticleStatusEnum } from '../model';

export const canChangeStatus = (task: ArticleType, columnStatus: ArticleStatus) => {
  if (columnStatus === ArticleStatusEnum.IN_WORK && task.status !== columnStatus) return true;

  return !(
    columnStatus === ArticleStatusEnum.SCHEDULED ||
    columnStatus === ArticleStatusEnum.PUBLISHED ||
    task.status === ArticleStatusEnum.SCHEDULED ||
    task.status === ArticleStatusEnum.PUBLISHED ||
    task.status >= columnStatus
  );
};
