import type { EditorState, SerializedEditorState } from 'lexical';
import type { SearchTagsResource } from '@/features/search/types';
import type { User } from '@/entities/session/types';
import type { UserType } from '@/entities/user/types';
import type { CategoryType } from '@/entities/category/types';
import type { ImageResponseType } from '@/entities/gallery/types';

import { SearchTypeToTagType } from '@/features/search';

import {
  AdvertStatusEnum,
  ArticleStatusEnum,
  FormatTypeEnum,
  PriorityEnum,
  SourceTypeEnum,
  TemplateTypeEnum,
  TopStatusEnum,
} from '../model';

type Estimate = number;
type Notes = string | null;
type UniquenessType = number | null;

export type ArticleStatus = (typeof ArticleStatusEnum)[keyof typeof ArticleStatusEnum];
export type AdvertStatus = (typeof AdvertStatusEnum)[keyof typeof AdvertStatusEnum];
export type FormatType = (typeof FormatTypeEnum)[keyof typeof FormatTypeEnum];
export type Priority = (typeof PriorityEnum)[keyof typeof PriorityEnum];
export type SourceType = (typeof SourceTypeEnum)[keyof typeof SourceTypeEnum];
export type TemplateType = (typeof TemplateTypeEnum)[keyof typeof TemplateTypeEnum];
export type TopStatus = (typeof TopStatusEnum)[keyof typeof TopStatusEnum];

type ArticleTag = {
  createdAt: DateTime;
  id: UniqueId;
  relatedId: number;
  related?: SearchTagsResource;
  relatedType: ExtracValuesFromEnum<typeof SearchTypeToTagType>;
  updatedAt: DateTime;
};

export type ArticleType = {
  id: UniqueId;
  categoryId: UniqueId;
  slug: Slug;
  mainHeadline: MainHeadline;
  status: ArticleStatus;
  sourceType: SourceType;
  advertStatus: AdvertStatus;
  topStatus: TopStatus;
  imageId: UniqueId;
  isBreaking: boolean;
  isEvergreen: boolean;
  isSponsored: boolean;
  isVerified: boolean;
  tableOfContents: boolean;
  catchyTitle: CatchyTitle | null;
  rssTitle: RssTitle | null;
  fbCatchyTitle: FbCatchyTitle | null;
  description: SeoDescription | null;
  assigneeId: UniqueId | null;
  assignee?: UserType;
  reporterId: UniqueId | null;
  notes: Notes | null;
  publishedAt: DateTime | null;
  tags?: Array<ArticleTag> | null;
  users?: Array<User> | null;
  priority: Priority;
  estimate: Estimate;
  body: Array<EditorState>;
  templateType: TemplateType;
  updatedAt: DateTime;
  createdAt: DateTime;
  formatType: FormatType;
  image?: ImageResponseType;
  fbImage?: ImageResponseType | null;
  category?: CategoryType;
  uniqueness: UniquenessType;
  uniquenessBody: UniquenessResult;
};

export type SearchConsoleKeyword = {
  key: string;
  id: string;
  clicks: number;
  position: number;
  impressions: number;
};

export type UniquenessUrlType = {
  plagiat: number;
  url: string;
  words: string;
  id: UniqueId;
};

export type UniquenessResult = {
  clearText: string;
  limitRemaining: number;
  uniquePercentage: number;
  urls: UniquenessUrlType[];
};

export type UniquenessLimit = {
  limitRemaining: number;
};

export const isArticle = (data: any): data is ArticleType => {
  return 'isEvergreen' in data;
};

export const isUniquenessResult = (data: any): data is UniquenessResult => {
  return 'clearText' in data;
};

export type BeforeSaveState = {
  state: boolean;
  callback: (currentEditorState: SerializedEditorState) => void;
};

export type ArticleConstantsKeys =
  | 'advertStatus'
  | 'priority'
  | 'sourceType'
  | 'templateType'
  | 'formatType'
  | 'status'
  | 'topStatus';

export type ArticleConstants = ConstantsType<ArticleConstantsKeys>;
