// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-list__searchBar___RK0Yx {
  position: sticky;
  top: 0;
  z-index: 999;

  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;

  background-color: #fff;
}

.image-list__pagination___DfO73 {
  flex-basis: 400px;
  flex-shrink: 0;
}

.image-list__searchField___AOmHf {
  margin: 0;
}

.image-list__list___rRyko {
  min-width: 1000px;
  padding: 10px;
  height: 800px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.image-list__item___we4lk {
  overflow: hidden;
}

.image-list__button___I6Ejr {
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/features/gallery/ui/image-list.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,YAAY;;EAEZ,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,aAAa;;EAEb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,4DAA4D;AAC9D;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".searchBar {\n  position: sticky;\n  top: 0;\n  z-index: 999;\n\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  padding: 10px;\n\n  background-color: #fff;\n}\n\n.pagination {\n  flex-basis: 400px;\n  flex-shrink: 0;\n}\n\n.searchField {\n  margin: 0;\n}\n\n.list {\n  min-width: 1000px;\n  padding: 10px;\n  height: 800px;\n  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));\n}\n\n.item {\n  overflow: hidden;\n}\n\n.button {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `image-list__searchBar___RK0Yx`,
	"pagination": `image-list__pagination___DfO73`,
	"searchField": `image-list__searchField___AOmHf`,
	"list": `image-list__list___rRyko`,
	"item": `image-list__item___we4lk`,
	"button": `image-list__button___I6Ejr`
};
export default ___CSS_LOADER_EXPORT___;
