import type { Option } from '@/shared/ui';
import type { UseSearch } from '@/shared/api/types';
import type { ArticleType } from '../api/types';

import { useCallback, useEffect, useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

import { useGetQueryParams, usePagination } from '@/shared/lib/query-params-filter';
import { usePageLoading } from '@/shared/ui';

import {
  useAddArticleMutation,
  useGetArticlesQuery,
  useGetArticlesConstantsQuery,
  useDeleteArticleMutation,
  useUpdateArticleMutation,
  useGetArticleQuery,
  useLazyGetArticleQuery,
  useGetTasksQuery,
  useLazySearchArticlesQuery,
  useLazyGetAnalyticsQuery,
  useGetUniquenessLimitQuery,
  useCheckUniquenessMutation,
  useUpdateTableOfContentsMutation,
} from '../api';

const acceptedFilters = ['category_id', 'status', 'users.id'];
const defaultQueryParams = {
  'fields[article]':
    'slug,id,catchy_title,category_id,main_headline,status,priority,template_type,format_type,reporter_id,published_at,assignee,uniqueness',
  include: 'category,users,assignee',
  order: '-published_at',
};

export const useGetAll = (queryParams?: PaginationQueryParams) => {
  const params = useGetQueryParams(acceptedFilters);

  const { articles, isFetching, total } = useGetArticlesQuery(
    {
      ...queryParams,
      params: {
        ...params,
        ...defaultQueryParams,
        ...queryParams?.params,
      },
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        articles: data?.data,
        total: data?.total,
        isFetching,
      }),
    },
  );

  return { articles, isFetching, total };
};

export const useGetTasks = () => {
  const params = useGetQueryParams(acceptedFilters);
  const { data, isFetching } = useGetTasksQuery(
    {
      params: {
        'fields[article]':
          'id,category_id,main_headline,status,updated_at,template_type,format_type,priority,assignee',
        order: '-id',
        ...params,
      },
    },
    {
      selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
    },
  );

  return { tasks: data ?? [], isFetching };
};

export const useGetTask = (id: UniqueId) => {
  const params = useGetQueryParams(acceptedFilters);
  const { task } = useGetTasksQuery(
    {
      params: {
        'fields[article]':
          'id,category_id,main_headline,status,updated_at,template_type,format_type,priority',
        order: '-id',
        ...params,
      },
    },
    {
      selectFromResult: ({ data }) => ({
        task: data?.find((article) => article.id === id),
      }),
    },
  );

  return task;
};

export const useGetFromList = (id: UniqueId) => {
  const params = useGetQueryParams(acceptedFilters);
  const { page, perPage } = usePagination();

  const { article } = useGetArticlesQuery(
    {
      page,
      per_page: perPage,
      params: {
        ...params,
        ...defaultQueryParams,
      },
    },
    {
      selectFromResult: ({ data }) => ({
        article: data?.data.find((article) => article.id === id),
      }),
    },
  );

  return { article };
};

export const useGetById = (id: UniqueId) => {
  const { data, isLoading } = useGetArticleQuery({ id, params: {} });

  return { article: data, isLoading };
};

export const useGetSearchConsoleAnalytics = () => {
  const [trigger, { isLoading, data }] = useLazyGetAnalyticsQuery();

  const getAnalytics = useCallback(
    (id: UniqueId, start: string, end: string) =>
      trigger(
        {
          id,
          params: {
            start_date: start,
            end_date: end,
          },
        },
        true,
      ),
    [trigger],
  );

  return { getAnalytics, isLoading, data };
};

export const useGetUniquenessResult = () => {
  const [trigger, { isLoading, data }] = useCheckUniquenessMutation();

  const getUniquenessResult = useCallback((id: UniqueId) => trigger({ id }), [trigger]);

  return { uniquenessResult: data, uniquenessLoading: isLoading, getUniquenessResult };
};

export const useGetUniquenessLimit = (id: UniqueId) => {
  const { data } = useGetUniquenessLimitQuery(id, { refetchOnMountOrArgChange: true });

  return { uniquenessCheckLimit: data?.limitRemaining };
};

export const useEditArticle = () => {
  const [trigger, { isLoading }] = useLazyGetArticleQuery();

  const { setIsLoading } = usePageLoading();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const editArticleById = useCallback(
    (id: UniqueId) =>
      trigger(
        {
          id,
          params: {
            'fields[article]': 'id,category_id,main_headline,template_type,format_type,priority',
            include: 'assignee',
          },
        },
        true,
      ),
    [trigger],
  );

  return { editArticleById, isLoading };
};

const defaultEditorParams = { include: 'tags.related,users,image,fbImage' };
export const useGetArticleEditor = (id: UniqueId) => {
  const { data, isLoading } = useGetArticleQuery({
    id,
    params: defaultEditorParams,
  });

  return { article: data, isLoading };
};

export const useGetTags = (id: UniqueId) => {
  const selectPostsForUser = useMemo(() => {
    const emptyArray: Array<Option> = [];

    return createSelector(
      (article: ArticleType | undefined) => article?.tags,
      (tags) =>
        tags
          ?.filter(({ related }) => related !== null)
          ?.map(({ related, relatedType }) => ({
            ...related,
            tagType: relatedType,
          })) ?? emptyArray,
    );
  }, []);

  const { tags } = useGetArticleQuery(
    { id, params: defaultEditorParams },
    {
      selectFromResult: ({ data }) => ({ tags: selectPostsForUser(data) }),
    },
  );

  return tags;
};

export const useGetAuthors = (id: UniqueId) => {
  const selectPostsForUser = useMemo(() => {
    const emptyArray: Array<Option> = [];

    return createSelector(
      (article: ArticleType | undefined) => article?.users,
      (users) => users?.map(({ id, username }) => ({ value: id, name: username })) ?? emptyArray,
    );
  }, []);

  const { users } = useGetArticleQuery(
    { id, params: defaultEditorParams },
    {
      selectFromResult: ({ data }) => ({ users: selectPostsForUser(data) }),
    },
  );

  return users;
};

export const useSearch: UseSearch<ArticleType> = () => {
  const params = useGetQueryParams(acceptedFilters);

  const [trigger, { articles, total, isFetching }] = useLazySearchArticlesQuery({
    selectFromResult: ({ data, isFetching }) => ({
      articles: data?.data,
      total: data?.total ?? 0,
      isFetching,
    }),
  });

  const handleSearch = useCallback(
    (search: string, queryParams?: PaginationQueryParams) =>
      trigger({
        search_field: 'main_headline',
        search,
        params: {
          ...queryParams,
          params: {
            ...params,
            ...defaultQueryParams,
          },
        },
      }),
    [params, trigger],
  );

  return [handleSearch, articles, total, isFetching];
};

export const useGetConstants = () => {
  const { data, isFetching } = useGetArticlesConstantsQuery(undefined, {
    selectFromResult: ({ data, isFetching }) => ({ data, isFetching }),
  });

  return { constants: data, isFetching };
};

export const useCreate = () => {
  const [create] = useAddArticleMutation();

  return create;
};

export const useDelete = () => {
  const [deleteArticle] = useDeleteArticleMutation();

  return deleteArticle;
};

export const useUpdate = () => {
  const [update] = useUpdateArticleMutation();

  return update;
};

export const useUpdateTableOfContents = () => {
  const [updateTableOfContents] = useUpdateTableOfContentsMutation();

  return updateTableOfContents;
};
