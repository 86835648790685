import { createSlice } from '@reduxjs/toolkit';

import type { State } from './types';

import { edit } from './reducers';
import { REDUCER_PATH } from './constants';

const initialState: State = {
  editing: null,
};

const slice = createSlice({
  name: REDUCER_PATH,
  initialState,
  reducers: {
    edit,
  },
});

export const actions = slice.actions;

export const reducer = { [REDUCER_PATH]: slice.reducer };
