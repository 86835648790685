import { z } from 'zod';
import NiceModal from '@ebay/nice-modal-react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { RHFForm, RHFInput } from '@/shared/lib/react-hook-form';

import { Button, Modal } from '../../ui';
import { insertEmbedPayload } from '../lib';

import styles from './index.css';

export const EmbedFormSchema = z.object({
  link: z
    .string({
      required_error: 'Link is required',
    })
    .url(),
});

export const EmbedModal = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <Modal id="embed-modal">
      <RHFForm
        onSubmit={async ({ link }) => {
          insertEmbedPayload(link, editor);

          NiceModal.hide('embed-modal');
        }}
        schema={EmbedFormSchema}
      >
        {() => (
          <div className={styles.embedModal}>
            <RHFInput
              name="link"
              type="text"
              label="Enter embed link"
              placeholder="Paste embed link"
              size="small"
              style={{ margin: 0, width: '300px' }}
            />
            <Button type="submit" label="Save" color="primary" />
          </div>
        )}
      </RHFForm>
    </Modal>
  );
};
