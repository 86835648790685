// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insert-image__form___TmR0m {
  display: flex;
  gap: 20px;
  padding: 10px;
  min-width: 0;
  max-width: 1000px;
}

.insert-image__image___rs3nV {
  flex-grow: 0;
  flex-shrink: 0;

  display: block;
  width: 100%;
  max-width: 500px;
  height: auto;
}

.insert-image__inputs___PadkE {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/features/gallery/ui/insert-image.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,cAAc;;EAEd,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".form {\n  display: flex;\n  gap: 20px;\n  padding: 10px;\n  min-width: 0;\n  max-width: 1000px;\n}\n\n.image {\n  flex-grow: 0;\n  flex-shrink: 0;\n\n  display: block;\n  width: 100%;\n  max-width: 500px;\n  height: auto;\n}\n\n.inputs {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `insert-image__form___TmR0m`,
	"image": `insert-image__image___rs3nV`,
	"inputs": `insert-image__inputs___PadkE`
};
export default ___CSS_LOADER_EXPORT___;
