import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

import { API_DOMAIN, API_PORT } from '@/shared/config';
import { jsonToFormData } from '@/shared/lib/json';
import { isFile, isFileList } from '@/shared/lib/type-guards';

import { getToken } from './access-token';

const UPLOAD_ENDPOINTS = ['addImage', 'uploadStopWords', 'updateAuthor'];

export const CACHE_KEYS = {
  article: 'Articles',
  category: 'Categories',
  image: 'Images',
  callToAction: 'CallToActions',
  tag: 'Tags',
  stopWord: 'StopWords',
  user: 'Users',
  role: 'Roles',
};

const baseQuery = fetchBaseQuery({
  baseUrl: `https://${API_DOMAIN}:${API_PORT}/v1`,
  prepareHeaders: (headers, { endpoint }) => {
    const token = getToken();

    if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
    }

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const api = createApi({
  baseQuery: async (args, api, extraOptions) => {
    if (args.body) {
      const bodyHasFile = Object.values(args.body).some(
        (value) => isFile(value) || isFileList(value),
      );

      if (bodyHasFile) {
        args.body = jsonToFormData(args.body);
      }

      if (!(args.body instanceof FormData)) {
        args.body = snakecaseKeys(args.body);
      }
    }

    const result = await baseQuery(args, api, extraOptions);

    result.data = camelcaseKeys((result.data as Record<string, unknown>) ?? {}, { deep: true });

    return result;
  },
  endpoints: () => ({}),
  tagTypes: Object.values(CACHE_KEYS),
});
