type Props = {
  id: string;
};

export const YouTubeComponent = ({ id }: Props) => (
  <iframe
    width="560"
    height="315"
    src={`https://www.youtube.com/embed/${id}`}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);
