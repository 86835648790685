import type { FC } from 'react';
import type { UserType } from '@/entities/user/api/types';

import NiceModal from '@ebay/nice-modal-react';
import { IconButton, Tooltip } from '@mui/material';
import Settings from '@mui/icons-material/Settings';

import { HasAccess } from '@/entities/session';
import { userModel } from '@/entities/user';
import { useAppDispatch } from '@/shared/lib/rtk';
import { USER_UPDATE } from '@/shared/config/permissions';

import { MODAL_ID } from '../config';

type Props = {
  entity: UserType;
};

export const EditUserButton: FC<Props> = ({ entity }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(userModel.actions.edit(entity));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[USER_UPDATE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title={'Edit'}>
          <Settings fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
