import type { InsertImagePayload } from '../nodes';

import { useEffect } from 'react';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { $createImageNode, ImageNode } from '../nodes';
import { INSERT_IMAGE_COMMAND } from '../commands';

export const ImagePlugin = ({
  captionsEnabled,
}: {
  captionsEnabled?: boolean;
}): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const selection = $getSelection();

          if (!$isRangeSelection(selection)) {
            return true;
          }

          const node = $createImageNode(payload);
          $insertNodeToNearestRoot(node);
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    );
  }, [captionsEnabled, editor]);

  return null;
};
