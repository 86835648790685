import type { ReactNode } from 'react';

export type TabPanelProps = {
  tabLabel: string;
  tabIndex?: number;
  value?: number;
  className?: string;
  children?: ReactNode;
};

export const TabPanel = ({ children, value, tabIndex, className = '' }: TabPanelProps) => (
  <div className={className} hidden={value !== tabIndex}>
    {children}
  </div>
);
