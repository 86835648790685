import type { FC } from 'react';
import type { CallToActionType } from '@/entities/call-to-action/types';

import { IconButton, Tooltip } from '@mui/material';
import NiceModal from '@ebay/nice-modal-react';
import SettingsIcon from '@mui/icons-material/Settings';

import { HasAccess } from '@/entities/session';
import { callToActionModel } from '@/entities/call-to-action';

import { useAppDispatch } from '@/shared/lib/rtk';
import { CTA_UPDATE } from '@/shared/config/permissions';

import { MODAL_ID } from '../config';

type Props = {
  entity: CallToActionType;
};

export const EditCallToActionButton: FC<Props> = ({ entity }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(callToActionModel.actions.edit(entity));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[CTA_UPDATE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title={'Edit call to action'}>
          <SettingsIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
