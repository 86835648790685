const readFile = (file: File | Blob): Promise<FileReader['result']> => {
  return new Promise((resolve) => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

const createImage = (src: unknown): HTMLImageElement | PromiseLike<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image: HTMLImageElement = new Image();
    if (typeof src === 'string') {
      image.src = src;
    }
    image.onload = () => resolve(image);
  });
};

export const readFileAsImage = (file: Blob | File) => {
  return new Promise((resolve) => resolve(readFile(file))).then(createImage);
};
