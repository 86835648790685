import CircularProgress from '@mui/material/CircularProgress';

import { usePageLoading } from './context';

import styles from './page-loader.css';

export const PageLoader = () => {
  const { isLoading } = usePageLoading();

  if (!isLoading) {
    return null;
  }

  return (
    <>
      <div className={styles.isLoading}>
        <CircularProgress />
      </div>
    </>
  );
};
