import type { ElementFormatType, TextFormatType } from 'lexical';
import type { HeadingTagType } from '@lexical/rich-text';
import type { ImagePayload } from '../nodes';

import { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  ElementNode,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from 'lexical';
import NiceModal from '@ebay/nice-modal-react';

import { Button, ButtonGroup, ToggleButton, ToggleButtonGroup } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';

import { $createQuoteNode, $isQuoteNode } from '@lexical/rich-text';

import { $isAtNodeEnd, $setBlocksType } from '@lexical/selection';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  $isListNode,
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';

import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '../nodes/link-node';
import { $createHeadingNode, $isHeadingNode } from '../nodes/heading-node';

import { INSERT_IMAGE_COMMAND, TRY_INSERT_LINK_COMMAND } from '../commands';

import { InsertTableModal } from '../ui/table-modal';

import styles from './toolbar.css';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

export function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export type ToolbarProps = {
  children?: ReactElement<InsertImageProps>;
  bodyVersion?: boolean;
};

export const Toolbar = ({ children, bodyVersion }: ToolbarProps) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isLink, setIsLink] = useState(false);
  const [elementFormat, setElementFormat] = useState('');
  const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>('paragraph');

  const [formats, setFormats] = useState({
    bold: false,
    italic: false,
    underline: false,
  });

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });
      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      if ($isElementNode(element)) {
        setElementFormat(element.getFormatType());
      } else {
        setElementFormat('');
      }

      // Update text format

      setFormats({
        bold: selection.hasFormat('bold'),
        italic: selection.hasFormat('italic'),
        underline: selection.hasFormat('underline'),
      });

      // Update links

      const node = getSelectedNode(selection);
      const maybeLinkNode = $getNearestNodeOfType(node, LinkNode);
      setIsLink($isLinkNode(maybeLinkNode));

      // Update list

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();

          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TRY_INSERT_LINK_COMMAND, undefined);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [activeEditor, editor, updateToolbar]);

  const handleQuote = () => {
    activeEditor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        if ($isQuoteNode(selection?.getNodes()[0].getParent())) {
          $setBlocksType(selection, () => $createParagraphNode());

          return;
        }

        $setBlocksType(selection, () => $createQuoteNode());
      }
    });
  };

  const handleHeadings = (headingSize: HeadingTagType) => () => {
    activeEditor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        if ($isHeadingNode(selection?.getNodes()[0].getParent())) {
          $setBlocksType(selection, () => $createParagraphNode());

          return;
        }

        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      }
    });
  };

  const handleTextFormatting = (format: TextFormatType) => () => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
  };

  const handleAlignment = (align: ElementFormatType) => () => {
    activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, align);
  };

  const buttonGroupFormats = Object.entries(formats)
    .filter(([_, value]) => value)
    .map(([key]) => key);

  return (
    <div className={styles.toolbar}>
      <ToggleButtonGroup
        className={styles.buttonGroup}
        value={buttonGroupFormats}
        aria-label="text formatting"
      >
        <ToggleButton value="bold" aria-label="bold" onClick={handleTextFormatting('bold')}>
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton value="italic" aria-label="italic" onClick={handleTextFormatting('italic')}>
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          value="underline"
          aria-label="underlined"
          onClick={handleTextFormatting('underline')}
        >
          <FormatUnderlinedIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        className={styles.buttonGroup}
        value={blockType}
        aria-label="list formatting"
        exclusive
      >
        <ToggleButton value="quote" aria-label="quote" onClick={handleQuote}>
          <FormatQuoteIcon />
        </ToggleButton>
        <ToggleButton value="bullet" aria-label="bullet" onClick={formatBulletList}>
          <FormatListBulletedIcon />
        </ToggleButton>
        <ToggleButton value="number" aria-label="number" onClick={formatNumberedList}>
          <FormatListNumberedIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButton selected={isLink} value="link" onClick={insertLink}>
        <InsertLinkIcon />
      </ToggleButton>

      {!bodyVersion && (
        <>
          <ToggleButtonGroup
            className={styles.buttonGroup}
            value={elementFormat}
            aria-label="text alignment"
            exclusive
          >
            <ToggleButton value="left" aria-label="quote" onClick={handleAlignment('left')}>
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center" aria-label="bullet" onClick={handleAlignment('center')}>
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right" aria-label="number" onClick={handleAlignment('right')}>
              <FormatAlignRightIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            className={styles.buttonGroup}
            value={blockType}
            aria-label="text heading"
            exclusive
          >
            <ToggleButton value="h2" aria-label="heading 2" onClick={handleHeadings('h2')}>
              <b>H2</b>
            </ToggleButton>
            <ToggleButton value="h3" aria-label="heading 3" onClick={handleHeadings('h3')}>
              <b>H3</b>
            </ToggleButton>
            <ToggleButton value="h4" aria-label="heading 4" onClick={handleHeadings('h4')}>
              <b>H4</b>
            </ToggleButton>
          </ToggleButtonGroup>

          <ButtonGroup className={styles.buttonGroup} variant="outlined" color={'inherit'}>
            <Button className={styles.button} onClick={() => NiceModal.show('embed-modal')}>
              <IntegrationInstructionsIcon />
            </Button>

            {children}

            <Button className={styles.button} onClick={() => NiceModal.show('table-modal')}>
              <CalendarViewMonthIcon />
            </Button>
          </ButtonGroup>

          <InsertTableModal />
        </>
      )}
    </div>
  );
};

type RenderInsertImageProps = (image: ImagePayload) => void;

type InsertImageProps = {
  children: (props: RenderInsertImageProps) => ReactNode;
};

const InsertImage = ({ children }: InsertImageProps) => {
  const [editor] = useLexicalComposerContext();

  const handleInsertImage = useCallback(
    (image: ImagePayload) => {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, image);
    },
    [editor],
  );
  return <Button className={styles.button}>{children(handleInsertImage)}</Button>;
};

Toolbar.InsertImage = InsertImage;
