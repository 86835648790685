import type { FC } from 'react';

import NiceModal from '@ebay/nice-modal-react';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { useAppDispatch } from '@/shared/lib/rtk';
import { galleryModel } from '@/entities/gallery';

import { MODAL_ID } from '../config';

type Props = {
  id: UniqueId;
};

export const EditImageButton: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { image } = galleryModel.useGetFromList(id);

  const handleClick = () => {
    dispatch(galleryModel.actions.edit(image ?? null));
    NiceModal.show(MODAL_ID);
  };

  return (
    <Tooltip title="Edit">
      <IconButton onClick={handleClick}>
        <EditIcon sx={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};
