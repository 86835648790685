import { z } from 'zod';

import { danger_symbols, repeating_symbols, slug as slug_reg } from './validation-regexp';

export const slug = z
  .string({ required_error: 'Slug title is required' })
  .refine((value) => slug_reg.test(value), {
    message: `Allowed: Latin, numbers, hyphens, underscores. Must start with a letter and end with a letter or a number.`,
  })
  .refine((value) => !repeating_symbols.test(value), {
    message: `Do not use repeating symbols.`,
  })
  .refine((value) => !danger_symbols.test(value), {
    message: `Do not use danger symbols: square, angle brackets and backslash – [ ] < > \\.`,
  });
