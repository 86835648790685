import type { FC } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { confirmModal } from '@/shared/ui/modal';

import { useDelete } from '../model';

export const DeleteImageButton: FC<EntityWithId> = ({ id }) => {
  const deleteTask = useDelete();

  const handleClick = () => {
    confirmModal
      .show({
        title: 'This will delete the image.',
      })
      .then(handleDelete)
      .catch(handleClose);
  };

  const handleDelete = async () => {
    await deleteTask(id);
    await confirmModal.hide();
  };

  const handleClose = async () => {
    await confirmModal.hide();
  };

  return (
    <Tooltip title="Delete">
      <IconButton onClick={handleClick}>
        <ClearIcon sx={{ color: 'white' }} />
      </IconButton>
    </Tooltip>
  );
};
