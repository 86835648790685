import { useCallback } from 'react';

import { useLazyGetProfileQuery } from '../api';

export const useGetProfile = () => {
  const [getProfile, { isLoading }] = useLazyGetProfileQuery({
    // Используется для оптимизации отрисовки. Если доставать весь объект,
    // то происходит перерисовка после каждого запроса, так как в сторе обновляется requestId.
    selectFromResult: ({ data, isLoading }) => ({ data, isLoading }),
  });

  const handleGetProfile = useCallback(() => getProfile(''), [getProfile]);

  return { getProfile: handleGetProfile, isLoading };
};
