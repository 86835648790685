import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';

import { $applyNodeReplacement, $getNodeByKey, DecoratorNode, LexicalNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { InsertImage } from '@/features/gallery';

import { DecoratorBlock } from '../ui/decorator-block';

export type ImagePayload = {
  src: string;
  alt: string;
  id: UniqueId;
  label: string | null;
  key?: NodeKey;
};

export type InsertImagePayload = Readonly<ImagePayload>;

export type SerializedImageNode = Spread<
  {
    src: string;
    alt: string;
    id: UniqueId;
    label: string | null;
    type: typeof NODE_TYPE;
    version: 1;
  },
  SerializedLexicalNode
>;

const NODE_TYPE = 'image';

const convertImageElement = (domNode: Node): null | DOMConversionOutput => {
  if (domNode instanceof HTMLImageElement) {
    const { alt, src, dataset } = domNode;

    if (dataset.id !== undefined) {
      const node = $createImageNode({ alt, src, id: +dataset.id, label: '' });
      return { node };
    }

    return null;
  }

  return null;
};

export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;
  __alt: string;
  __id: UniqueId;
  __label: string | null;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__alt, node.__id, node.__label, node.__key);
  }

  exportJSON(): SerializedImageNode {
    return {
      src: this.getSrc(),
      alt: this.getAlt(),
      id: this.getId(),
      label: this.getLabel(),
      type: NODE_TYPE,
      version: 1,
    };
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { src, alt, id, label } = serializedNode;

    return $createImageNode({
      src,
      alt,
      id,
      label,
    });
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img');

    element.src = this.__src;
    element.alt = this.__alt;
    element.dataset.id = this.__id.toString();

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  constructor(src: string, alt: string, id: UniqueId, label: string | null, key?: NodeKey) {
    super(key);
    this.__src = src;
    this.__alt = alt;
    this.__id = id;
    this.__label = label;
  }

  getSrc(): string {
    const self = this.getLatest();
    return self.__src;
  }

  getAlt(): string {
    const self = this.getLatest();
    return self.__alt;
  }

  getId(): number {
    const self = this.getLatest();

    return self.__id;
  }

  getLabel(): string | null {
    const self = this.getLatest();

    return self.__label;
  }

  isInline(): false {
    return false;
  }

  update(payload: ImagePayload): void {
    const writable = this.getWritable();
    const { alt, label, id, src } = payload;

    alt !== undefined && (writable.__alt = alt);
    label !== undefined && (writable.__label = label);
    id !== undefined && (writable.__id = id);
    src !== undefined && (writable.__src = src);
  }

  updateDOM(): false {
    return false;
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  decorate(): JSX.Element {
    return <ImageComponent image={this} />;
  }
}

export function $createImageNode({ alt, src, id, label }: ImagePayload): ImageNode {
  return $applyNodeReplacement(new ImageNode(src, alt, id, label));
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode;
}

const ImageComponent = ({ image }: { image: ImageNode }) => {
  const [editor] = useLexicalComposerContext();

  const payload = {
    alt: image.__alt,
    label: image.__label,
    src: image.__src,
    id: image.__id,
  };

  const handleUpdate = (payload: ImagePayload) => {
    editor.update(() => {
      editor.setEditable(true);
      image.update(payload);
      editor.setEditable(false);
    });
  };

  const handleOpen = () => {
    editor.update(() => {
      const node = $getNodeByKey(image.__key);
      node?.selectPrevious();
      editor.setEditable(false);
      editor.blur();
    });
  };

  const handleClose = () => {
    editor.setEditable(true);
    editor.focus();
  };

  return (
    <InsertImage
      onOpen={handleOpen}
      onClose={handleClose}
      name={`${image.__id}`}
      onInsert={handleUpdate}
      selectedImage={payload}
    >
      {({ open }) => (
        <DecoratorBlock nodeKey={image.getKey()} onEdit={open}>
          <img alt={image.__alt} src={image.__src} data-id={image.__id} />
          <p
            style={{
              color: '#333',
              margin: 0,
              padding: '1rem 0',
              borderBottom: '1px solid #ccc',
            }}
          >
            {image.__label}
          </p>
        </DecoratorBlock>
      )}
    </InsertImage>
  );
};
