import { z } from 'zod';

import { title, seoDescription, lengthRefine } from '@/shared/validation';
import { tag_title, tag_title_symbols_at_end } from '@/shared/validation/validation-regexp';

const tagHeadline = lengthRefine(title('Headline'), 110, 55)
  .refine((value) => tag_title_symbols_at_end.test(value), {
    message: `Must end with a letter, digits, period, plus sign, question or exclamation mark, closing quote or bracket.`,
  })
  .refine((value) => tag_title.test(value), {
    message: `Allowed: Latin, Cyrillic, space, hyphens, plus sign.`,
  })
  .nullish()
  .or(z.literal(''));

export const schema = z.object({
  catchyTitle: lengthRefine(title('Catchy title'), 255),
  mainHeadline: tagHeadline,
  description: lengthRefine(seoDescription, 300),
});

export type FieldsValues = z.infer<typeof schema>;
