import type { FC, ReactNode } from 'react';

import { useSelector } from 'react-redux';

import { sessionModel } from '@/entities/session';

type Props = {
  children?: ReactNode;
  permission: string;
};

export const PrivateRoute: FC<Props> = ({ children, permission }) => {
  const user = useSelector(sessionModel.selectUser);

  if (user && !user.permissions.includes(permission)) {
    return <p>You are not authorized to view this page</p>;
  }

  return <>{children}</>;
};
