import { Autocomplete as MuiAutocomplete } from '@mui/material';

import { Input } from './input';
import { useEffect, useState } from 'react';
import { Noop, RefCallBack } from 'react-hook-form/dist/types';
import { FieldError } from 'react-hook-form/dist/types/errors';

export type Option = {
  value: string | number;
  name: string;
};

export type AutocompleteOnChange = (value: string | number | Array<string | number> | null) => void;

export type BaseProps = {
  fieldRef?: RefCallBack;
  error?: FieldError;
  placeholder?: string;
  onBlur?: Noop;
  onChange: AutocompleteOnChange;
  multiple?: boolean;
  disableClearable?: boolean;
  value: Option | Array<Option> | null;
  label?: string;
};

type Async = {
  onInputChange: (searchPhrase: string) => void;
  options: Array<Option>;
  loading: boolean;
};

type Sync = {
  options: Array<Option>;
};
export type SyncAutocompleteProps = BaseProps & Sync;
export type AsyncAutocompleteProps = BaseProps & Async;

export const AsyncAutocomplete = ({
  onInputChange,
  onChange,
  options,
  multiple,
  fieldRef,
  onBlur,
  placeholder,
  disableClearable,
  loading = false,
  error,
  value,
  label,
}: AsyncAutocompleteProps) => {
  const [currentValue, setCurrentValue] = useState<Array<Option> | Option | null>(
    multiple ? [] : null,
  );

  const [localOptions, setLocalOptions] = useState<Array<Option>>([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  return (
    <MuiAutocomplete
      value={currentValue}
      ref={fieldRef}
      onBlur={onBlur}
      loading={loading}
      multiple={multiple}
      popupIcon={null}
      disableClearable={disableClearable}
      open={isOpen}
      options={localOptions}
      isOptionEqualToValue={(option, value) => {
        if (value instanceof Array) {
          return value.some((value) => option.value === value.value);
        }

        return option.value === value.value;
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <Input error={error} label={label} placeholder={placeholder} {...params} />
      )}
      getOptionLabel={(currentOption) => currentOption.name}
      onInputChange={async (event, searchPhrase, reason) => {
        if (searchPhrase.length >= 2 && reason === 'input') {
          setIsOpen(true);
          onInputChange && (await onInputChange(searchPhrase));
        } else {
          setIsOpen(false);
        }
      }}
      filterOptions={(options) => options}
      onChange={(event, value) => {
        if (value instanceof Array) {
          onChange(value.map(({ value }) => value));
        } else if (value === null) {
          onChange(value);
        } else {
          value && onChange(value.value);
          setIsOpen(false);
        }

        if (localOptions?.length > 0) {
          setLocalOptions([]);
        }

        setCurrentValue(value);
      }}
    />
  );
};

export const SyncAutocomplete = ({
  options,
  onChange,
  multiple,
  fieldRef,
  onBlur,
  placeholder,
  disableClearable,
  error,
  value,
  label,
}: SyncAutocompleteProps) => {
  return (
    <MuiAutocomplete
      value={value}
      ref={fieldRef}
      onBlur={onBlur}
      multiple={multiple}
      disableClearable={disableClearable}
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (value instanceof Array) {
          return value.some((value) => option.value === value.value);
        }

        return option.value === value.value;
      }}
      renderInput={(params) => (
        <Input error={error} placeholder={placeholder} label={label} {...params} />
      )}
      getOptionLabel={(currentOption) => currentOption.name}
      onChange={(event, value) => {
        if (value instanceof Array) {
          onChange(value.map(({ value }) => value));
        } else {
          onChange(value?.value ?? null);
        }
      }}
    />
  );
};
