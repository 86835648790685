import type { SubmitHandler } from 'react-hook-form';
import type { FC } from 'react';

import {
  RHFButtons,
  RHFForm,
  RHFInput,
  RHFPasswordInput,
  SUBMIT,
} from '@/shared/lib/react-hook-form';
import { FormSchema } from '@/features/auth/model';

type LoginFormProps = {
  onSubmit: SubmitHandler<any>;
};

export const LoginForm: FC<LoginFormProps> = ({ onSubmit }) => {
  return (
    <RHFForm onSubmit={onSubmit} schema={FormSchema}>
      {({ loading }) => (
        <>
          <RHFInput name="email" type="email" label="Email" />
          <RHFPasswordInput name="password" label="Password" />
          <RHFButtons rhfButtonType={SUBMIT} label="Sign In" loading={loading} />
        </>
      )}
    </RHFForm>
  );
};
