import type { CallToActionType, CallToActionConstantsKeys, CallToActionIncludes } from './types';

import { api } from '@/shared/api';

const RESOURCE_PATH = 'call-to-actions';

const callToActionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCallToActions: builder.query<
      CallToActionType[],
      { params: QueryParams; includes: CallToActionIncludes[] }
    >({
      query: ({ params, includes }) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'GET',
        params: { ...params, include: includes.join() },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CallToActions', id } as const)),
              { type: 'CallToActions', id: 'LIST' },
            ]
          : [{ type: 'CallToActions', id: 'LIST' }],
    }),

    getCallToAction: builder.query<CallToActionType, UniqueId>({
      query: (id) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'CallToActions', id }],
    }),

    getCallToActionConstants: builder.query<ConstantsType<CallToActionConstantsKeys>, void>({
      query: () => ({
        url: `/${RESOURCE_PATH}/constants`,
        method: 'GET',
      }),
    }),

    addCallToAction: builder.mutation({
      query: (body) => ({
        url: `/${RESOURCE_PATH}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'CallToActions', id: 'LIST' }],
    }),

    updateCallToAction: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${RESOURCE_PATH}/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'CallToActions', id: 'LIST' }],
    }),

    deleteCallToAction: builder.mutation({
      query: (id) => {
        return {
          url: `/${RESOURCE_PATH}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'CallToActions', id }],
    }),
  }),
});

export const {
  useGetCallToActionsQuery,
  useGetCallToActionQuery,
  useGetCallToActionConstantsQuery,
  useAddCallToActionMutation,
  useDeleteCallToActionMutation,
  useUpdateCallToActionMutation,
} = callToActionsApi;
