// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__formContainer___r8mi5 {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__formIcon___Hy4oB {
    margin: 8px;
    background-color: mediumpurple;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,8BAA8B;AAClC","sourcesContent":[".formContainer {\n    margin-top: 64px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.formIcon {\n    margin: 8px;\n    background-color: mediumpurple;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `login__formContainer___r8mi5`,
	"formIcon": `login__formIcon___Hy4oB`
};
export default ___CSS_LOADER_EXPORT___;
