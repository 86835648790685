import { useEffect } from 'react';
import { $createRangeSelection, $getRoot, $getSelection, LexicalNode, TextNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { clearHighlight } from '@/shared/editor/lib';

const stringAsObjectKeys = (str: string) => {
  const obj: { [key: string]: true } = {};

  for (let i = 0; i < str.length; i++) {
    obj[str[i]] = true;
  }

  return obj;
};

const allowCharactersMap = stringAsObjectKeys(
  '0123456789ab cdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZёЁіІїЇєЄàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ- ',
);

const separatorCharactersMap = stringAsObjectKeys('- ');

export const UniquenessPlugin = ({
  plagiatState = [],
}: {
  plagiatState?: string[];
}): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Convert the array to an object for performance
    const highlightWordsMap: { [key: number]: true } = {};
    for (let i = 0; i < plagiatState.length; i++) {
      highlightWordsMap[+plagiatState[i]] = true;
    }

    const highlightWord = (textNode: TextNode, startOffset: number, endOffset: number) => {
      const selection = $createRangeSelection();
      selection.setTextNodeRange(textNode, startOffset, textNode, endOffset);
      selection.formatText('highlight');
    };

    editor.update(() => {
      const textNodes = $getRoot().getAllTextNodes();
      const selection = $getSelection();

      // Clear node from 'highlight' before processing
      clearHighlight(textNodes);

      // Clear cursor selection from 'highlight' format
      if (selection && 'hasFormat' in selection) {
        if (selection.hasFormat('highlight')) {
          selection.toggleFormat('highlight');
        }
      }

      if (!plagiatState || !plagiatState.length) {
        return;
      }

      const updatedTextNodes = $getRoot().getAllTextNodes();
      let lastCharacter = ' ';
      let wordNumber = -1;

      const handleNode = (node: LexicalNode | null | undefined) => {
        // If there is no node, we do nothing.
        if (!node) {
          return;
        }

        // We work with text nodes only
        if (!(node instanceof TextNode)) {
          handleNode(node.getNextSibling());
          return;
        }

        // If content is manipulated, the modified text will be inside the node
        const textContent = node.getTextContent();

        for (let startOffset = 0; startOffset < textContent.length; startOffset++) {
          // We skip special characters, like "we don't see them."
          if (!allowCharactersMap[textContent[startOffset]]) {
            // Do not memorize the last character of the special characters
            continue;
          }

          // If we see "separation" characters, we also skip them
          if (separatorCharactersMap[textContent[startOffset]]) {
            lastCharacter = textContent[startOffset];
            continue;
          }

          // Check if the previous character is a space in this node, so it is the beginning
          // of a new word. This logic does not work with spaces between nodes
          if (separatorCharactersMap[lastCharacter]) {
            wordNumber++;
          }

          // Now we need to find the end of the word
          let endOffset = startOffset + 1;
          for (; endOffset < textContent.length; endOffset++) {
            if (
              !allowCharactersMap[textContent[endOffset]] ||
              separatorCharactersMap[textContent[endOffset]]
            ) {
              break;
            }
          }

          lastCharacter = textContent[endOffset];

          // We save the last offset of the word so that we don't have to go
          // through the word again
          if (!highlightWordsMap[wordNumber]) {
            startOffset = endOffset;
            continue;
          }

          // Checking that this word needs to be highlighted
          highlightWord(node, startOffset, endOffset);

          // If there were characters at the beginning, for example - empty space,
          // then we need to jump x2 nodes
          if (startOffset > 0) {
            node = node.getNextSibling();
          }

          break;
        }

        handleNode(node?.getNextSibling());
      };

      for (const textNode of updatedTextNodes) {
        // It works only with those nodes that do not have a previous node,
        // because this logic is executed internally
        if (!textNode.getPreviousSibling()) {
          handleNode(textNode);

          // Between nodes (paragraphs), we have a space separator
          lastCharacter = ' ';
        }
      }
    });
  }, [plagiatState]);

  return null;
};
