import type { FC } from 'react';
import type { UserType } from '@/entities/user/api/types';

import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { HasAccess } from '@/entities/session';

import { confirmModal } from '@/shared/ui/modal';
import { USER_DELETE } from '@/shared/config/permissions';

import { useDelete } from '../model';

type Props = {
  entity: UserType;
};

export const DeleteUserButton: FC<Props> = ({ entity }) => {
  const deleteUser = useDelete();

  const handleClick = async () => {
    confirmModal
      .show({
        title: 'This will delete selected user.',
      })
      .then(handleDelete)
      .catch(handleClose);
  };

  const handleDelete = async () => {
    await deleteUser(entity.id);
    await confirmModal.hide();
  };

  const handleClose = async () => {
    await confirmModal.hide();
  };

  return (
    <HasAccess permissions={[USER_DELETE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title={'Delete'}>
          <DeleteIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
};
