import type { Area } from 'react-easy-crop/types';
import type { ImageType } from '@/entities/gallery/types';

import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Modal } from '@/shared/ui/modal';
import { Button } from '@/shared/ui/button';
import { ImageCropper } from '@/shared/ui/image-croppper';

import { galleryModel, getCropResizeByWidth } from '@/entities/gallery';
import { CROP_IMAGE_MODAL, SELECT_IMAGE_MODAL } from '../config';

import { ImageList } from './image-list';

type SelectImageProps = {
  onSelect: (image: ImageType) => void;
  selectedImage: ImageType;
  name: string;
};

export const SelectImage = ({ onSelect, selectedImage, name }: SelectImageProps) => {
  const [image, setImage] = useState<ImageType | null>(null);
  const [croppedUrl, setCropedUrl] = useState<string | null>(null);
  const cropImage = galleryModel.useCrop();

  const handleClick = () => {
    NiceModal.show(`${SELECT_IMAGE_MODAL}-${name}`);
  };

  const handleSelect = (image: ImageType) => {
    NiceModal.hide(`${SELECT_IMAGE_MODAL}-${name}`);

    if (image && image.crop === null) {
      setImage(image);
      NiceModal.show(`${CROP_IMAGE_MODAL}-${name}`);
    } else {
      onSelect(image);
      setCropedUrl(null);
    }
  };

  const handleCrop = (imageUrl: string, crop: Area) => {
    setCropedUrl(imageUrl);
    NiceModal.hide(`${CROP_IMAGE_MODAL}-${name}`);

    if (image) {
      cropImage({ id: image.id, ...crop });
      onSelect(image);
      setImage(null);
    }
  };

  const previewImage =
    croppedUrl ??
    (image && getCropResizeByWidth(image, 1200)?.url) ??
    (selectedImage && getCropResizeByWidth(selectedImage, 1200)?.url) ??
    selectedImage?.previewUrl;

  return (
    <div
      style={{
        backgroundImage: `url(${previewImage})`,
        backgroundSize: 'cover',
        aspectRatio: '16/9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        label="Select image"
        onClick={handleClick}
        variant="outlined"
        style={{ backgroundColor: '#fff' }}
      />
      <Modal id={`${SELECT_IMAGE_MODAL}-${name}`}>
        <ImageList onSelect={handleSelect} showCropped />
      </Modal>

      <Modal id={`${CROP_IMAGE_MODAL}-${name}`}>
        <ImageCropper onCrop={handleCrop} imageSrc={image?.originalUrl ?? ''} />
      </Modal>
    </div>
  );
};
