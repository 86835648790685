// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout__container___W1GVS {
  display: flex;
  height: 100%;
}

.layout__main___crCIG {
  flex-grow: 1;
  margin-top: 64px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/layout/layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  height: 100%;\n}\n\n.main {\n  flex-grow: 1;\n  margin-top: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `layout__container___W1GVS`,
	"main": `layout__main___crCIG`
};
export default ___CSS_LOADER_EXPORT___;
