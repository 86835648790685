import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type { StopWordType } from '@/entities/stop-word/types';

import { DataGrid } from '@/shared/ui';

import { DeleteStopWord } from './delete-stop-word';
import { EditStopWord } from './edit-stop-word';

const columns: NarrowGridColumns<StopWordType> = [
  {
    field: 'name',
    headerName: 'Name',
    width: 500,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'isArticle',
    headerName: 'For Article',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'isTag',
    headerName: 'For Tag',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
];

type Props = ItemsListProps<StopWordType>;

export const StopWordList = ({ items, total, isFetching }: Props) => {
  return (
    <DataGrid
      columns={columns}
      loading={isFetching}
      rows={items ?? []}
      rowsTotalCount={total}
      actions={(entity) => (
        <>
          <DeleteStopWord entity={entity} />
          <EditStopWord entity={entity} />
        </>
      )}
    />
  );
};
