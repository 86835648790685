// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-console__select___UTCq6 {
  margin: 0;
}

.search-console__controls___nDj0i {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/features/article/ui/search-console.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".select {\n  margin: 0;\n}\n\n.controls {\n  display: flex;\n  gap: 10px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `search-console__select___UTCq6`,
	"controls": `search-console__controls___nDj0i`
};
export default ___CSS_LOADER_EXPORT___;
