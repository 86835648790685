// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.author-form__form___zUfAG {
  display: flex;
}

.author-form__title___YAb0k {
  margin-bottom: 1.5rem;
}

.author-form__button___SYHip {
  margin: 1.5rem 0;
}

.author-form__imageWrapper___F3ikp {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
  gap: 1.5rem;
  margin: 2rem;
}

.author-form__inputsWrapper___XKot4 {
  flex: 1.5;
  margin: 2rem 5rem 2rem 2rem;
}

.author-form__button___SYHip {
  width: 200px
}
`, "",{"version":3,"sources":["webpack://./src/features/user/ui/author-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE;AACF","sourcesContent":[".form {\n  display: flex;\n}\n\n.title {\n  margin-bottom: 1.5rem;\n}\n\n.button {\n  margin: 1.5rem 0;\n}\n\n.imageWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  flex: 1;\n  gap: 1.5rem;\n  margin: 2rem;\n}\n\n.inputsWrapper {\n  flex: 1.5;\n  margin: 2rem 5rem 2rem 2rem;\n}\n\n.button {\n  width: 200px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `author-form__form___zUfAG`,
	"title": `author-form__title___YAb0k`,
	"button": `author-form__button___SYHip`,
	"imageWrapper": `author-form__imageWrapper___F3ikp`,
	"inputsWrapper": `author-form__inputsWrapper___XKot4`
};
export default ___CSS_LOADER_EXPORT___;
