import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import type { State } from './types';
import type { User } from '../types';

export const login: CaseReducer<State> = (state) => {
  state.isLogin = true;
};

export const logout: CaseReducer<State> = (state) => {
  state.isLogin = false;
};

export const setUser: CaseReducer<State, PayloadAction<User | null>> = (state, { payload }) => {
  state.user = payload;
};

export const removeUser: CaseReducer<State> = (state) => {
  state.user = null;
};
