import type { FC } from 'react';

import { useController } from 'react-hook-form';

import { Switch } from '@/shared/ui';

type SwitchProps = {
  name: string;
  label?: string;
  className?: string;
  onChange?: (value: boolean) => void;
};

export const RHFSwitch: FC<SwitchProps> = ({ name, label, className, onChange }) => {
  const {
    field: { onChange: onFieldChange, value, ref },
    fieldState: { error },
  } = useController({ name, defaultValue: false });

  return (
    <Switch
      className={className}
      label={label}
      value={value}
      onChange={(e) => {
        onFieldChange(e);
        onChange && onChange(!value);
      }}
      error={error}
      inputRef={ref}
    />
  );
};
