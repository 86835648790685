import type { FieldsValues } from './schema';
import type { ArticleType } from '@/entities/article/types';

import { useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { articleModel } from '@/entities/article';
import { useShowNotification } from '@/shared/notification';
import { useAppDispatch } from '@/shared/lib/rtk';
import { MIN_PERCENTAGE, UNIQUENESS_SYMBOLS_MAX, UNIQUENESS_SYMBOLS_MIN } from '../config';

import { MODAL_ID } from '../config';

export const useCreate = () => {
  const create = articleModel.useCreate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues) =>
      create(data)
        .unwrap()
        .then(() => {
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Article was created`);
        })
        .catch(console.error),
    [create, showSuccessNotification],
  );
};

export const useUpdate = () => {
  const dispatch = useAppDispatch();
  const update = articleModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: FieldsValues & EntityWithId) =>
      update(data)
        .unwrap()
        .then(() => {
          dispatch(articleModel.actions.edit(null));
          NiceModal.hide(MODAL_ID);
          showSuccessNotification(`Article was updated`);
        })
        .catch(console.error),
    [update, showSuccessNotification, dispatch],
  );
};

export const useUpdateStatus = () => {
  const update = articleModel.useUpdate();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (data: Pick<ArticleType, 'id' | 'status'>) => {
      update(data).unwrap().catch(console.error);

      if (data.status === articleModel.ArticleStatusEnum.IN_TRASH) {
        showSuccessNotification(`Article was moved to trash`);
      }
    },
    [showSuccessNotification, update],
  );
};

export const useDelete = () => {
  const deleteArticle = articleModel.useDelete();
  const { showSuccessNotification } = useShowNotification();

  return useCallback(
    (id: UniqueId) => {
      deleteArticle(id)
        .unwrap()
        .then(() => {
          showSuccessNotification(`Article was deleted`);
        })
        .catch(console.error);
    },
    [deleteArticle, showSuccessNotification],
  );
};

export const useCheckUniquenessAndSubmit = (articleId: UniqueId, textContent: string) => {
  const { getUniquenessResult, uniquenessResult, uniquenessLoading } =
    articleModel.useGetUniquenessResult();
  const { showSuccessNotification, showErrorNotification } = useShowNotification();

  const checkUniquenessValidation = (text: string) => {
    const { length } = text;

    if (length < UNIQUENESS_SYMBOLS_MIN) {
      return `Minimum body length – ${UNIQUENESS_SYMBOLS_MIN}.`;
    }
    if (length > UNIQUENESS_SYMBOLS_MAX) {
      return `Maximum body length – ${UNIQUENESS_SYMBOLS_MAX}.`;
    }

    return null;
  };

  const getUniqueness = (submitAction: () => Promise<unknown>) => {
    const validationError = checkUniquenessValidation(textContent);

    if (validationError !== null) {
      showErrorNotification(validationError);
      return Promise.reject(validationError);
    }

    return submitAction()
      .then(() => getUniquenessResult(articleId))
      .then((response) => {
        if (response?.data?.uniquePercentage) {
          response.data.uniquePercentage >= MIN_PERCENTAGE
            ? showSuccessNotification(`Text uniqueness ${response.data.uniquePercentage}%.`)
            : showErrorNotification(`Text uniqueness ${response.data.uniquePercentage}%.`);
        }
        return response;
      });
  };

  return { getUniqueness, uniquenessResult, uniquenessLoading };
};
