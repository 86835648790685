import NiceModal from '@ebay/nice-modal-react';

import { useAppDispatch } from '@/shared/lib/rtk';
import { galleryModel } from '@/entities/gallery';
import { Button } from '@/shared/ui';

import { MODAL_ID } from '../config';

export const UploadFormButton = ({ label = 'Upload image' }: { label?: string }) => {
  const dispatch = useAppDispatch();
  galleryModel.useGetConstants();

  const handleClick = () => {
    dispatch(galleryModel.actions.edit(null));
    NiceModal.show(MODAL_ID);
  };

  return <Button label={label} onClick={handleClick} color="primary" />;
};
