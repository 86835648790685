import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

const acceptedParams = ['only_my_tasks'];

export const useGetQueryParams = (acceptFilters: Array<string>) => {
  const [searchParams] = useSearchParams();

  return useMemo(
    () => ({
      ...Object.fromEntries(
        [...searchParams.entries()]
          .filter(([key]) => acceptFilters.includes(key))
          .map(([key, value]) => [`filter[${key}]`, value]),
      ),
      ...Object.fromEntries(
        [...searchParams.entries()].filter(([key]) => acceptedParams.includes(key)),
      ),
    }),
    [acceptFilters, searchParams],
  );
};
