import type { FC } from 'react';
import type { ArticleType } from '@/entities/article/types';

import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { HasAccess } from '@/entities/session';
import { articleModel } from '@/entities/article';

import { useAppDispatch } from '@/shared/lib/rtk';
import { ARTICLE_UPDATE } from '@/shared/config/permissions';

import { MODAL_ID } from '../config';

type Props = {
  entity: ArticleType;
};

export const EditArticle: FC<Props> = React.memo(({ entity }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(articleModel.actions.edit(entity));
    NiceModal.show(MODAL_ID);
  };

  return (
    <HasAccess permissions={[ARTICLE_UPDATE]}>
      <IconButton onClick={handleClick}>
        <Tooltip title={'Settings'}>
          <SettingsIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </HasAccess>
  );
});
