import type { NodeKey, SerializedLexicalNode, Spread } from 'lexical';

import { $applyNodeReplacement, DecoratorNode, LexicalNode } from 'lexical';

import { DecoratorBlock } from '../ui/decorator-block';
import { TiktokComponent } from '../ui/tiktok';

const NODE_TYPE = 'tiktok';

export type TiktokPayload = {
  id: string;
  link: string;
  key?: NodeKey;
};

export type InsertTiktokPayload = Readonly<TiktokPayload>;

type SerializedTiktokNode = Spread<
  {
    id: string;
    link: string;
    type: typeof NODE_TYPE;
    version: 1;
  },
  SerializedLexicalNode
>;

export class TiktokNode extends DecoratorNode<JSX.Element> {
  __id: string;
  __link: string;

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: TiktokNode): TiktokNode {
    return new TiktokNode(node.__id, node.__link, node.__key);
  }

  exportJSON(): SerializedTiktokNode {
    return {
      id: this.getId(),
      link: this.getLink(),
      type: NODE_TYPE,
      version: 1,
    };
  }

  static importJSON(serializedNode: SerializedTiktokNode): TiktokNode {
    const { id, link } = serializedNode;

    return $createTiktokNode({ id, link });
  }

  constructor(id: string, link: string, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__link = link;
  }

  getId(): string {
    const self = this.getLatest();
    return self.__id;
  }

  getLink(): string {
    const self = this.getLatest();
    return self.__link;
  }

  isInline(): false {
    return false;
  }

  updateDOM(): false {
    return false;
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  decorate(): JSX.Element {
    return (
      <DecoratorBlock nodeKey={this.getKey()}>
        <TiktokComponent link={this.__link} id={this.__id} />
      </DecoratorBlock>
    );
  }
}

export function $createTiktokNode({ id, link }: TiktokPayload): TiktokNode {
  return $applyNodeReplacement(new TiktokNode(id, link));
}

export function $isTiktokNode(node: LexicalNode | null | undefined): node is TiktokNode {
  return node instanceof TiktokNode;
}
