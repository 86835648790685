import type { NarrowGridColumns } from '@/shared/ui/data-grid/types';
import type { TagType } from '@/entities/tag/types';

import { tagModel } from '@/entities/tag';
import { DataGrid } from '@/shared/ui';

import { DeleteTag } from './delete-tag';
import { EditTag } from './edit-tag';

const columns: NarrowGridColumns<TagType> = [
  {
    field: 'catchyTitle',
    headerName: 'Catchy title',
    width: 400,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'slug',
    headerName: 'Slug',
    width: 250,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'articlesCount',
    headerName: 'Articles',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
];

export const TagList = () => {
  const { tags, isFetching, total } = tagModel.useGetAll();

  return (
    <DataGrid
      rowsTotalCount={total}
      columns={columns}
      loading={isFetching}
      rows={tags ?? []}
      actions={(entity) => (
        <>
          <DeleteTag entity={entity} /> <EditTag entity={entity} />
        </>
      )}
    />
  );
};
