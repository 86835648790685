import type { FC, ReactNode } from 'react';

import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { PageLoader } from './page-loader';

type PageLoadingContext = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

const PageLoadingContext = createContext<PageLoadingContext>({
  isLoading: false,
  setIsLoading: () => {},
});

export const usePageLoading = () => {
  const context = useContext(PageLoadingContext);

  if (!context) {
    throw new Error(
      `[PageLoadingContext]: You forgot to wrap your component with PageLoadingContext`,
    );
  }

  return context;
};

type Props = {
  children: ReactNode;
};

export const PageLoadingProvider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedIsLoading, setDebouncedIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedIsLoading(isLoading), 150);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const value = useMemo(
    () => ({ isLoading: debouncedIsLoading, setIsLoading }),
    [debouncedIsLoading],
  );

  return (
    <PageLoadingContext.Provider value={value}>
      {children}
      <PageLoader />
    </PageLoadingContext.Provider>
  );
};
