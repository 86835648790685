import type { AsyncAutocompleteProps, SyncAutocompleteProps } from '@/shared/ui';

import { useController } from 'react-hook-form';

import { AsyncAutocomplete, SyncAutocomplete } from '@/shared/ui';

type Props = {
  name: string;
  label?: string;
} & Pick<SyncAutocompleteProps, 'options' | 'multiple' | 'placeholder' | 'disableClearable'>;

type AsyncProps = {
  name: string;
  label?: string;
} & Omit<AsyncAutocompleteProps, 'onBlur' | 'onChange' | 'fieldRef' | 'error'>;

export const RHFSyncAutocomplete = ({
  name,
  multiple,
  options,
  placeholder,
  disableClearable,
  label,
}: Props) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name });

  const computedValueFromOptions = multiple
    ? options.filter((option) => value.includes(option.value))
    : options.find((option) => option.value === value);

  return (
    <SyncAutocomplete
      placeholder={placeholder}
      fieldRef={ref}
      options={options}
      onBlur={onBlur}
      value={computedValueFromOptions ?? null}
      multiple={multiple}
      disableClearable={disableClearable}
      error={error}
      onChange={onChange}
      label={label}
    />
  );
};

export const RHFAsyncAutocomplete = ({
  name,
  multiple,
  onInputChange,
  options,
  value,
  loading,
  placeholder,
  disableClearable,
  label,
}: AsyncProps) => {
  const {
    field: { onChange, onBlur, ref },
    fieldState: { error },
  } = useController({ name });

  return (
    <AsyncAutocomplete
      placeholder={placeholder}
      onInputChange={onInputChange}
      options={options}
      fieldRef={ref}
      onBlur={onBlur}
      value={value}
      loading={loading}
      multiple={multiple}
      disableClearable={disableClearable}
      error={error}
      onChange={onChange}
      label={label}
    />
  );
};
