// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__container___shTJJ {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1300;

  flex-grow: 1;
}

.header__container___shTJJ .MuiToolbar-root {
  padding-left: 20px;
  padding-right: 20px;
}

.header__iconBtn___AFSqf {
  margin-right: 16px;
}

.header__title____jTnl {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/header/header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;;EAEb,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 1300;\n\n  flex-grow: 1;\n}\n\n.container :global .MuiToolbar-root {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.iconBtn {\n  margin-right: 16px;\n}\n\n.title {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `header__container___shTJJ`,
	"iconBtn": `header__iconBtn___AFSqf`,
	"title": `header__title____jTnl`
};
export default ___CSS_LOADER_EXPORT___;
