import type { ImageType, ResizeWidth, ImageResize, ImageResponseType } from './types';

export const getCropResizeByWidth = (image: ImageType, width: ResizeWidth): ImageResize | null => {
  if (!image.crop) {
    return null;
  }

  return (
    image.crop.resizes.find(
      (image) => image.extension === 'jpeg' && image.width === width && image.isUploaded,
    ) ?? null
  );
};

export const transformToImage = (image: ImageResponseType | null | undefined): ImageType | null => {
  if (image === null || image === undefined) {
    return null;
  }

  const { id, name, width, height, originalUrl, previewUrl, source, crop } = image;

  return { id, label: name, width, height, originalUrl, previewUrl, source, crop };
};
