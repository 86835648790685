export const REDUCER_PATH = 'entities/gallery';

export const imageColor = {
  WHITE: 0,
  BLACK: 1,
} as const;

export const imageSource = {
  UNKNOWN: 0,
  ORIGINAL: 1,
  FACEBOOK: 2,
  TWITTER: 3,
  INSTAGRAM: 4,
  GETTY: 5,
  UGC: 6,
  DEPOSIT: 7,
  AFP: 8,
} as const;
