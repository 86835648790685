import { imageColor, imageSource } from '../model';

type ImageExtensions = 'jpg' | 'jpeg' | 'png' | 'gif';

type ImageName = string;
type ImageHashKey = string;
type ImageWidth = number;
type ImageHeight = number;
type Author = string;
type IsProduction = boolean;
type ImageOriginalUrl = string;
type ImagePreviewUrl = string;

export type ResizeWidth = 1200 | 1120 | 720 | 560 | 360;

export type ImageResize = {
  width: ResizeWidth;
  height: number;
  extension: ImageExtensions;
  mainStorage: boolean;
  version: number;
  url: string;
  isUploaded?: boolean; // TODO: remove optional after deploy
};

type ImageCrop = {
  width: number;
  height: number;
  x: number;
  y: number;
  resizes: Array<ImageResize>;
};

export type ImageColor = (typeof imageColor)[keyof typeof imageColor];
export type ImageSource = (typeof imageSource)[keyof typeof imageSource];

export type ImageResponseType = {
  id: UniqueId;
  name: ImageName;
  hashKey: ImageHashKey;
  ext: ImageExtensions;
  color: ImageColor;
  crop: ImageCrop;
  source: ImageSource;
  width: ImageWidth;
  height: ImageHeight;
  author: Author;
  isProduction: IsProduction;
  updatedAt: DateTime;
  createdAt: DateTime;
  originalUrl: ImageOriginalUrl;
  previewUrl: ImagePreviewUrl;
};

export type ImageType = {
  id: UniqueId;
  label: ImageName;
  width: ImageWidth;
  height: ImageHeight;
  originalUrl: ImageOriginalUrl;
  previewUrl: ImageOriginalUrl;
  source: ImageSource;
  crop: ImageCrop | null;
  alt?: string;
};

export const isImageType = (maybeImage: any): maybeImage is ImageType => !!maybeImage?.originalUrl;

export type CropImagePayload = { id: UniqueId } & Omit<ImageCrop, 'resizes'>;

export type ImageConstantsKeys = 'color' | 'source';
