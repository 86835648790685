// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-cropper__root___LcAkL {
  display: flex;
  flex-wrap: wrap;
  min-width: 320px;
  padding: 20px 30px;
}

.image-cropper__canvas___Lzo1K {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.image-cropper__controls___j2f9e {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

.image-cropper__slider___PI1Pt {
  flex-grow: 1;
}

@media (min-width: 768px) {
  .image-cropper__root___LcAkL {
    min-width: 800px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/image-croppper/image-cropper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".root {\n  display: flex;\n  flex-wrap: wrap;\n  min-width: 320px;\n  padding: 20px 30px;\n}\n\n.canvas {\n  position: relative;\n  width: 100%;\n  padding-bottom: 56.25%;\n}\n\n.controls {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  width: 100%;\n  padding-top: 20px;\n}\n\n.slider {\n  flex-grow: 1;\n}\n\n@media (min-width: 768px) {\n  .root {\n    min-width: 800px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `image-cropper__root___LcAkL`,
	"canvas": `image-cropper__canvas___Lzo1K`,
	"controls": `image-cropper__controls___j2f9e`,
	"slider": `image-cropper__slider___PI1Pt`
};
export default ___CSS_LOADER_EXPORT___;
