import {
  useGetTagsQuery,
  useGetTagQuery,
  useAddTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
  useLazySearchTagsQuery,
} from '../api';
import { usePagination } from '@/shared/lib/query-params-filter';

export const useGetAll = () => {
  const { page, perPage } = usePagination();
  const { tags, isFetching, total } = useGetTagsQuery(
    {
      page,
      per_page: perPage,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        tags: data?.data,
        total: data?.total,
        isFetching,
      }),
    },
  );

  return { tags, isFetching, total };
};

export const useGetFromList = (id: UniqueId) => {
  const { page, perPage } = usePagination();
  const { tag, isFetching } = useGetTagsQuery(
    {
      page,
      per_page: perPage,
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        tag: data?.data.find((tag) => tag.id === id),
        isFetching,
      }),
    },
  );

  return { tag, isFetching };
};

export const useGetById = (id: UniqueId) => {
  const { data, isFetching } = useGetTagQuery(id);

  return { tag: data, isFetching };
};

export const useSuggestions = () => {
  const [searchTags, { suggestion, isFetching }] = useLazySearchTagsQuery({
    selectFromResult: ({ data, isFetching }) => ({
      suggestion: data?.data.map(({ id, catchyTitle }) => ({ value: id, name: catchyTitle })),
      isFetching,
    }),
  });

  return { searchTags, suggestion, isFetching };
};

export const useCreate = () => {
  const [create] = useAddTagMutation();

  return create;
};

export const useDelete = () => {
  const [deleteTag] = useDeleteTagMutation();

  return deleteTag;
};

export const useUpdate = () => {
  const [update] = useUpdateTagMutation();

  return update;
};
