// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-editor__popper___WLbzC{
  z-index: 2000;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.link-editor__paper___EcG7Y {
  padding: 0.5rem;
  border-radius: 10px;
}

.link-editor__container___zBWe7 {
  display: flex;
  align-items: flex-start;
  width: 25em;
}

.link-editor__submitButton___e9CYq{
  margin-top: 3px;
  margin-left: 0.5rem;
}

.link-editor__deleteButton___CLS1Z {
  margin-top: 3px;
}

.link-editor__input___Qub6T {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/shared/editor/ui/link-editor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;AACX","sourcesContent":[".popper{\n  z-index: 2000;\n  border: 1px solid rgba(0, 0, 0, 0.5);\n  border-radius: 10px;\n}\n\n.paper {\n  padding: 0.5rem;\n  border-radius: 10px;\n}\n\n.container {\n  display: flex;\n  align-items: flex-start;\n  width: 25em;\n}\n\n.submitButton{\n  margin-top: 3px;\n  margin-left: 0.5rem;\n}\n\n.deleteButton {\n  margin-top: 3px;\n}\n\n.input {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper": `link-editor__popper___WLbzC`,
	"paper": `link-editor__paper___EcG7Y`,
	"container": `link-editor__container___zBWe7`,
	"submitButton": `link-editor__submitButton___e9CYq`,
	"deleteButton": `link-editor__deleteButton___CLS1Z`,
	"input": `link-editor__input___Qub6T`
};
export default ___CSS_LOADER_EXPORT___;
