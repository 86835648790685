import { Outlet } from 'react-router';
import { useState } from 'react';
import Box from '@mui/material/Box';

import { Header } from '@/widgets/header';
import { Sidebar } from '@/widgets/sidebar';

import styles from './layout.css';

export default function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((isOpen) => !isOpen);
  };

  return (
    <div className={styles.container}>
      <Header isSidebarOpen={isSidebarOpen} toggleSidebar={handleToggleSidebar} />
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <Box component="main" className={styles.main}>
        <Outlet />
      </Box>
    </div>
  );
}
