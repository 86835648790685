import type { FC } from 'react';

import { ImageList as MUIImageList, ImageListItem, ImageListItemBar } from '@mui/material';

import { ImageType } from '../api/types';
import { useGetAll } from '../model';

type Props = {
  actionButtons: (image: ImageType) => JSX.Element;
};

export const ImageList: FC<Props> = ({ actionButtons }) => {
  const { images } = useGetAll();

  return (
    <MUIImageList sx={{ width: '100%', height: '100%' }} cols={4} rowHeight={350}>
      {images.map((item: ImageType) => (
        <ImageListItem key={item.id}>
          <ImageListItemBar
            title={item.label}
            subtitle={`${item.width}x${item.height}`}
            position="top"
          />
          <img src={item.previewUrl} alt={item.label} />
          <ImageListItemBar
            sx={{ display: 'flex', justifyContent: 'center' }}
            actionIcon={actionButtons(item)}
          />
        </ImageListItem>
      ))}
    </MUIImageList>
  );
};
