// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats__accordion___y8cUM {
  width: 55rem;
}

.stats__success___KcKTS {
  color: green;
}

.stats__warning___W3HcV {
  color: red
}
`, "",{"version":3,"sources":["webpack://./src/features/article/ui/stats.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;AACF","sourcesContent":[".accordion {\n  width: 55rem;\n}\n\n.success {\n  color: green;\n}\n\n.warning {\n  color: red\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `stats__accordion___y8cUM`,
	"success": `stats__success___KcKTS`,
	"warning": `stats__warning___W3HcV`
};
export default ___CSS_LOADER_EXPORT___;
