import type {
  GridRowsProp,
  GridColDef,
  GridValidRowModel,
  GridEventListener,
  GridPaginationModel,
  GridSlotsComponent,
  GridRowIdGetter,
} from '@mui/x-data-grid';
import type { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import type { GridInputRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import type { OnGridSelectionChange, OnRowClick } from './types';

import { useCallback, useMemo } from 'react';
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';

import { usePagination } from '@/shared/lib/query-params-filter';

import styles from './data-grid.css';

type Props<D extends GridValidRowModel> = {
  slots?: UncapitalizeObjectKeys<Partial<GridSlotsComponent>>;
  columns: GridColDef<D>[];
  rows: GridRowsProp<D>;
  rowsTotalCount?: number;
  actions?: (item: D) => JSX.Element;
  loading?: boolean;
  autoHeight?: boolean;
  checkboxSelection?: boolean;
  hidePagination?: boolean;
  onSelectionChange?: OnGridSelectionChange;
  onRowClick?: OnRowClick;
  onHeaderClick?: (field: string) => void;
  getRowId?: GridRowIdGetter;
  rowSelectionModel?: GridInputRowSelectionModel;
};

const DEFAULT_PER_PAGE = 25;
const PAGE_SIZES = [10, 25, 50, 100];

export const DataGrid = <D extends EntityWithId>({
  slots,
  columns,
  rows,
  rowsTotalCount,
  actions,
  loading = true,
  autoHeight = false,
  checkboxSelection = false,
  hidePagination = false,
  onSelectionChange,
  onRowClick,
  onHeaderClick,
  getRowId,
  rowSelectionModel,
}: Props<D>) => {
  const { setPage, setPerPage, page, perPage } = usePagination();
  const paginationModel = useMemo(
    () => ({
      page: page - 1,
      pageSize: perPage,
    }),
    [page, perPage],
  );

  let modifiedColumns = columns;

  if (actions?.length) {
    modifiedColumns = [
      ...columns,
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        type: 'actions',
        renderCell: ({ row }) => actions(row),
      },
    ];
  }

  const handlePaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setPage(model.page + 1);
      setPerPage(model.pageSize);
    },
    [setPage, setPerPage],
  );

  const handleHeaderClick: GridEventListener<'columnHeaderClick'> = useCallback(
    (params) => {
      onHeaderClick && onHeaderClick(params.field);
    },
    [onHeaderClick],
  );

  return (
    <MUIDataGrid
      hideFooterPagination={hidePagination}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      checkboxSelection={checkboxSelection}
      className={styles.dataGrid}
      onRowSelectionModelChange={onSelectionChange}
      disableRowSelectionOnClick
      onColumnHeaderClick={handleHeaderClick}
      paginationMode="server"
      pageSizeOptions={PAGE_SIZES}
      disableColumnFilter
      loading={loading}
      autoHeight={autoHeight}
      disableColumnMenu
      disableVirtualization
      onRowClick={onRowClick}
      disableColumnSelector
      rows={rows}
      rowCount={rowsTotalCount ?? DEFAULT_PER_PAGE}
      columns={modifiedColumns}
      slots={slots}
      getRowId={getRowId}
      rowSelectionModel={rowSelectionModel}
    />
  );
};
