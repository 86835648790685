import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { FieldsValues } from '../model';

import { useAppSelector } from '@/shared/lib/rtk';
import { categoryModel } from '@/entities/category';
import { RHFForm, RHFInput, RHFSwitch } from '@/shared/lib/react-hook-form';
import { Button, Drawer } from '@/shared/ui';

import { MODAL_ID } from '../config';
import { useCreate, useUpdate, schema } from '../model';

import styles from './form.css';

export const CategoryForm: FC = () => {
  const create = useCreate();
  const update = useUpdate();
  const category = useAppSelector(categoryModel.selectEditing);

  const handleSubmit: SubmitHandler<FieldsValues> = async (data) => {
    category ? update({ id: category.id, ...data }) : create(data);
  };

  return (
    <RHFForm onSubmit={handleSubmit} schema={schema} defaultValues={category ?? {}}>
      {({ loading }) => (
        <>
          <RHFInput name="catchyTitle" type="text" label="Catchy title" />
          <RHFInput name="mainHeadline" type="text" label="Headline" />
          <RHFInput name="slug" type="text" label="Slug" disabled={!!category} />
          <RHFInput name="description" type="text" label="Seo description" multiline />
          <RHFSwitch name="webEnabled" label="Show on main page" className={styles.switch} />
          <Button loading={loading} type="submit" label="Save" color="primary" />
        </>
      )}
    </RHFForm>
  );
};

export const CategoryModal = () => (
  <Drawer id={MODAL_ID}>
    <CategoryForm />
  </Drawer>
);
